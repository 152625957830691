import SGColumnHeader from "../../../../../../common/components/grids/SGColumnHeader";
import EditIcon from "../../../../../../common/components/icons/EditIcon";

import useSortableGrid from "../../../../../../common/utils/UseSortableGrid";
import SGConstants from "../../../../../../common/utils/SGConstants";
import { formatDate } from "../../../../../../common/utils/DateFunctions";

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ group, onEditGroupClicked }) => (
  <tr>
    <td>{group.nationalTeamGroupName || ''}</td>
    <td>{group.nationalTeamGroupCode || ''}</td>
    <td>{group.groupTypeName || ''}</td>
    <td>{group.effectiveDate ? formatDate(group.effectiveDate) : ''} - {group.expirationDate ? formatDate(group.expirationDate) : ''}</td>
    <td>
      {onEditGroupClicked &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onEditGroupClicked(e, group)}>
          <EditIcon />
        </button>
      }
    </td>
  </tr>
);

const NTGroupSearchGridLarge = ({ gridData, isLoading, onEditGroupClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group Name'} columnField={'nationalTeamGroupName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group Code'} columnField={'nationalTeamGroupCode'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group Type'} columnField={'nationalTeamGroupTypeId'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th>Effective Date - Expiration Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="5">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((group, i) => <GridRow key={i} group={group} onEditGroupClicked={onEditGroupClicked} />)
            : <tr><td colSpan="5">No Groups</td></tr>
        }
      </tbody>
    </table>
  );
};

export default NTGroupSearchGridLarge;