import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from '../../../common/wrappers/ReactRouterDom';

import useGroupData from '../../../common/state/group/UseGroupData';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import useSecurityData from '../../../common/state/security/UseSecurityData';

const TAXONOMIES = ['GroupsDetail'];
const SCOPE = 'Group';

const LOADING_PHASES = {
  ERROR: -1,
  NOT_LOADED: 1,
  LOADING_GROUP: 2,
  LOADING_MEMBERS: 3,
  LOADING_CONTEXT: 4,
  LOADED: 5
};

const BACK_TO_LABELS = {
  groupSearchBackToLabel: 'Back to Group Search',
  groupMyGroupsBackToLabel: 'Back to My Groups'
};

const useGroupContextView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { groupState, groupMembersState, getGroup, getGroupMembers } = useGroupData();
  const { securityState } = useSecurityData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();
  const [loadingState, setLoadingState] = useState(LOADING_PHASES.NOT_LOADED);

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.GROUP_INFO?.route);
  };

  const onBackToClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (Array.isArray(securityState?.userInfo?.appRoutes)) {
      const appRoutes = securityState.userInfo.appRoutes;

      if (appRoutes.some(x => x.routeName === 'GROUP_SEARCH')) {
        navigate(navRoutes.GROUP_SEARCH?.route);
      } else if (appRoutes.some(x => x.routeName === 'GROUP_MY_GROUPS')) {
        navigate(navRoutes.GROUP_MY_GROUPS?.route);
      } else {
        navigate('/');
      }
    }
  };

  useEffect(() => {
    const orgGroupId = location?.state?.orgGroupId;

    switch (loadingState) {
      case LOADING_PHASES.NOT_LOADED:
        if (orgGroupId) {
          getGroup(orgGroupId);
          setLoadingState(LOADING_PHASES.LOADING_GROUP);
        } else {
          setLoadingState(LOADING_PHASES.ERROR);
        }
        break;
      case LOADING_PHASES.LOADING_GROUP:
        if (groupState.isObjLoaded === true) {
          getGroupMembers(orgGroupId);
          setLoadingState(LOADING_PHASES.LOADING_MEMBERS);
        }
        break;
      case LOADING_PHASES.LOADING_MEMBERS:
        if (groupMembersState.isArrayLoaded === true) {
          const ctxStatus = getContextSecurity(orgGroupId, TAXONOMIES, SCOPE);
          setLoadingState(ctxStatus === 'sent' ? LOADING_PHASES.LOADING_CONTEXT : LOADING_PHASES.LOADED);
        }
        break;
      case LOADING_PHASES.LOADING_CONTEXT:
        if (contextSecurityState.isObjLoaded === true) {
          setLoadingState(LOADING_PHASES.LOADED);
        }
        break;
      case LOADING_PHASES.LOADED:
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupState, contextSecurityState, groupMembersState]);

  function getBackToLabel() {
    if (Array.isArray(securityState?.userInfo?.appRoutes)) {
      const appRoutes = securityState.userInfo.appRoutes;

      if (appRoutes.some(x => x.routeName === 'GROUP_SEARCH')) {
        return BACK_TO_LABELS.groupSearchBackToLabel;
      } else if (appRoutes.some(x => x.routeName === 'GROUP_MY_GROUPS')) {
        return BACK_TO_LABELS.groupMyGroupsBackToLabel;
      }
    } else {
      return 'Back To Groups';
    }
  }

  return {
    isError: loadingState === LOADING_PHASES.ERROR,
    isLoading: loadingState !== LOADING_PHASES.LOADED && loadingState !== LOADING_PHASES.ERROR,
    homeRoute: navRoutes.GROUP_INFO?.route,
    taxonomyName: TAXONOMIES[0],
    backToLabel: getBackToLabel(),
    groupInfoObj: groupState.objData || {},
    onHomeClicked,
    onBackToClicked
  };
};

export default useGroupContextView;