import useNTGroupConfirmedDropdown from './UseNTGroupConfirmedDropdown';

import Dropdown from '../../../../../../common/components/dropdowns/Dropdown';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const NTGroupConfirmedDropdown = ({ label, name, value, error, message, onChange }) => {
  const { nationalTeamGroupConfirmedState } = useNTGroupConfirmedDropdown();

  return nationalTeamGroupConfirmedState.message
    ? <span className={global.LoadingMsg}>{nationalTeamGroupConfirmedState.message}</span>
    : (
      <Dropdown
        name={name}
        value={value}
        error={error}
        label={label}
        message={message}
        onChange={onChange}
        options={nationalTeamGroupConfirmedState.options || []}
        isLoading={nationalTeamGroupConfirmedState.areOptionsLoaded !== true}
      />
    );
};

export default NTGroupConfirmedDropdown;