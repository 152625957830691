import DeleteIcon from '../../../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../../../common/utils/TimesUtils';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ groupAthleteEvent, onDeleteClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{groupAthleteEvent.eventName || ''}</span>&nbsp;
      <div className={global.FloatRight}>
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={(e) => onDeleteClicked(e, groupAthleteEvent)}>
          <DeleteIcon />
        </button>
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {groupAthleteEvent.eventName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Swim Time
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {groupAthleteEvent.swimTime ? formatTimeForDisplay(groupAthleteEvent.swimTime) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Swim Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {groupAthleteEvent.swimDate ? formatDate(groupAthleteEvent.swimDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Meet Name
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {groupAthleteEvent.meetName || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const NTGroupAthleteEventsGridSmall = ({ gridData, isLoading, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((groupAthleteEvent, i) => <GridRow key={i} groupAthleteEvent={groupAthleteEvent} onDeleteClicked={onDeleteClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Events</div>
            </div>
          </div>
        )
    }
  </div>
);

export default NTGroupAthleteEventsGridSmall;