import { Fragment } from "react";

import useNTGroupSearch from "./UseNTGroupSearch";

import NTGroupSearchForm from "../components/forms/search/NTGroupSearchForm";
import NTGroupSearchGrid from "../components/grids/search/NTGroupSearchGrid";
import NTGroupsLoadingModal from "../components/dialogs/loading/NTGroupsLoadingModal";

import Headings from "../../../../common/components/headings/Headings";

import global from '../../../../common/components/GlobalStyle.module.css';

const NTGroupSearch = () => {
  const {
    showFilters,
    previousSearchFilters,
    nationalTeamGroupSearchState,
    onAddGroupClicked,
    onEditGroupClicked,
    onSubmitSearchForm,
    onToggleShowFilters
  } = useNTGroupSearch();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>National Team Group Search</Headings.H3>
        </div>
      </div>
      <div className={showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <NTGroupSearchForm
          previousSearchFilters={previousSearchFilters}
          onSubmitFormCallback={onSubmitSearchForm}
          onAddGroupClicked={onAddGroupClicked} />
      </div>
      <NTGroupSearchGrid
        gridData={nationalTeamGroupSearchState.arrayData}
        isLoading={nationalTeamGroupSearchState.isArrayLoading}
        showFilters={showFilters}
        onToggleShowFilters={onToggleShowFilters}
        onEditGroupClicked={onEditGroupClicked} />
      <NTGroupsLoadingModal isLoading={false} />
    </Fragment>
  );
};

export default NTGroupSearch;