import { useEffect } from "react";
import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";

import useGroupAlternateMembersData from "../../../../state/groupAlternateMembers/UseGroupAlternateMembersData";

import useGroupData from "../../../../../common/state/group/UseGroupData";

const useGroupInviteAlternateMembersIMX = () => {
  const navigate = useNavigate();
  const { groupState, groupMembersState, getGroupMembers } = useGroupData();
  const { groupAlternateMembersIMXState, getGroupAlternateMembersIMX, putGroupAlternateMembersIMX, updateMemberIMXIsSelected
  } = useGroupAlternateMembersData();

  const onInviteAthletesClicked = async (event) => {
    event?.preventDefault();

    if (groupAlternateMembersIMXState.arrayData.some(x => x.isSelected === true)) {
      const putGroupAlternateMembersIMXResult = await putGroupAlternateMembersIMX(groupState.objData.orgGroupId, groupAlternateMembersIMXState.arrayData);

      if (putGroupAlternateMembersIMXResult) {
        const getGroupMembersResult = await getGroupMembers(groupState.objData.orgGroupId);

        if (getGroupMembersResult) {
          navigate(NavLinks.GROUP_MEMBERS);
        }
      }
    }
  };

  const onBackClicked = () => {
    navigate(NavLinks.GROUP_MEMBERS);
  };

  const onCheckboxClicked = (groupQualificationImxId) => {
    if (groupQualificationImxId && groupAlternateMembersIMXState.isArrayLoading !== true) {
      updateMemberIMXIsSelected(groupQualificationImxId);
    }
  };

  useEffect(() => {
    if (groupState.isObjLoaded === true) {
      getGroupAlternateMembersIMX(groupState.objData.orgGroupId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSaving: groupAlternateMembersIMXState.isSaving,
    isLoading: groupMembersState.isArrayLoading,
    isGridLoading: groupAlternateMembersIMXState.isArrayLoading,
    gridData: groupAlternateMembersIMXState.arrayData,
    onInviteAthletesClicked,
    onBackClicked,
    onCheckboxClicked
  };
};

export default useGroupInviteAlternateMembersIMX;