import { Fragment } from 'react';

import useNTGroupMembersFormGrid from './UseNTGroupMembersFormGrid';

import NTGroupMembersFormGridLarge from './NTGroupMembersFormGridLarge';
import NTGroupMembersFormGridSmall from './NTGroupMembersFormGridSmall';

import Spinner from '../../../../../../common/components/spinners/Spinner';
import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';

const NTGroupMembersFormGrid = ({ gridData, isLoading, onSubmitFormCallback, onEditClicked, onDeleteClicked }) => {
  const {
    areOptionsLoaded,
    canSaveState,
    formGridState,
    onUpdateFormGridState,
    onSaveClicked,
    onCancelClicked
  } = useNTGroupMembersFormGrid(gridData, onSubmitFormCallback);

  if (areOptionsLoaded !== true || isLoading === true) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" isDisabled={canSaveState === false} onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          {canSaveState === true && <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>}
        </div>
      </div>
      <NTGroupMembersFormGridLarge formGridState={formGridState} onUpdateFormGridState={onUpdateFormGridState} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
      <NTGroupMembersFormGridSmall formGridState={formGridState} onUpdateFormGridState={onUpdateFormGridState} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="button" isDisabled={canSaveState === false} onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          {canSaveState === true && <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>}
        </div>
      </div>
    </Fragment>
  );
};

export default NTGroupMembersFormGrid;