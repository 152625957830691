import useSAAGroupMembersFilter from "./UseSAAGroupMembersFilter";

import Dropdown from "../../../../common/components/dropdowns/Dropdown";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

const SAAGroupMembersFilter = ({ previousSearchFilters, statusOptions = [], genderOptions = [], zoneOptions = [], onSubmitFormCallback }) => {
  const {
    displayClearFilterButton,
    state,
    onUpdateFilter,
    onClearFilterClicked
  } = useSAAGroupMembersFilter(previousSearchFilters, onSubmitFormCallback);

  return (
    <form noValidate>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Filter By Status"
            name="personOrgGroupQualificationStatus"
            value={state.personOrgGroupQualificationStatus}
            options={statusOptions}
            isLoading={statusOptions.length === 0}
            onChange={(value, valueLabel, e) => { e?.target?.value && onUpdateFilter('personOrgGroupQualificationStatus', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Filter By Competition Category"
            name="competitionGenderTypeCode"
            value={state.competitionGenderTypeCode}
            options={genderOptions}
            isLoading={genderOptions.length === 0}
            onChange={(value, valueLabel, e) => { e?.target?.value && onUpdateFilter('competitionGenderTypeCode', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Filter By Zone"
            name="zoneOrgUnitCode"
            value={state.zoneOrgUnitCode}
            options={zoneOptions}
            isLoading={zoneOptions.length === 0}
            onChange={(value, valueLabel, e) => { e?.target?.value && onUpdateFilter('zoneOrgUnitCode', value); }} />
        </div>
      </div>
      {displayClearFilterButton === true &&
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-md-4">
            <SecondaryButton type="button" onClick={onClearFilterClicked}>Clear Filters</SecondaryButton>
          </div>
        </div>
      }
    </form>
  );
};

export default SAAGroupMembersFilter;