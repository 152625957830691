import { useState } from "react";
import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";

import useGroupData from "../../../../../common/state/group/UseGroupData";
import useGroupFieldNamesData from "../../../../../common/state/groupFieldNames/UseGroupFieldNamesData";
import useMemberGroupsData from "../../../../../common/state/memberGroups/UseMemberGroupsData";

const INITIAL_STATE = {
  isMemberLoaded: false,
  personId: '',
  firstName: '',
  lastName: ''
};

const INITIAL_ERROR_MODAL_STATE = {
  displayModal: false,
  firstName: '',
  lastName: '',
};

const useGroupMemberAdd = () => {
  const navigate = useNavigate();
  const { groupState, groupMembersState, getGroupMembers } = useGroupData();
  const { memberGroupsState, postGroupMember } = useMemberGroupsData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const [state, setState] = useState(INITIAL_STATE);
  const [errorModalState, setErrorModalState] = useState(INITIAL_ERROR_MODAL_STATE);

  const onBackClicked = () => {
    navigate(NavLinks.GROUP_MEMBERS);
  };

  const onAddMemberSubmitFormCallback = async (formState) => {
    if (formState.matchedMember?.personId) {
      const member = formState.matchedMember;

      if (memberGroupsState.arrayData.find(x => x.personId === member.personId)) {
        setErrorModalState({
          ...errorModalState,
          displayModal: true,
          firstName: member.firstName || '',
          lastName: member.lastName || ''
        });
      } else if (doesGroupTypeHavePersonFields(groupState.objData.groupType?.typeName)) {
        setState({
          ...state,
          isMemberLoaded: true,
          personId: member.personId,
          firstName: member.firstName || '',
          lastName: member.lastName || ''
        });
      } else {
        const groupMemberObj = {
          orgGroupId: groupState.objData.orgGroupId,
          personId: member.personId,
          personGroupTypeField: [],
          personOrgGroupQualification: []
        };

        const postGroupMemberResult = await postGroupMember(groupMemberObj);

        if (postGroupMemberResult) {
          const getGroupMembersResult = await getGroupMembers(groupState.objData.orgGroupId);

          if (getGroupMembersResult) {
            navigate(NavLinks.GROUP_MEMBERS);
          }
        }
      }
    }
  };

  const onFormCompletion = async (groupMemberObj) => {
    const postGroupMemberResult = await postGroupMember(groupMemberObj);

    if (postGroupMemberResult) {
      const getGroupMembersResult = await getGroupMembers(groupState.objData.orgGroupId);

      if (getGroupMembersResult) {
        navigate(NavLinks.GROUP_MEMBERS);
      }
    }
  };

  function doesGroupTypeHavePersonFields(groupTypeName) {
    switch (groupTypeName) {
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
        return true;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_COMPETITOR_LIST:
        return true;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_CAMP:
        return true;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_SAA:
        return true;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_NATIONAL_TEAM:
        return true;
      default:
        return false;
    }
  }

  return {
    GROUP_CONSTANTS,
    isLoading: groupMembersState.isArrayLoading,
    isSaving: memberGroupsState.isSaving,
    state,
    errorModalState,
    groupObj: groupState.objData || {},
    memberGroupObj: {},
    onBackClicked,
    onAddMemberSubmitFormCallback,
    onFormCompletion,
  };
};

export default useGroupMemberAdd;