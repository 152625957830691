import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

const getSAAGroupInfoData = (state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = `/OrgGroup/SAA`;

  return api.executeObject ? api.executeObject(url, 'GET') : null;
};

const INITIAL_GROUP_INFO_STATE = {
  ...BASIC_INITIAL_STATE
};

const SAAGroupInfoData = {
  INITIAL_GROUP_INFO_STATE,
  getSAAGroupInfoData
};

export default SAAGroupInfoData;