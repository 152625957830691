import { useState } from "react";

import NTGroupApprovalData from "./NTGroupApprovalData";

const useNTGroupApprovalData = () => {
  const [groupApprovalState, setGroupApprovalState] = useState(NTGroupApprovalData.INITIAL_GROUP_APPROVAL_STATE);
  const [groupApprovalSubmitState, setGroupApprovalSubmitState] = useState(NTGroupApprovalData.INITIAL_GROUP_APPROVAL_SUBMIT_STATE);

  const postNTGroupApproval = (nationalTeamGroupId, personNationalTeamGroupId, groupApprovalArray) => {
    return NTGroupApprovalData.postNTGroupApprovalData(nationalTeamGroupId, personNationalTeamGroupId, groupApprovalArray, groupApprovalSubmitState, setGroupApprovalSubmitState);
  };

  const getNTGroupApproval = (nationalTeamGroupId, personNationalTeamGroupId) => {
    return NTGroupApprovalData.getNTGroupApprovalData(nationalTeamGroupId, personNationalTeamGroupId, groupApprovalState, setGroupApprovalState);
  };

  return {
    groupApprovalState,
    groupApprovalSubmitState,
    getNTGroupApproval,
    postNTGroupApproval
  };
};

export default useNTGroupApprovalData;