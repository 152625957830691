import { Fragment } from "react";

import PopUpModal from "../../../../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";

const NTGroupMemberDupModal = ({ groupMemberObj, onCloseModal }) => (
  <Fragment>
    <PopUpModal
      widthPct={90}
      maxWidth={720}
      title={'Duplicate Group Member'}
      displayPopUp={true}
      onModalCanceled={onCloseModal}>
      <div className="row">
        <div className="col-xs-12">
          <p>{groupMemberObj.groupMemberName} is already a member of this group.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <SecondaryButton type='button' onClick={onCloseModal}>Close</SecondaryButton>
        </div>
      </div>
    </PopUpModal>
  </Fragment>
);

export default NTGroupMemberDupModal;