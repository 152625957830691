import { useEffect } from 'react';

import useNTGroupTypesHierarchyData from '../../../../../state/nationalTeamGroupTypesHierarchy/UseNTGroupTypesHierarchyData';

const useNTGroupTypesTreeView = () => {
  const { nationalTeamGroupTypesHierarchyState, getNTGroupTypesHierarchy } = useNTGroupTypesHierarchyData();

  useEffect(() => {
    getNTGroupTypesHierarchy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    nationalTeamGroupTypesHierarchyState
  };
};

export default useNTGroupTypesTreeView;