import { Route, Routes } from "react-router-dom";
import { Navigate } from "../../../common/wrappers/ReactRouterDom";

import NTGroupsContextView from "./NTGroupsContextView";

import NTGroupAdd from "./nationalTeamGroupAdd/NTGroupAdd";
import NTGroupInfo from "./nationalTeamGroupInfo/NTGroupInfo";
import NTGroupSearch from "./nationalTeamGroupSearch/NTGroupSearch";
import NTGroupReports from "./nationalTeamGroupReports/NTGroupReports";
import NTGroupMembers from "./nationalTeamGroupMembers/NTGroupMembers";
import NTGroupAthletes from "./nationalTeamGroupAthletes/NTGroupAthletes";
import NTGroupApprovals from "./nationalTeamGroupApprovals/NTGroupAppovals";

import NTGroupContextProvider from "../../state/nationalTeamGroup/NTGroupContextProvider";
import NTGroupFiltersContextProvider from "../../state/nationalTeamGroupSearch/NTGroupFiltersContextProvider";
import NTGroupTypesHierarchyContextProvider from "../../state/nationalTeamGroupTypesHierarchy/NTGroupTypesHierarchyContextProvider";
import NTGroupRoleTypesContextProvider from "../../state/nationalTeamGroupRoleTypes/NTGroupRoleTypesContextProvider";
import NTGroupAttendeeTypesContextProvider from "../../state/nationalTeamGroupAttendeeTypes/NTGroupAttendeeTypesContextProvider";
import NTGroupInfoContextProvider from "../../state/nationalTeamGroup/nationalTeamGroupInfo/NTGroupInfoContextProvider";
import NTGroupMembersContextProvider from "../../state/nationalTeamGroup/nationalTeamGroupMembers/NTGroupMembersContextProvider";

import LoadingModal from "../../../common/components/dialogs/LoadingModal";
import ErrorBoundary from "../../../common/components/errors/ErrorBoundary";
import SecuredRouteElement from "../../../common/components/security/SecuredRouteElement";
import ErrorBoundaryFallback from "../../../common/components/errors/ErrorBoundaryFallback";

import useNavRoutes from "../../../common/state/security/UseNavRoutes";
import EventContextProvider from "../../../common/state/event/EventContextProvider";

const NTGroupsContextRoutesContextProviders = ({ children }) => (
  <NTGroupInfoContextProvider>
    <NTGroupMembersContextProvider>
      {children}
    </NTGroupMembersContextProvider>
  </NTGroupInfoContextProvider>
);

const NTGroupsBasicRoutesContextProviders = ({ children }) => (
  <EventContextProvider>
    <NTGroupTypesHierarchyContextProvider>
      <NTGroupAttendeeTypesContextProvider>
        <NTGroupRoleTypesContextProvider>
          <NTGroupContextProvider>
            <NTGroupFiltersContextProvider>
              {children}
            </NTGroupFiltersContextProvider>
          </NTGroupContextProvider>
        </NTGroupRoleTypesContextProvider>
      </NTGroupAttendeeTypesContextProvider>
    </NTGroupTypesHierarchyContextProvider>
  </EventContextProvider>
);

const NTGroupsContextRoutes = ({ navRoutes }) => (
  <NTGroupsContextRoutesContextProviders>
    <NTGroupsContextView>
      <Routes>
        <Route path={navRoutes.NT_GROUP_INFO?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.NT_GROUP_INFO}
              editElement={<NTGroupInfo />} />
          } />
        <Route path={navRoutes.NT_GROUP_MEMBERS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.NT_GROUP_MEMBERS}
              editElement={<NTGroupMembers />} />
          } />
        <Route path={navRoutes.NT_GROUP_ATHLETES?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.NT_GROUP_ATHLETES}
              editElement={<NTGroupAthletes />} />
          } />
        <Route path={navRoutes.NT_GROUP_APPROVALS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.NT_GROUP_APPROVALS}
              editElement={<NTGroupApprovals />} />
          } />
        <Route path={navRoutes.NT_GROUP_REPORTS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.NT_GROUP_REPORTS}
              editElement={<NTGroupReports />} />
          } />
      </Routes>
    </NTGroupsContextView>
  </NTGroupsContextRoutesContextProviders>
);

const NTGroupsBasicRoutes = ({ navRoutes }) => (
  <NTGroupsBasicRoutesContextProviders>
    <Routes>
      <Route path={navRoutes.NT_GROUP_SEARCH?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_GROUP_SEARCH}
            editElement={<NTGroupSearch />} />
        } />
      <Route path={navRoutes.NT_GROUP_ADD?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.NT_GROUP_ADD}
            editElement={<NTGroupAdd />} />
        } />

      <Route path={'/*'} element={<NTGroupsContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes.NT_GROUP_SEARCH?.path} replace />} />
    </Routes>
  </NTGroupsBasicRoutesContextProviders>
);

const NTGroups = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <NTGroupsBasicRoutes navRoutes={navRoutes} />
    </ErrorBoundary>
  );
};

export default NTGroups;