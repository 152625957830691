import useNTGroupMembersData from "../../../../../state/nationalTeamGroup/nationalTeamGroupMembers/UseNTGroupMembersData";
import useNTGroupData from "../../../../../state/nationalTeamGroup/UseNTGroupData";

const useNTGroupMemberDeleteModal = (groupMemberObj, onCloseModal) => {
  const { onNTGroupsError } = useNTGroupData();
  const { groupMemberDeleteState, deleteNTGroupMember } = useNTGroupMembersData();

  const onConfirmDeleteClicked = (event) => {
    event?.preventDefault();

    const deleteNTGroupMemberPromise = deleteNTGroupMember(groupMemberObj.personNationalTeamGroupId);

    if (deleteNTGroupMemberPromise ?? false) {
      deleteNTGroupMemberPromise.then((newState) => {
        if (newState ?? false) {
          onCloseModal(event, true);
        }
      }).catch((e) => {
        onNTGroupsError(e);
        onCloseModal(event);
      });
    }
  };

  return {
    isSaving: groupMemberDeleteState.isObjLoading,
    onConfirmDeleteClicked
  };
};

export default useNTGroupMemberDeleteModal;