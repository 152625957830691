import { useContext } from "react";

import SAAGroupInfoData from "./SAAGroupInfoData";
import { SAAGroupInfoContext } from "./SAAGroupInfoContextProvider";

const useSAAGroupInfoData = () => {
  const [groupInfoState, setGroupInfoState] = useContext(SAAGroupInfoContext);

  const getSAAGroupInfo = () => {
    return SAAGroupInfoData.getSAAGroupInfoData(groupInfoState, setGroupInfoState);
  };

  return {
    groupInfoState,
    getSAAGroupInfo
  };
};

export default useSAAGroupInfoData;