import { createContext, useState } from 'react';

import SAAGroupMembersData from './SAAGroupMembersData';

export const SAAGroupMembersContext = createContext();

const SAAGroupMembersContextProvider = ({ children }) => {
  const stateHook = useState(SAAGroupMembersData.INITIAL_GROUP_MEMBERS_STATE);

  return (
    <SAAGroupMembersContext.Provider value={stateHook}>
      {children}
    </SAAGroupMembersContext.Provider>
  );
};

export default SAAGroupMembersContextProvider;