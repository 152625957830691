import { useState } from "react";

import useNTGroupData from "../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupMembersData from "../../../state/nationalTeamGroup/nationalTeamGroupMembers/UseNTGroupMembersData";

import useMemberPersistentStorage from "../../../../common/state/memberPersistentStorage/UseMemberPersistentStorage";
import useGlobalRoutesData from "../../../../common/state/security/UseGlobalRoutesData";

import formatCrossUiRoute from "../../../../common/utils/FormatCrossUiRoute";
import { UI_PROJECT_NAMES } from "../../../../common/utils/Constants";

const INITIAL_STATE = {
  deleteGroupMemberObj: {},
  addGroupAthleteObj: {},
  dupGroupMemberObj: {},
  displayDeletePopup: false,
  displayAddPopup: false,
  displayDupPopup: false
};

const useNTGroupAthletesManualInvite = () => {
  const { onNTGroupsError } = useNTGroupData();
  const { setPersistentStorage } = useMemberPersistentStorage();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { groupMembersState, getNTGroupMembers } = useNTGroupMembersData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCloseDeletePopup = (event, refreshGrid = false) => {
    try {
      event?.preventDefault();

      if (refreshGrid === true) {
        getNTGroupMembersHelper(groupMembersState.nationalTeamGroupId);
      }

      setState({
        ...state,
        displayDeletePopup: false,
        deleteGroupMemberObj: {}
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onCloseAddPopup = (event, refreshGrid = false) => {
    try {
      event?.preventDefault();

      if (refreshGrid === true) {
        getNTGroupMembersHelper(groupMembersState.nationalTeamGroupId);
      }

      setState({
        ...state,
        displayAddPopup: false,
        addGroupAthleteObj: {}
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onCloseDupPopup = (event) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDupPopup: false,
        dupGroupMemberObj: {}
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onDeleteGroupMemberClicked = (event, groupMemberObj) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDeletePopup: true,
        deleteGroupMemberObj: {
          ...groupMemberObj,
          groupMemberName: `${groupMemberObj?.preferredName || groupMemberObj?.firstName} ${groupMemberObj?.lastName}`
        }
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onEditGroupAthleteClicked = (event, groupAthleteObj) => {
    try {
      event?.preventDefault();
      setPersistentStorage(
        groupAthleteObj.personId,
        UI_PROJECT_NAMES.MEMBER,
        '/',
        'Back to Member Search'
      );

      const routeObj = getGlobalRoute('MEMBER_NT_ADVANCED_INFO');
      const crossUiRoute = formatCrossUiRoute(routeObj?.uIProjectName, routeObj?.route, true);
      window.open(crossUiRoute.route, '_blank');
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onSubmitMemberIdForm = (formState) => {
    try {
      const dupGroupMemberObj = Array.isArray(groupMembersState.objData?.nationalTeamGroupMember) ? groupMembersState.objData.nationalTeamGroupMember.find((groupMember) => groupMember.personId === formState.matchedMember.personId) : null;

      if (dupGroupMemberObj ?? false) {
        setState({
          ...state,
          dupGroupMemberObj: {
            ...dupGroupMemberObj,
            groupMemberName: `${dupGroupMemberObj.preferredName || dupGroupMemberObj.firstName} ${dupGroupMemberObj.lastName}`
          },
          displayDupPopup: true
        });
      } else {
        setState({
          ...state,
          displayAddPopup: true,
          addGroupAthleteObj: formState.matchedMember
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  function getNTGroupMembersHelper(nationalTeamGroupId) {
    const getNTGroupMembersPromise = getNTGroupMembers(nationalTeamGroupId);

    if (getNTGroupMembersPromise ?? false) {
      getNTGroupMembersPromise.catch((e) => {
        onNTGroupsError(e);
      });
    }
  }

  return {
    isGridLoading: groupMembersState.isObjLoading,
    gridData: groupMembersState.objData?.nationalTeamGroupMember || [],
    state,
    onCloseDupPopup,
    onCloseAddPopup,
    onCloseDeletePopup,
    onSubmitMemberIdForm,
    onEditGroupAthleteClicked,
    onDeleteGroupMemberClicked,
  };
};

export default useNTGroupAthletesManualInvite;