import PlusIcon from '../../icons/PlusIcon';

import global from '../../GlobalStyle.module.css';

const GridRow = ({ meet, onSelectMeet }) => (
  <tr>
    <td>{meet.meetName || ''}</td>
    <td>{meet.meetClassification || ''}</td>
    <td>{meet.dateRange || ''}</td>
    <td>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={(e) => onSelectMeet(e, meet)}>
        <PlusIcon />
      </button>
    </td>
  </tr>
);

const SearchMeetPopupGrid = ({ data, isLoading, onSelectMeet }) => (
  <table className={global.UsasTable}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Classification</th>
        <th>Dates</th>
        <th>Select</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(data) && data.length > 0
          ? data.map((meet, i) => <GridRow key={i} meet={meet} onSelectMeet={onSelectMeet} />)
          : <tr><td colSpan="4">No Results - Meet Not Found</td></tr>
      }
    </tbody>
  </table>
);

export default SearchMeetPopupGrid;