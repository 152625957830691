import useGroupMembers from './UseGroupMembers';

import GroupListMembers from './listMembers/GroupListMembers';
import GroupCompetitorListMembers from './competitorMembers/GroupCompetitorListMembers';
import GroupPracticeMembers from './practiceMembers/GroupPracticeMembers';
import GroupTravelTripMembers from './travelTripMembers/GroupTravelTripMembers';
import GroupCampMembers from './campMembers/GroupCampMembers';
import GroupSAAMembers from './saaMembers/GroupSAAMembers';
import GroupNationalTeamMembers from './nationalTeamMembers/GroupNationalTeamMembers';
import GroupClubSiteMembers from './clubSiteMembers/GroupClubSiteMembers';

import ErrorMessage from '../../../../common/components/errors/ErrorMessage';
import LoadingModal from '../../../../common/components/dialogs/LoadingModal';

const GroupMembers = () => {
  const {
    isLoaded,
    groupTypeName,
    GROUP_CONSTANTS
  } = useGroupMembers();

  if (isLoaded === true) {
    switch (groupTypeName) {
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
        return <GroupListMembers />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_COMPETITOR_LIST:
        return <GroupCompetitorListMembers />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_CAMP:
        return <GroupCampMembers />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_TRAVEL_TRIP:
        return <GroupTravelTripMembers />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP:
        return <GroupPracticeMembers />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_SAA:
        return <GroupSAAMembers />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_NATIONAL_TEAM:
        return <GroupNationalTeamMembers />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_CLUB_SITE:
        return <GroupClubSiteMembers />;
      default:
        return <ErrorMessage message={'An error occurred'} />;
    }
  } else {
    return <LoadingModal />;
  }
};

export default GroupMembers;