import { useEffect } from "react";
import { useNavigate } from "../../../../../../common/wrappers/ReactRouterDom";

import useSAAGroupData from "../../../../../state/scholasticAllAmericaGroup/UseSAAGroupData";

import useNavRoutes from "../../../../../../common/state/security/UseNavRoutes";
import useSecurityData from "../../../../../../common/state/security/UseSecurityData";
import useErrorLoggerData from "../../../../../../common/state/errorLogger/UseErrorLoggerData";

import { getErrorObjForLog } from "../../../../../../common/utils/ErrorUtils";

const useSAAGroupsLoadingModal = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { userInfo } = useSecurityData();
  const { scholasticAllAmericaGroupState, resetScholasticAllAmericaGroupState } = useSAAGroupData();
  const { postErrorLog } = useErrorLoggerData();

  const onReloadClicked = (event) => {
    event?.preventDefault();

    resetScholasticAllAmericaGroupState();

    const homeRoute = navRoutes.SAA_GROUP_LANDING?.route;
    if (homeRoute && homeRoute !== window.location.pathname) {
      navigate(homeRoute);
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (scholasticAllAmericaGroupState.isError === true && scholasticAllAmericaGroupState.error) {
      postErrorLog(getErrorObjForLog(scholasticAllAmericaGroupState.error, userInfo.memberId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scholasticAllAmericaGroupState.isError])

  return {
    scholasticAllAmericaGroupState,
    onReloadClicked
  };
};

export default useSAAGroupsLoadingModal;