import { useEffect, useState } from "react";

import { ATTENDEE_TYPE_NAME_ATHLETE, MEMBER_INVITE_STATUS_INVITED, ROLE_TYPE_NAME_ATHLETE } from "../../../utils/NTGroupConstants";

import useNTGroupData from "../../../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupInfoData from "../../../../../state/nationalTeamGroup/nationalTeamGroupInfo/UseNTGroupInfoData";
import useNTGroupMembersData from "../../../../../state/nationalTeamGroup/nationalTeamGroupMembers/UseNTGroupMembersData";
import useNTGroupRoleTypesData from "../../../../../state/nationalTeamGroupRoleTypes/UseNTGroupRoleTypesData";
import useNTGroupAttendeeTypesData from "../../../../../state/nationalTeamGroupAttendeeTypes/UseNTGroupAttendeeTypesData";

const INITIAL_STATE = {
  groupAthleteName: '',
  personId: '',
  canInvite: false,
  groupAthleteEvents: []
};

const useNTGroupInviteAthleteModal = (memberObj, onCloseModal) => {
  const { onNTGroupsError } = useNTGroupData();
  const { groupInfoState } = useNTGroupInfoData();
  const { nationalTeamGroupRoleTypesState, getNTGroupRoleTypes } = useNTGroupRoleTypesData();
  const { nationalTeamGroupAttendeeTypesState, getNTGroupAttendeeTypes } = useNTGroupAttendeeTypesData();
  const { groupMemberPostState, postNTGroupMember } = useNTGroupMembersData();
  const [state, setState] = useState(INITIAL_STATE);

  const onInviteAthleteClicked = (event) => {
    try {
      event?.preventDefault();

      const athleteAttendeeType = nationalTeamGroupAttendeeTypesState.options.find((x) => x.name === ATTENDEE_TYPE_NAME_ATHLETE);
      const athleteRoleType = nationalTeamGroupRoleTypesState.options.find((x) => x.name === ROLE_TYPE_NAME_ATHLETE);

      const groupMemberObj = {
        nationalTeamGroupId: groupInfoState.nationalTeamGroupId,
        effectiveDate: groupInfoState.objData.effectiveDate,
        expirationDate: groupInfoState.objData.expirationDate,
        personNationalTeamGroupEvent: state.groupAthleteEvents,
        personId: state.personId,
        groupAttendeeTypeId: athleteAttendeeType.id,
        groupRoleTypeId: athleteRoleType.id,
        isConfirmed: true,
        inviteStatus: MEMBER_INVITE_STATUS_INVITED
      };

      const postNTGroupMemberPromise = postNTGroupMember(groupMemberObj, true);

      if (postNTGroupMemberPromise ?? false) {
        postNTGroupMemberPromise.then((newState) => {
          if (newState ?? false) {
            onCloseModal(undefined, true);
          }
        }).catch((e) => {
          onNTGroupsError(e);
          onCloseModal();
        });
      }
    } catch (e) {
      onNTGroupsError(e);
      onCloseModal();
    }
  };

  const onDeleteAthleteEventClicked = (event, groupAthleteEvent) => {
    try {
      event?.preventDefault();

      const newGroupAthleteEvents = state.groupAthleteEvents.filter((x) => x.eventId !== groupAthleteEvent.eventId);

      setState({
        ...state,
        groupAthleteEvents: newGroupAthleteEvents,
        canInvite: getCanInvite(newGroupAthleteEvents)
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onSubmitAthleteEventAddForm = (formState) => {
    try {
      const newGroupAthleteEvents = JSON.parse(JSON.stringify(state.groupAthleteEvents));

      newGroupAthleteEvents.push({
        eventId: formState.eventId, // is this unique?
        eventName: formState.eventName,
        swimTime: formState.swimTimeValue,
        swimDate: formState.swimDate,
        meetName: formState.meetName
      });

      setState({
        ...state,
        groupAthleteEvents: newGroupAthleteEvents,
        canInvite: getCanInvite(newGroupAthleteEvents)
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  useEffect(() => {
    try {
      setState({
        ...state,
        groupAthleteName: `${memberObj?.preferredName || memberObj?.firstName} ${memberObj?.lastName}`,
        personId: memberObj.personId
      });
    } catch (e) {
      onNTGroupsError(e);
      onCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberObj]);

  useEffect(() => {
    if (nationalTeamGroupRoleTypesState.areOptionsLoaded !== true) {
      const getNTGroupRoleTypesPromise = getNTGroupRoleTypes();

      if (getNTGroupRoleTypesPromise ?? false) {
        getNTGroupRoleTypesPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamGroupRoleTypesState.areOptionsLoaded]);

  useEffect(() => {
    if (nationalTeamGroupAttendeeTypesState.areOptionsLoaded !== true) {
      const getNTGroupAttendeeTypesPromise = getNTGroupAttendeeTypes();

      if (getNTGroupAttendeeTypesPromise ?? false) {
        getNTGroupAttendeeTypesPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamGroupAttendeeTypesState.areOptionsLoaded]);

  function getCanInvite(newGroupAthleteEvents) {
    return newGroupAthleteEvents.length > 0 ? true : false;
  }

  return {
    isSaving: groupMemberPostState.isObjLoading,
    state,
    onInviteAthleteClicked,
    onDeleteAthleteEventClicked,
    onSubmitAthleteEventAddForm
  };
};

export default useNTGroupInviteAthleteModal;