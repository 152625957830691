import { useEffect, useState } from "react";

import useSAAGroupFiltersData from "../../../state/scholasticAllAmericaGroupFilters/UseSAAGroupFiltersData";

import { DEFAULT_ID } from "../../../../common/utils/Constants";

const INITIAL_DROPDOWN_OPTIONS_STATE = {
  genderOptions: [],
  zoneOptions: [],
  statusOptions: []
};

const useSAAGroupMembersGrid = (gridData) => {
  const { groupMemberFiltersState, updateGroupMemberFilters } = useSAAGroupFiltersData();
  const [dropdownOptionsState, setDropdownOptionsState] = useState(INITIAL_DROPDOWN_OPTIONS_STATE);
  const [gridState, setGridState] = useState([]);

  const onSubmitFilterForm = (filters) => {
    const newGridState = filterGridState(gridState, filters);

    setGridState(newGridState);
    updateGroupMemberFilters(filters);
  };

  useEffect(() => {
    const newGridState = filterGridState(createGridState(gridData), groupMemberFiltersState);

    setGridState(newGridState);
    setDropdownOptionsState(createDropdownOptionsState(gridData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  function createDropdownOptionsState(gridData) {
    const newDropdownOptionsState = {
      genderOptions: [],
      zoneOptions: [],
      statusOptions: []
    };

    for (const member of gridData) {
      if (member.competitionGenderTypeCode && newDropdownOptionsState.genderOptions.some((x) => x.id === member.competitionGenderTypeCode) === false) {
        newDropdownOptionsState.genderOptions.push({ id: member.competitionGenderTypeCode, name: member.competitionGenderTypeName });
      }

      if (member.zoneOrgUnitCode && newDropdownOptionsState.zoneOptions.some((x) => x.id === member.zoneOrgUnitCode) === false) {
        newDropdownOptionsState.zoneOptions.push({ id: member.zoneOrgUnitCode, name: member.zoneOrgUnitName });
      }

      if (member.personOrgGroupQualificationStatus && newDropdownOptionsState.statusOptions.some((x) => x.id === member.personOrgGroupQualificationStatus) === false) {
        newDropdownOptionsState.statusOptions.push({ id: member.personOrgGroupQualificationStatus, name: member.personOrgGroupQualificationStatus });
      }
    }

    if (newDropdownOptionsState.genderOptions.length > 1) {
      newDropdownOptionsState.genderOptions.unshift({ id: DEFAULT_ID, name: '--' });
    }

    if (newDropdownOptionsState.zoneOptions.length > 1) {
      newDropdownOptionsState.zoneOptions.unshift({ id: DEFAULT_ID, name: '--' });
    }

    if (newDropdownOptionsState.statusOptions.length > 1) {
      newDropdownOptionsState.statusOptions.unshift({ id: DEFAULT_ID, name: '--' });
    }

    return newDropdownOptionsState;
  }

  function createGridState(gridData) {
    const newGridData = [];

    for (const member of gridData) {
      newGridData.push({
        personId: member.personId,
        firstName: member.firstName,
        lastName: member.lastName,
        // eventName: member.eventName,
        personOrgGroupId: member.personOrgGroupId,
        personOrgGroupQualificationStatus: member.personOrgGroupQualificationStatus,
        competitionGenderTypeCode: member.competitionGenderTypeCode,
        competitionGenderTypeName: member.competitionGenderTypeName,
        zoneOrgUnitCode: member.zoneOrgUnitCode,
        zoneOrgUnitName: member.zoneOrgUnitName,
        displayInGrid: true
      });
    }

    return newGridData;
  }

  function filterGridState(gridState, filters) {
    const newGridState = JSON.parse(JSON.stringify(gridState));

    for (const member of newGridState) {
      member.displayInGrid = true;

      if (filters.competitionGenderTypeCode && filters.competitionGenderTypeCode !== DEFAULT_ID && member.competitionGenderTypeCode !== filters.competitionGenderTypeCode) {
        member.displayInGrid = false;
      }

      if (filters.zoneOrgUnitCode && filters.zoneOrgUnitCode !== DEFAULT_ID && member.zoneOrgUnitCode !== filters.zoneOrgUnitCode) {
        member.displayInGrid = false;
      }

      if (filters.personOrgGroupQualificationStatus && filters.personOrgGroupQualificationStatus !== DEFAULT_ID && member.personOrgGroupQualificationStatus !== filters.personOrgGroupQualificationStatus) {
        member.displayInGrid = false;
      }
    }

    return newGridState;
  }

  return {
    ...dropdownOptionsState,
    previousSearchFilters: groupMemberFiltersState,
    gridState: gridState.filter((member) => member.displayInGrid === true),
    onSubmitFilterForm
  };
};

export default useSAAGroupMembersGrid;