import SGColumnHeader from "../../../grids/SGColumnHeader";

import useSortableGrid from "../../../../utils/UseSortableGrid";
import SGConstants from "../../../../utils/SGConstants";

import global from '../../../GlobalStyle.module.css';

const GridRow = ({ group, onEditGroupClicked }) => (
  <tr>
    <td><a onClick={(e) => onEditGroupClicked(e, group)}>{group?.groupName || ''}</a></td>
    <td>{group?.groupCode || ''}</td>
    <td>{group?.orgUnitName || ''}</td>
    <td>{group?.groupCategory || ''}</td>
    <td>{group?.groupType || ''}</td>
  </tr>
);

const SearchGroupGridLarge = ({ gridData, isLoading, onEditGroupClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group Name'} columnField={'groupName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group Code'} columnField={'groupCode'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group Owner'} columnField={'orgUnitName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group Category / Hierarchy'} columnField={'groupCategory'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group Type'} columnField={'groupType'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="5">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((group, i) => <GridRow key={i} group={group} onEditGroupClicked={onEditGroupClicked} />)
            : <tr><td colSpan="5">No Groups</td></tr>
        }
      </tbody>
    </table>
  );
};

export default SearchGroupGridLarge;