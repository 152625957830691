import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

const postNTGroupApprovalData = (nationalTeamGroupId, personNationalTeamGroupId, groupApprovalArray, state, setState) => {
  if (groupApprovalArray) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const nationalTeamGroupIdForUrl = nationalTeamGroupId ? encodeURIComponent(nationalTeamGroupId) : 'NaN';
    const personNationalTeamGroupIdForUrl = personNationalTeamGroupId ? encodeURIComponent(personNationalTeamGroupId) : 'NaN';
    const url = `/NationalTeamGroup/${nationalTeamGroupIdForUrl}/Member/PersonNationalTeamGroup/${personNationalTeamGroupIdForUrl}/Registration`;

    return api.executeArray ? api.executeArray(url, 'POST', groupApprovalArray) : null;
  }
};

const getNTGroupApprovalData = (nationalTeamGroupId, personNationalTeamGroupId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const nationalTeamGroupIdForUrl = nationalTeamGroupId ? encodeURIComponent(nationalTeamGroupId) : 'NaN';
  const personNationalTeamGroupIdForUrl = personNationalTeamGroupId ? encodeURIComponent(personNationalTeamGroupId) : 'NaN';
  const url = `/NationalTeamGroup/${nationalTeamGroupIdForUrl}/Member/PersonNationalTeamGroup/${personNationalTeamGroupIdForUrl}/Registration`;

  return api.executeArray ? api.executeArray(url, 'GET') : null;
};

const INITIAL_GROUP_APPROVAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_GROUP_APPROVAL_SUBMIT_STATE = {
  ...BASIC_INITIAL_STATE
};

const NTGroupApprovalData = {
  INITIAL_GROUP_APPROVAL_STATE,
  INITIAL_GROUP_APPROVAL_SUBMIT_STATE,
  postNTGroupApprovalData,
  getNTGroupApprovalData
};

export default NTGroupApprovalData;