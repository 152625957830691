import { Fragment } from "react";

import useNTGroupApprovals from "./UseNTGroupApprovals";

import Headings from "../../../../common/components/headings/Headings";
import NTGroupsLoadingModal from "../components/dialogs/loading/NTGroupsLoadingModal";
import NTGroupAthleteApprovalsGrid from "../components/grids/athleteApprovals/NTGroupAthleteApprovalsGrid";
import NTGroupAthleteApprovalModal from "../components/dialogs/athleteApproval/NTGroupAthleteApprovalModal";

const NTGroupApprovals = () => {
  const {
    isGridLoading,
    gridData,
    state,
    onCloseApprovalPopup,
    onOpenApprovalPopupClicked,
  } = useNTGroupApprovals();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Approvals</Headings.H3>
        </div>
      </div>
      <NTGroupAthleteApprovalsGrid
        gridData={gridData}
        isLoading={isGridLoading}
        onEditClicked={onOpenApprovalPopupClicked} />
      {state.displayApprovalPopup === true &&
        <NTGroupAthleteApprovalModal memberObj={state.approvalGroupMemberObj} onCloseModal={onCloseApprovalPopup} />
      }
      <NTGroupsLoadingModal isLoading={false} />
    </Fragment>
  );
};

export default NTGroupApprovals;