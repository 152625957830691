import { useEffect } from 'react';

import validate from './NTGroupInfoFormValidation';

import useNTGroupData from '../../../../../state/nationalTeamGroup/UseNTGroupData';

import useEnvironmentVariableData from '../../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from "../../../../../../common/utils/UseForm";
import { BLANK_DATE_STRING, DEFAULT_ID } from '../../../../../../common/utils/Constants';
import { formatDate } from '../../../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  nationalTeamGroupTypeId: DEFAULT_ID,
  nationalTeamGroupTypeName: '',
  nationalTeamGroupName: '',
  nationalTeamGroupCode: '',
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING,
  registrationStartDate: BLANK_DATE_STRING,
  registrationEndDate: BLANK_DATE_STRING,
  meetId: DEFAULT_ID,
  meetName: '',
  hasRegistration: false,
  hasMeet: false,
  areDatesReadonly: false
};

const useNTGroupInfoForm = (groupInfoObj, hasMembers, onSubmitFormCallback) => {
  const { onNTGroupsError } = useNTGroupData();
  const { FINA_ORG_UNIT_ID, ORG_APPROVED_MEET_CLASSIFICATION_ID } = useEnvironmentVariableData();
  const { formState, errorState, handleSubmit, updateFormState, setFormData, resetForm
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try {
      if (groupInfoObj) {
        setFormData({
          ...formState,
          nationalTeamGroupTypeId: groupInfoObj.nationalTeamGroupTypeId || DEFAULT_ID,
          nationalTeamGroupTypeName: groupInfoObj.groupTypeName || '',
          nationalTeamGroupName: groupInfoObj.nationalTeamGroupName || '',
          nationalTeamGroupCode: groupInfoObj.nationalTeamGroupCode || '',
          effectiveDate: groupInfoObj.effectiveDate ? formatDate(groupInfoObj.effectiveDate) : BLANK_DATE_STRING,
          expirationDate: groupInfoObj.expirationDate ? formatDate(groupInfoObj.expirationDate) : BLANK_DATE_STRING,
          registrationStartDate: groupInfoObj.registrationStartDate ? formatDate(groupInfoObj.registrationStartDate) : BLANK_DATE_STRING,
          registrationEndDate: groupInfoObj.registrationEndDate ? formatDate(groupInfoObj.registrationEndDate) : BLANK_DATE_STRING,
          meetId: groupInfoObj.meetId || DEFAULT_ID,
          meetName: groupInfoObj.meetName || '',
          hasRegistration: groupInfoObj.hasRegistration === true,
          hasMeet: groupInfoObj.meetRequired === true,
          areDatesReadonly: hasMembers === true
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupInfoObj]);

  return {
    areDatesReadonly: formState.areDatesReadonly === true,
    hasRegistration: formState.hasRegistration === true,
    hasMeet: formState.hasMeet === true,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    setFormData,
    resetForm,
    FINA_ORG_UNIT_ID,
    ORG_APPROVED_MEET_CLASSIFICATION_ID
  };
};

export default useNTGroupInfoForm;