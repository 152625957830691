import { Fragment } from 'react';

import NTGroupAthletesGridLarge from './NTGroupAthletesGridLarge';
import NTGroupAthletesGridSmall from './NTGroupAthletesGridSmall';

import useExpandedRow from '../../../../../../common/components/grids/UseExpandedRow';

const NTGroupAthletesGrid = ({ gridData, isLoading, onEditClicked, onDeleteClicked }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <NTGroupAthletesGridLarge gridData={gridData} isLoading={isLoading} expandedId={expandedId} onExpandClicked={onClick} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
      <NTGroupAthletesGridSmall gridData={gridData} isLoading={isLoading} expandedId={expandedId} onExpandClicked={onClick} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
    </Fragment>
  );
};

export default NTGroupAthletesGrid;