import { Fragment } from "react";

import useNTGroupInfoForm from "./UseNTGroupInfoForm";

import Input from "../../../../../../common/components/inputs/Input";
import ReadOnly from "../../../../../../common/components/readOnly/ReadOnly";
import DatePicker from "../../../../../../common/components/datepickers/DatePicker";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";
import SearchMeetPopup from "../../../../../../common/components/searches/searchMeet/SearchMeetPopup";

import global from '../../../../../../common/components/GlobalStyle.module.css';

const NTGroupInfoForm = ({ groupInfoObj, submitButtonText = 'Save', hasMembers,
  onSubmitFormCallback, onDeleteGroupClicked }) => {
  const {
    areDatesReadonly,
    hasRegistration,
    hasMeet,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    setFormData,
    resetForm,
    FINA_ORG_UNIT_ID,
    ORG_APPROVED_MEET_CLASSIFICATION_ID
  } = useNTGroupInfoForm(groupInfoObj, hasMembers, onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label={'Group Type'}
            name={'nationalTeamGroupTypeId'}
            value={formState.nationalTeamGroupTypeName} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Group Name*'
            name="nationalTeamGroupName"
            value={formState.nationalTeamGroupName}
            error={errorState.nationalTeamGroupName}
            message={errorState.nationalTeamGroupName}
            onChange={(value) => { updateFormState('nationalTeamGroupName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Group Code*'
            name="nationalTeamGroupCode"
            value={formState.nationalTeamGroupCode}
            error={errorState.nationalTeamGroupCode}
            message={errorState.nationalTeamGroupCode}
            onChange={(value) => { updateFormState('nationalTeamGroupCode', value) }} />
        </div>
        {areDatesReadonly === true
          ? (
            <Fragment>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label={'Effective Date'}
                  name={'effectiveDate'}
                  value={formState.effectiveDate} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label={'Expiration Date'}
                  name={'expirationDate'}
                  value={formState.expirationDate} />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <DatePicker
                  label="Effective Date*"
                  name="effectiveDate"
                  value={formState.effectiveDate}
                  error={errorState.effectiveDate}
                  message={errorState.effectiveDate}
                  onChange={(value) => { updateFormState('effectiveDate', value); }}
                  countOfYears={30}
                  startYearOffset={-25} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <DatePicker
                  label="Expiration Date*"
                  name="expirationDate"
                  value={formState.expirationDate}
                  error={errorState.expirationDate}
                  message={errorState.expirationDate}
                  onChange={(value) => { updateFormState('expirationDate', value); }}
                  countOfYears={30}
                  startYearOffset={-25} />
              </div>
            </Fragment>
          )
        }
        {hasMeet && FINA_ORG_UNIT_ID && ORG_APPROVED_MEET_CLASSIFICATION_ID &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMeetPopup
              label="Meet*"
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              hostOrganizationId={FINA_ORG_UNIT_ID}
              meetClassificationId={ORG_APPROVED_MEET_CLASSIFICATION_ID} />
          </div>
        }
        {hasRegistration &&
          <Fragment>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <DatePicker
                label="Registration Start Date*"
                name="registrationStartDate"
                value={formState.registrationStartDate}
                error={errorState.registrationStartDate}
                message={errorState.registrationStartDate}
                onChange={(value) => { updateFormState('registrationStartDate', value); }}
                countOfYears={30}
                startYearOffset={-25} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <DatePicker
                label="Registration End Date*"
                name="registrationEndDate"
                value={formState.registrationEndDate}
                error={errorState.registrationEndDate}
                message={errorState.registrationEndDate}
                onChange={(value) => { updateFormState('registrationEndDate', value); }}
                countOfYears={30}
                startYearOffset={-25} />
            </div>
          </Fragment>
        }
      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>&nbsp;
          {hasMembers === false &&
            <PrimaryButton type="button" onClick={onDeleteGroupClicked}>Delete Group</PrimaryButton>
          }
        </div>
      </div>
    </form>
  );
};

export default NTGroupInfoForm;