import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';


import { createGroupObjFromGroupInfoForm } from '../utils/GroupUtils';

import validate from '../../../../common/components/forms/groups/GroupInfoFormValidation';

import useGroupData from '../../../../common/state/group/UseGroupData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useSecurityData from '../../../../common/state/security/UseSecurityData';
import useGroupCategoryData from '../../../../common/state/groupCategory/UseGroupCategoryData';

import useForm from '../../../../common/utils/UseForm';
import HierarchicalDataUtils from '../../../../common/utils/HierarchicalDataUtils';
import Constants from '../../../../common/utils/Constants';

const INITIAL_MODAL_STATE = {
  displayModal: false,
  deleteGroup: false,
  groupName: '',
  groupType: '',
  groupCategory: ''
};

const useGroupInfo = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { groupCategoryState } = useGroupCategoryData();
  const { groupState, putGroup, deleteGroup } = useGroupData();
  const { securityState } = useSecurityData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormData, resetForm
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onDeleteGroupClicked = () => {
    const groupObj = groupState.objData;

    setModalState({
      ...modalState,
      displayModal: true,
      groupName: groupObj.groupName || '',
      groupType: groupObj.groupType?.typeName || '',
      groupCategory: groupObj.groupCategory?.categoryName || ''
    });
  };

  const onDeleteModalConfirmClicked = async (e) => {
    e?.preventDefault();

    await deleteGroup(groupState.objData.orgGroupId);

    if (Array.isArray(securityState?.userInfo?.appRoutes)) {
      const appRoutes = securityState.userInfo.appRoutes;

      if (appRoutes.some(x => x.routeName === 'GROUP_SEARCH')) {
        navigate(navRoutes.GROUP_SEARCH?.route);
      } else if (appRoutes.some(x => x.routeName === 'GROUP_MY_GROUPS')) {
        navigate(navRoutes.GROUP_MY_GROUPS?.route);
      } else {
        navigate('/');
      }
    } else {
      navigate('/');
    }
  };

  const onDeleteModalCancelClicked = () => {
    setModalState(INITIAL_MODAL_STATE);
  };

  useEffect(() => {
    if (groupCategoryState.isArrayLoaded === true && groupCategoryState.id === groupState.objData.orgUnitId
      && groupState.isObjLoaded === true) {
      const groupObj = groupState.objData;

      setFormData({
        ...formState,
        orgUnitId: groupObj.orgUnitId,
        groupTypeId: groupObj.groupTypeId,
        groupTypeName: groupObj.groupType?.typeName || '',
        groupCategories: HierarchicalDataUtils.GetNameIdPairs(groupCategoryState.treeData, [groupObj.groupCategoryId]),
        groupName: groupObj.groupName,
        groupCode: groupObj.groupCode,
        groupDescription: groupObj.groupDescription
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCategoryState, groupState]);

  function submitFormCallback(formState) {
    if (groupState.isObjLoading === false) {
      putGroup(groupState.objData.orgGroupId, createGroupObjFromGroupInfoForm(groupState.objData, formState));
    }
  }

  function getInitialFormState() {
    return {
      orgUnitId: '',
      groupTypeId: Constants.DEFAULT_ID,
      groupTypeName: '',
      groupCategories: [],
      groupName: '',
      groupCode: '',
      groupDescription: ''
    };
  }

  return {
    isLoading: groupCategoryState.isArrayLoading,
    isSaving: groupState.isObjLoading,
    formState,
    errorState,
    modalState,
    handleSubmit,
    onFormValueChanged,
    onCancelClicked: resetForm,
    onDeleteGroupClicked,
    onDeleteModalConfirmClicked,
    onDeleteModalCancelClicked,
    orgUnitId: groupState.objData.orgUnitId || ''
  };
};

export default useGroupInfo;