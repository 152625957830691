import { Fragment } from 'react';

import useSAAGroupLanding from './UseSAAGroupLanding';

import SAAGroupsLoadingModal from '../components/dialogs/loading/SAAGroupsLoadingModal';

const SAAGroupLanding = () => {
  useSAAGroupLanding();

  return (
    <Fragment>
      <SAAGroupsLoadingModal isLoading={true} />
    </Fragment>
  );
};

export default SAAGroupLanding;