import { createContext, useState } from 'react';

import GroupSearchData from './GroupSearchData';

export const GroupFiltersContext = createContext();

const GroupFiltersContextProvider = ({ children }) => {
  const stateHook = useState(GroupSearchData.INITIAL_GROUP_FILTERS_STATE);

  return (
    <GroupFiltersContext.Provider value={stateHook}>
      {children}
    </GroupFiltersContext.Provider>
  );
};

export default GroupFiltersContextProvider;