import { useEffect, useState } from 'react';

import useSAAGroupInfoData from '../../../state/scholasticAllAmericaGroup/scholasticAllAmericaGroupInfo/UseSAAGroupInfoData';

const INITIAL_VIEW_STATE = {
  reportParameters: { orgGroupId: '' },
  contextId: '',
  routeName: ''
};

const useSAAGroupReports = () => {
  const [viewState, setViewState] = useState({ ...INITIAL_VIEW_STATE });
  const { groupInfoState } = useSAAGroupInfoData();

  useEffect(() => {
    if (groupInfoState.objData?.orgGroupId) {
      setViewState({
        ...viewState,
        reportParameters: { orgGroupId: groupInfoState.objData?.orgGroupId },
        contextId: groupInfoState.objData?.orgGroupId,
        routeName: 'SAA_GROUP_REPORTS'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupInfoState.objData]);

  return { ...viewState };
};

export default useSAAGroupReports;