import { Fragment } from "react";

import useNTGroupAthletesBulkInvite from "./UseNTGroupAthletesBulkInvite";

import NTGroupsLoadingModal from "../components/dialogs/loading/NTGroupsLoadingModal";
import NTGroupInviteAthletesFormGrid from "../components/grids/inviteAthletesForm/NTGroupInviteAthletesFormGrid";

import Headings from "../../../../common/components/headings/Headings";

const NTGroupAthletesBulkInvite = ({ updateAthletePillStep }) => {
  const {
    isSaving,
    gridData,
    onSubmitAthletesFormGrid,
    onEditGroupAthleteClicked
  } = useNTGroupAthletesBulkInvite(updateAthletePillStep);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Athletes</Headings.H3>
        </div>
      </div>
      <NTGroupInviteAthletesFormGrid
        gridData={gridData}
        isLoading={gridData.length === 0}
        onEditClicked={onEditGroupAthleteClicked}
        onSubmitFormCallback={onSubmitAthletesFormGrid} />
      <NTGroupsLoadingModal
        isLoading={isSaving}
        loadingMessage={'Sending Invites...'} />
    </Fragment>
  );
};

export default NTGroupAthletesBulkInvite;