import { DEFAULT_ID } from "../../../../common/utils/Constants";
import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

const getSAAGroupMembersData = (orgGroupId, state, setState) => {
  if (orgGroupId > 0) {
    const newState = {
      ...state,
      orgGroupId
    };

    const api = initApi(API_NAMES.COMMON, newState, setState);

    const orgGroupIdForUrl = encodeURIComponent(orgGroupId);
    const url = `/OrgGroup/Member/${orgGroupIdForUrl}`;

    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const INITIAL_GROUP_MEMBERS_STATE = {
  ...BASIC_INITIAL_STATE,
  orgGroupId: DEFAULT_ID
};

const SAAGroupMembersData = {
  INITIAL_GROUP_MEMBERS_STATE,
  getSAAGroupMembersData
};

export default SAAGroupMembersData;