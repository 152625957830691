import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useSAAGroupMembersData from "../../../state/scholasticAllAmericaGroup/scholasticAllAmericaGroupMembers/UseSAAGroupMembersData";
import useSAAGroupData from "../../../state/scholasticAllAmericaGroup/UseSAAGroupData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

const useSAAGroupMembers = () => {
  const navigate = useNavigate();
  const { onSAAGroupsError } = useSAAGroupData();
  const { navRoutes } = useNavRoutes();
  const { groupMembersState } = useSAAGroupMembersData();

  const onEditGroupMemberClicked = (event, member) => {
    try {
      event?.preventDefault();

      navigate(navRoutes.SAA_GROUP_MEMBERS_DETAIL?.route, { state: { member } });
    } catch (e) {
      onSAAGroupsError(e);
    }
  };

  return {
    isGridLoading: groupMembersState.isObjLoading,
    gridData: groupMembersState.arrayData || [],
    onEditGroupMemberClicked
  };
};

export default useSAAGroupMembers;