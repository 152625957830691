import { useEffect } from 'react';

import { createOrgGroupFieldValues } from '../../utils/GroupUtils';

import validate from '../../components/forms/NationalTeamAttributesFormValidation';

import useOrgGroupPropertiesData from '../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';
import useGroupData from '../../../../../common/state/group/UseGroupData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const useGroupNationalTeamAttributes = () => {
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { groupState, putGroup } = useGroupData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormData, resetForm
  } = useForm(getInitialFormState, submitFormCallback, validate);

  useEffect(() => {
    if (groupState.isObjLoaded === true) {
      if (orgGroupPropertiesState.isArrayLoaded === true &&
        orgGroupPropertiesState.groupTypeId === groupState.objData.groupTypeId) {
        const groupObj = groupState.objData;

        setFormData({
          ...formState,
          groupYear: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_YEAR)?.fieldValue,
          startDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE)?.fieldValue,
          endDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE)?.fieldValue
        });
      } else if (orgGroupPropertiesState.isArrayLoading === false && groupState.objData.groupTypeId) {
        getOrgGroupProperties(groupState.objData.groupTypeId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupPropertiesState, groupState]);

  function submitFormCallback(formState) {
    if (groupState.isObjLoading === false) {
      const newOrgGroupObj = JSON.parse(JSON.stringify(groupState.objData));

      newOrgGroupObj.orgGroupFieldValue = createOrgGroupFieldValues([
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_YEAR, fieldValue: formState.groupYear },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE, fieldValue: formState.startDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE, fieldValue: formState.endDate }
      ], orgGroupPropertiesState.arrayData, newOrgGroupObj.orgGroupId);

      putGroup(newOrgGroupObj.orgGroupId, newOrgGroupObj);
    }
  }

  function getInitialFormState() {
    return {
      groupYear: 'Year',
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING
    };
  }

  return {
    isSaving: groupState.isObjLoading,
    isLoading: orgGroupPropertiesState.isArrayLoading,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onCancelClicked: resetForm
  };
};

export default useGroupNationalTeamAttributes;