import { useEffect, useState } from "react";

import useNTGroupData from "../../../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupRoleTypesData from "../../../../../state/nationalTeamGroupRoleTypes/UseNTGroupRoleTypesData";
import useNTGroupAttendeeTypesData from "../../../../../state/nationalTeamGroupAttendeeTypes/UseNTGroupAttendeeTypesData";

import { POTENTIAL_CONFIRMED_OPTIONS } from "../../../utils/NTGroupConstants";

const useNTGroupMembersFormGrid = (gridData, onSubmitFormCallback) => {
  const { onNTGroupsError } = useNTGroupData();
  const { nationalTeamGroupRoleTypesState, getNTGroupRoleTypes } = useNTGroupRoleTypesData();
  const { nationalTeamGroupAttendeeTypesState, getNTGroupAttendeeTypes } = useNTGroupAttendeeTypesData();
  const [initialFormGridState, setInitialFormGridState] = useState([]);
  const [formGridState, setFormGridState] = useState([]);
  const [canSaveState, setCanSaveState] = useState(false);

  const onSaveClicked = (event) => {
    try {
      event?.preventDefault();

      const updatedGroupMembers = [];

      for (const groupMemberFormObj of formGridState) {
        if (groupMemberFormObj.isDirty === true) {
          updatedGroupMembers.push({
            personNationalTeamGroupId: groupMemberFormObj.personNationalTeamGroupId,
            groupAttendeeTypeId: groupMemberFormObj.groupAttendeeTypeId,
            groupRoleTypeId: groupMemberFormObj.groupRoleTypeId,
            isConfirmed: POTENTIAL_CONFIRMED_OPTIONS.find((x) => x.id === groupMemberFormObj.isConfirmedTypeId).value
          });
        }
      }

      onSubmitFormCallback(updatedGroupMembers);
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onCancelClicked = (event) => {
    try {
      event?.preventDefault();

      const newFormGridState = JSON.parse(JSON.stringify(initialFormGridState));

      setFormGridState(newFormGridState);
      setCanSaveState(getCanSave(newFormGridState));
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onUpdateFormGridState = (personNationalTeamGroupId, newValue, valuePropertyName, newValueLabel, labelPropertyName) => {
    try {
      const newFormGridState = [...formGridState];

      for (let i = 0; i < newFormGridState.length; i++) {
        if (newFormGridState[i].personNationalTeamGroupId === personNationalTeamGroupId) {
          newFormGridState[i][valuePropertyName] = newValue;
          newFormGridState[i][labelPropertyName] = newValueLabel;
          newFormGridState[i].isDirty = getIsGroupMemberDirty(newFormGridState[i]);

          break;
        }
      }

      setFormGridState(newFormGridState);
      setCanSaveState(getCanSave(newFormGridState));
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  useEffect(() => {
    try {
      const newFormGridState = createFormGridState(gridData, formGridState);
      const newInitialFormGridState = createFormGridState(gridData);

      setFormGridState(newFormGridState);
      setInitialFormGridState(newInitialFormGridState);
      setCanSaveState(getCanSave(newFormGridState));
    } catch (e) {
      onNTGroupsError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  useEffect(() => {
    if (nationalTeamGroupRoleTypesState.areOptionsLoaded !== true) {
      const getNTGroupRoleTypesPromise = getNTGroupRoleTypes();

      if (getNTGroupRoleTypesPromise ?? false) {
        getNTGroupRoleTypesPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamGroupRoleTypesState.areOptionsLoaded]);

  useEffect(() => {
    if (nationalTeamGroupAttendeeTypesState.areOptionsLoaded !== true) {
      const getNTGroupAttendeeTypesPromise = getNTGroupAttendeeTypes();

      if (getNTGroupAttendeeTypesPromise ?? false) {
        getNTGroupAttendeeTypesPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamGroupAttendeeTypesState.areOptionsLoaded]);

  function getCanSave(newFormGridState) {
    for (const groupMember of newFormGridState) {
      if (groupMember.isDirty === true) {
        return true;
      }
    }

    return false;
  }

  function getIsGroupMemberDirty(groupMemberFormObj, initialGroupMemberFormObj = undefined) {
    if (initialGroupMemberFormObj === undefined) {
      initialGroupMemberFormObj = initialFormGridState.find((x) => x.personNationalTeamGroupId === groupMemberFormObj.personNationalTeamGroupId);
    }

    if (initialGroupMemberFormObj?.groupAttendeeTypeId !== groupMemberFormObj.groupAttendeeTypeId
      || initialGroupMemberFormObj?.groupRoleTypeId !== groupMemberFormObj.groupRoleTypeId
      || initialGroupMemberFormObj?.isConfirmedTypeId !== groupMemberFormObj.isConfirmedTypeId) {
      return true;
    } else {
      return false;
    }
  }

  function createFormGridState(gridData, existingFormGridState = undefined) {
    const newFormGridState = [];

    for (const groupMemberObj of gridData) {
      const newGroupMemberFormObj = createGroupMemberFormObj(groupMemberObj);

      if (existingFormGridState) {
        const existingGroupMemberFormObj = existingFormGridState.find((x) => x.personNationalTeamGroupId === groupMemberObj.personNationalTeamGroupId);

        if (existingGroupMemberFormObj && getIsGroupMemberDirty(existingGroupMemberFormObj, newGroupMemberFormObj) === true) {
          newFormGridState.push({
            ...existingGroupMemberFormObj,
            isDirty: true
          });
        } else {
          newFormGridState.push(newGroupMemberFormObj);
        }
      } else {
        newFormGridState.push(newGroupMemberFormObj);
      }
    }

    return newFormGridState;
  }

  function createGroupMemberFormObj(groupMemberObj) {
    const confirmedOption = POTENTIAL_CONFIRMED_OPTIONS.find((x) => x.value === groupMemberObj.isConfirmed);

    return {
      personNationalTeamGroupId: groupMemberObj.personNationalTeamGroupId,
      personId: groupMemberObj.personId,
      groupMemberName: `${groupMemberObj?.preferredName || groupMemberObj?.firstName} ${groupMemberObj?.lastName}`,
      groupAttendeeTypeId: groupMemberObj.groupAttendeeTypeId,
      attendeeType: groupMemberObj.attendeeType,
      groupRoleTypeId: groupMemberObj.groupRoleTypeId,
      roleType: groupMemberObj.roleType,
      isConfirmedTypeId: confirmedOption?.id || POTENTIAL_CONFIRMED_OPTIONS[0].id,
      isConfirmedType: confirmedOption?.name || POTENTIAL_CONFIRMED_OPTIONS[0].name,
      isDirty: false
    };
  }

  return {
    areOptionsLoaded: nationalTeamGroupAttendeeTypesState.areOptionsLoaded === true && nationalTeamGroupRoleTypesState.areOptionsLoaded === true,
    canSaveState,
    formGridState,
    onUpdateFormGridState,
    onSaveClicked,
    onCancelClicked
  };
};

export default useNTGroupMembersFormGrid;