import { useState } from 'react';

import MeetLookupData from './MeetLookupData';

const useMeetLookupData = () => {
  const [meetLookupState, setMeetLookupState] = useState(MeetLookupData.INITIAL_STATE);

  const getMeetLookupByName = (meetName, hostOrganizationId, meetClassificationId = undefined) => {
    return MeetLookupData.getMeetLookupByNameData(meetName, hostOrganizationId, meetClassificationId, meetLookupState, setMeetLookupState);
  };

  return {
    meetLookupState,
    getMeetLookupByName
  };
};

export default useMeetLookupData;