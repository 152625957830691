import useGroupSearchData from '../../../state/groupSearch/UseGroupSearchData';

const useSearchGroup = () => {
  const { groupSearchState, groupFiltersState, searchGroups, onToggleShowFilters } = useGroupSearchData();
  
  const onSubmitSearchForm = (formState) => {
    if (groupSearchState.isArrayLoading === false) {
      const filterObject = {
        orgUnitId: formState.orgUnit.length > 0 ? formState.orgUnit[0].id : undefined,
        groupCategoryId: formState.groupCategories.length > 0 ? formState.groupCategories.map(x => x.id) : undefined,
        groupName: formState.groupName ? formState.groupName.trim() : undefined,
        groupCode: formState.groupCode ? formState.groupCode.trim() : undefined
      };

      searchGroups(filterObject);
    }
  };

  return {
    gridData: groupSearchState.arrayData || [],
    isLoading: groupSearchState.isArrayLoading,
    showFilters: groupFiltersState.showFilters,
    previousSearchFilters: groupFiltersState.filterObject || {},
    onToggleShowFilters,
    onSubmitSearchForm
  };
};

export default useSearchGroup;