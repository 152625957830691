import { useEffect, useState } from "react";
import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";

import useGroupAlternateMembersData from "../../../../state/groupAlternateMembers/UseGroupAlternateMembersData";

import useGroupData from "../../../../../common/state/group/UseGroupData";

const useGroupInviteAlternateMembers = () => {
  const navigate = useNavigate();
  const { groupState, groupMembersState, getGroupMembers } = useGroupData();
  const { groupAlternateMembersState, getGroupAlternateMembers, putGroupAlternateMembers, updateMemberEventIsSelected
  } = useGroupAlternateMembersData();
  const [selectedPersonsState, setSelectedPersonsState] = useState([]);

  const onInviteAthletesClicked = async (event) => {
    event?.preventDefault();

    if (groupAlternateMembersState.arrayData.some(x => x.isSelected === true)) {
      const putGroupAlternateMembersResult = await putGroupAlternateMembers(groupState.objData.orgGroupId, groupAlternateMembersState.arrayData);
    
      if (putGroupAlternateMembersResult) {
       const getGroupMembersResult = await getGroupMembers(groupState.objData.orgGroupId);

       if (getGroupMembersResult) {
         navigate(NavLinks.GROUP_MEMBERS);
       }
      }
    }
  };

  const onBackClicked = () => {
    navigate(NavLinks.GROUP_MEMBERS);
  };

  const onCheckboxClicked = (groupQualificationEventId, personOrgGroupId) => {
    if (personOrgGroupId && groupAlternateMembersState.isArrayLoading !== true) {
      if (selectedPersonsState.some(x => x === personOrgGroupId)) {
        setSelectedPersonsState(selectedPersonsState.filter(x => x !== personOrgGroupId));
      } else {
        const newSelectedPersonsState = selectedPersonsState;

        newSelectedPersonsState.push(personOrgGroupId);

        setSelectedPersonsState(newSelectedPersonsState);
      }

      updateMemberEventIsSelected(groupQualificationEventId);
    }
  };

  useEffect(() => {
    if (groupState.isObjLoaded === true) {
      getGroupAlternateMembers(groupState.objData.orgGroupId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSaving: groupAlternateMembersState.isSaving,
    isLoading: groupMembersState.isArrayLoading,
    isGridLoading: groupAlternateMembersState.isArrayLoading,
    gridData: groupAlternateMembersState.arrayData,
    selectedPersonsState,
    onInviteAthletesClicked,
    onBackClicked,
    onCheckboxClicked
  };
};

export default useGroupInviteAlternateMembers;