import { Fragment } from 'react';

import SearchGroup from '../../../../common/components/searches/searchGroup/SearchGroup';

import useGroupSearch from './UseGroupSearch';

const GroupSearch = () => {
  const {
    onAddGroupClicked,
    onEditGroupClicked
  } = useGroupSearch();

  return (
    <Fragment>
      <SearchGroup
        pageTitle={'Group Search'}
        onAddGroupClicked={onAddGroupClicked}
        onEditGroupClicked={onEditGroupClicked} />
    </Fragment>
  );
};

export default GroupSearch;