import { useEffect, useState } from "react";
import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";

import useGroupData from "../../../../../common/state/group/UseGroupData";
import useMemberGroupsData from "../../../../../common/state/memberGroups/UseMemberGroupsData";
import useGroupFieldNamesData from "../../../../../common/state/groupFieldNames/UseGroupFieldNamesData";

import { DEFAULT_ID } from "../../../../../common/utils/Constants";

const INITIAL_MODAL_STATE = {
  displayModal: false,
  personOrgGroupId: DEFAULT_ID,
  firstName: '',
  lastName: '',
};

const useGroupCompetitorListMembers = () => {
  const navigate = useNavigate();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { groupState, groupMembersState, getGroupMembers } = useGroupData();
  const { memberGroupsState, deleteGroupMember, resetMemberGroupsState } = useMemberGroupsData();
  const [gridState, setGridState] = useState([]);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onInviteAlternateClicked = () => {
    navigate(NavLinks.GROUP_MEMBERS_INVITE_ALTERNATE);
  };

  const onAddMemberToGroupClicked = () => {
    navigate(NavLinks.GROUP_MEMBERS_ADD);
  };

  const onEditGroupMemberClicked = (member) => {
    navigate(NavLinks.GROUP_MEMBERS_EDIT, { state: { member } });
  };

  const onDeleteModalConfirmClicked = async (event) => {
    event?.preventDefault();

    const personOrgGroupId = modalState.personOrgGroupId;

    setModalState({ ...INITIAL_MODAL_STATE });

    const deleteGroupMemberResult = await deleteGroupMember(personOrgGroupId);

    if (deleteGroupMemberResult) {
      resetMemberGroupsState();
      setGridState([]);

      const getGroupMemberResult = await getGroupMembers(groupState.objData.orgGroupId);

      if (getGroupMemberResult) {
        setGridState(createMemberGridState(getGroupMemberResult.arrayData || []));
      }
    }
  };

  const onDeleteModalCancelClicked = () => {
    setModalState({ ...INITIAL_MODAL_STATE });
  };

  const onDeleteGroupMemberClicked = (member) => {
    setModalState({
      ...modalState,
      displayModal: true,
      firstName: member.firstName,
      lastName: member.lastName,
      personOrgGroupId: member.personOrgGroupId
    });
  };

  useEffect(() => {
    if (groupMembersState.isArrayLoaded === true) {
      setGridState(createMemberGridState(groupMembersState.arrayData || []));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createMemberGridState(arrayData) {
    const newGridData = [];

    for (const member of arrayData) {
      newGridData.push({
        personId: member.personId,
        firstName: member.firstName,
        lastName: member.lastName,
        eventName: member.eventName,
        personOrgGroupId: member.personOrgGroupId,
        groupAttendeeType: Array.isArray(member.personGroupTypeField)
          ? member.personGroupTypeField.find(x => x.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE)?.fieldValue
          : '',
        groupRoleType: Array.isArray(member.personGroupTypeField)
          ? member.personGroupTypeField.find(x => x.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ROLE_TYPE)?.fieldValue
          : '',
        potentialOrConfirmed: Array.isArray(member.personGroupTypeField)
          ? member.personGroupTypeField.find(x => x.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_POTENTIAL_CONFIRMED)?.fieldValue
          : ''
      });
    }

    return newGridData;
  }

  return {
    isLoading: groupMembersState.isArrayLoading,
    isSaving: memberGroupsState.isSaving,
    gridData: gridState,
    modalState,
    onInviteAlternateClicked,
    onAddMemberToGroupClicked,
    onEditGroupMemberClicked,
    onDeleteGroupMemberClicked,
    onDeleteModalConfirmClicked,
    onDeleteModalCancelClicked
  };
};

export default useGroupCompetitorListMembers;