import { Fragment } from 'react';

import useNTGroupMemberIdForm from './UseNTGroupMemberIdForm';

import PopUpModal from '../../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';
import SearchMemberPopup from '../../../../../../common/components/searches/searchMember/SearchMemberPopup';

import { LOADING_MSG } from '../../../../../../common/utils/Constants';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const NTGroupMemberIdForm = ({ primaryButtonText = 'Add Member', onSubmitFormCallback }) => {
  const {
    isLoading,
    formState,
    errorState,
    handleSubmit,
    setFormData,
    onFormValueChanged
  } = useNTGroupMemberIdForm(onSubmitFormCallback);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberPopup
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged} />
          </div>
        </div>
        {errorState.apiError &&
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <p className={global.ErrorMessage}>{errorState.apiError}</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">{primaryButtonText}</PrimaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default NTGroupMemberIdForm;