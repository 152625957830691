import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import NavLinks from '../NavLinks';

const useGroupSearch = () => {
  const navigate = useNavigate();
  const { resetSelectOrgUnitState } = useSelectOrgUnitData();

  const onAddGroupClicked = (event) => {
    event?.preventDefault();

    navigate(NavLinks.GROUP_ADD);
  };

  const onEditGroupClicked = (event, group) => {
    event?.preventDefault();

    const orgGroupId = group?.orgGroupId;

    if (orgGroupId > 0) {
      navigate(NavLinks.GROUP_INFO, { state: { orgGroupId } });
    }
  };

  useEffect(() => {
    resetSelectOrgUnitState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onAddGroupClicked,
    onEditGroupClicked
  };
};

export default useGroupSearch;