import { Fragment } from "react";

import useSAAGroupsContextView from "./UseSAAGroupsContextView";

import SAAGroupsLoadingModal from "./components/dialogs/loading/SAAGroupsLoadingModal";

import Headings from "../../../common/components/headings/Headings";
import PageNotFound from "../../../common/components/PageNotFound";
import LeftArrowIcon from "../../../common/components/icons/LeftArrowIcon";
import LargeContextBasedNav from "../../../common/components/contextBasedNav/LargeContextBasedNav";
import MobileContextBasedNav from "../../../common/components/contextBasedNav/MobileContextBasedNav";

import global from '../../../common/components/GlobalStyle.module.css';

const SAAGroupsContextView = ({ children }) => {
  const {
    isError,
    isLoading,
    groupInfoObj,
    homeRoute,
    taxonomyName,
    onHomeClicked,
    onBackToClicked
  } = useSAAGroupsContextView();

  if (isLoading) {
    return <SAAGroupsLoadingModal isLoading={true} />;
  }

  if (isError) {
    return (
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackToClicked}>
              <LeftArrowIcon /> Back To Landing Page
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onHomeClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              Scholastic All America Groups
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <p className={global.HeaderText}><strong>Group Name:</strong>&nbsp;{groupInfoObj.groupName || ''}</p>
          <p className={global.HeaderText}><strong>Group Code:</strong>&nbsp;{groupInfoObj.groupCode || ''}</p>
          <p className={global.HeaderText}><strong>Group Type:</strong>&nbsp;{groupInfoObj.groupType || ''}</p>
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName={taxonomyName} renderPillsOnly={true} />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName={taxonomyName} homeRoute={homeRoute} renderPillsOnly={true} />
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default SAAGroupsContextView;