import useSAAGroupReports from './UseSAAGroupReports';

import ReportList from '../../../../common/components/embeddedReports/ReportList';

const SAAGroupReports = () => {
  const { reportParameters, contextId, routeName } = useSAAGroupReports();

  return (
    <ReportList
      listTitle="Reports"
      routeName={routeName}
      contextId={contextId}
      reportParameters={reportParameters} />
  );
};

export default SAAGroupReports;