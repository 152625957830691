import { createContext, useState } from 'react';

import { DEFAULT_ID } from '../../../common/utils/Constants';

export const SAAGroupFiltersContext = createContext();

const SAAGroupFiltersContextProvider = ({ children }) => {
  const stateHook = useState({
    personOrgGroupQualificationStatus: DEFAULT_ID,
    competitionGenderTypeCode: DEFAULT_ID,
    zoneOrgUnitCode: DEFAULT_ID
  });

  return (
    <SAAGroupFiltersContext.Provider value={stateHook}>
      {children}
    </SAAGroupFiltersContext.Provider>
  );
};

export default SAAGroupFiltersContextProvider;