import { Fragment } from 'react';

import HideIcon from '../../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../../common/components/icons/ShowIcon';
import Checkbox from '../../../../../../common/components/checkboxes/Checkbox';

import { formatDate } from '../../../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../../../common/utils/TimesUtils';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ event }) => (
  <tr>
    <td>{event.eventName || ''}</td>
    <td>{event.swimTime ? formatTimeForDisplay(event.swimTime) : ''}</td>
    <td>{event.swimDate ? formatDate(event.swimDate) : ''}</td>
    <td>{event.meetName || ''}</td>
  </tr>
);

const DetailTable = ({ events }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Event</th>
        <th>Swim Time</th>
        <th>Swim Date</th>
        <th>Meet Name</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(events) && events.length > 0
        ? events.map((event, i) => <DetailTableRow event={event} key={i} />)
        : <tr><td colSpan='4'>No Events</td></tr>
      }
    </tbody>
  </table>
);

const GridRow = ({ groupAthlete, expandedId, onEditClicked, onUpdateIsSelected, onExpandClicked }) => (
  <Fragment>
    <tr>
      <td colSpan='2'><a onClick={(e) => onEditClicked(e, groupAthlete)}>{groupAthlete.groupAthleteName || ''}</a></td>
      <td>
        <Checkbox
          name={`isSelected${groupAthlete.personNationalTeamGroupId}`}
          checked={groupAthlete.isSelected}
          onChange={() => { onUpdateIsSelected(groupAthlete.personNationalTeamGroupId) }} />
      </td>
      <td className='pull-right'>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpandClicked(e, groupAthlete.personNationalTeamGroupId)}>
          {expandedId === groupAthlete.personNationalTeamGroupId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {expandedId === groupAthlete.personNationalTeamGroupId &&
      <tr className={global.Expanded}>
        <td colSpan="4">
          <DetailTable events={groupAthlete.events} />
        </td>
      </tr>
    }
  </Fragment>
);

const NTGroupInviteAthletesFormGridLarge = ({ formGridState, expandedId, onEditClicked, onUpdateIsSelected, onExpandClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th colSpan='2'>Name</th>
        <th>Selected?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(formGridState) && formGridState.length > 0
        ? formGridState.map((groupAthlete, i) =>
          <GridRow key={i} groupAthlete={groupAthlete} expandedId={expandedId} onEditClicked={onEditClicked} onUpdateIsSelected={onUpdateIsSelected} onExpandClicked={onExpandClicked} />)
        : <tr><td colSpan="4">No Group Athletes</td></tr>
      }
    </tbody>
  </table>
);

export default NTGroupInviteAthletesFormGridLarge;