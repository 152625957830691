import { useState } from 'react';

import validate from './SearchMeetPopupValidation';

import useMeetLookupData from '../../../state/meetLookup/UseMemberLookupData';

import UseForm from '../../../utils/UseForm';

const INITIAL_STATE = {
  displayPopup: false
};

const INITIAL_FORM_STATE = {
  meetName: ''
};

const useSearchMeetPopup = (viewFormState, setViewFormData, meetIdKey, meetNameKey, hostOrganizationId, meetClassificationId) => {
  const { meetLookupState, getMeetLookupByName } = useMeetLookupData();
  const { formState, errorState, updateFormState, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onDisplayPopupClicked = () => {
    updateFormState('meetName', viewFormState[meetNameKey] || '');

    setState({ ...state, displayPopup: true });
  };

  const onHidePopupClicked = () => {
    setState({ ...state, displayPopup: false });
  };

  const onSelectMeet = (e, meetObj) => {
    e?.preventDefault();

    setViewFormData({ ...viewFormState, [meetIdKey]: meetObj.meetId, [meetNameKey]: meetObj.meetName }, true);
    setState({ ...state, displayPopup: false });
  };

  function onSubmitFormCallback(formState) {
    getMeetLookupByName(formState.meetName, hostOrganizationId, meetClassificationId);
  }

  return {
    state,
    meetLookupState,
    popupFormState: formState,
    popupErrorState: errorState,
    updatePopupFormState: updateFormState,
    handleSubmit,
    onDisplayPopupClicked,
    onHidePopupClicked,
    onSelectMeet
  };
};

export default useSearchMeetPopup;