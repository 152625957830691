import { POTENTIAL_CONFIRMED_OPTIONS } from "../../../utils/NTGroupConstants";

const useNTGroupConfirmedDropdown = () => {

  return {
    nationalTeamGroupConfirmedState: {
      options: POTENTIAL_CONFIRMED_OPTIONS,
      areOptionsLoaded: true,
      message: ''
    }
  };
};

export default useNTGroupConfirmedDropdown;