import EditIcon from '../../../../../../common/components/icons/EditIcon';
import SGColumnHeader from '../../../../../../common/components/grids/SGColumnHeader';

import SGConstants from '../../../../../../common/utils/SGConstants';
import useSortableGrid from '../../../../../../common/utils/UseSortableGrid';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ groupAthlete, onEditClicked }) => (
  <tr>
    <td>{groupAthlete.groupMemberName}</td>
    <td>{groupAthlete.memberId || ''}</td>
    <td className='pull-right'>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={(e) => onEditClicked(e, groupAthlete)}>
        <EditIcon toolTipText='Open Approval Menu' />
      </button>
    </td>
  </tr>
);

const NTGroupAthleteApprovalsGridLarge = ({ gridData, isLoading, onEditClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Member Id'} columnField={'memberId'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="3">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((groupAthlete, i) => <GridRow key={i} groupAthlete={groupAthlete} onEditClicked={onEditClicked} />)
            : <tr><td colSpan="3">No Pending Approvals</td></tr>
        }
      </tbody>
    </table>
  );
}

export default NTGroupAthleteApprovalsGridLarge;