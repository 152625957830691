import { API_NAMES, initApi } from "../../utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../utils/HttpHelper";

const getMeetLookupByNameData = (meetName, hostOrganizationId, meetClassificationId, state, setState) => {
  if (meetName && hostOrganizationId) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const filterObject = {
      orgUnitId: hostOrganizationId,
      meetName: meetName,
      meetClassificationId: meetClassificationId || undefined
    };
    const filterObjectForUrl = encodeURIComponent(JSON.stringify(filterObject));
    filterObjectForUrl.replace('&', '%26');
    const url = `/Meet/search?filters=${filterObjectForUrl}&sortBy=${'MeetName'}`;
    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetLookupData = {
  INITIAL_STATE,
  getMeetLookupByNameData
};

export default MeetLookupData;