export const localValidate = (formState) => {
  let errors = {};

  //Meet Name
  if (formState.meetName.trim() === '') {
    errors.meetName = 'Meet Name is required';
  } else if ((formState.meetName.trim()).length > 200) {
    errors.meetName = 'Meet Name cannot exceed 200 characters';
  }

  return errors;
};

const SearchMeetsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SearchMeetsValidation;