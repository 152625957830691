import { Fragment } from "react";

import useSAAGroupMembersGrid from "./UseSAAGroupMembersGrid";

import SAAGroupMembersGridLarge from "./SAAGroupMembersGridLarge";
import SAAGroupMembersGridSmall from "./SAAGroupMembersGridSmall";
import SAAGroupMembersFilter from "../../forms/saaGroupMembersFilter/SAAGroupMembersFilter";

import Spinner from "../../../../common/components/spinners/Spinner";

const SAAGroupMembersGrid = ({ gridData, isLoading, onEditClicked, onDeleteClicked }) => {
  const {
    genderOptions,
    zoneOptions,
    statusOptions,
    previousSearchFilters,
    gridState,
    onSubmitFilterForm
  } = useSAAGroupMembersGrid(gridData);

  if (isLoading === true) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <SAAGroupMembersFilter
        previousSearchFilters={previousSearchFilters}
        genderOptions={genderOptions}
        statusOptions={statusOptions}
        zoneOptions={zoneOptions}
        onSubmitFormCallback={onSubmitFilterForm} />
      <div className="row">
        <div className="col-xs-12">
          <p>{gridState.length !== gridData.length ? `Showing ${gridState.length} of ${gridData.length}` : `${gridState.length}`} Members</p>
        </div>
      </div>
      <SAAGroupMembersGridLarge data={gridState} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
      <SAAGroupMembersGridSmall data={gridState} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
    </Fragment>
  );
}

export default SAAGroupMembersGrid;