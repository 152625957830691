import useNTGroupData from "../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupMembersData from "../../../state/nationalTeamGroup/nationalTeamGroupMembers/UseNTGroupMembersData";

import useMemberPersistentStorage from "../../../../common/state/memberPersistentStorage/UseMemberPersistentStorage";
import useGlobalRoutesData from "../../../../common/state/security/UseGlobalRoutesData";

import { UI_PROJECT_NAMES } from "../../../../common/utils/Constants";
import formatCrossUiRoute from "../../../../common/utils/FormatCrossUiRoute";

const useNTGroupAthletesBulkInvite = (updateAthletePillStep) => {
  const { onNTGroupsError } = useNTGroupData();
  const { setPersistentStorage } = useMemberPersistentStorage();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { groupMembersState, putNTGroupMembers } = useNTGroupMembersData();

  const onEditGroupAthleteClicked = (event, groupAthleteObj) => {
    try {
      event?.preventDefault();
      setPersistentStorage(
        groupAthleteObj.personId,
        UI_PROJECT_NAMES.MEMBER,
        '/',
        'Back to Member Search'
      );

      const routeObj = getGlobalRoute('MEMBER_NT_ADVANCED_INFO');
      const crossUiRoute = formatCrossUiRoute(routeObj?.uIProjectName, routeObj?.route, true);
      window.open(crossUiRoute.route, '_blank');
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onSubmitAthletesFormGrid = (formGridState) => {
    try {
      const groupMembersArray = [...formGridState];

      const putNTGroupMembersPromise = putNTGroupMembers(groupMembersState.nationalTeamGroupId, groupMembersArray, true);

      if (putNTGroupMembersPromise ?? false) {
        putNTGroupMembersPromise.then((newState) => {
          if (newState ?? false) {
            updateAthletePillStep(newState.objData);
          }
        }).catch((e) => {
          onNTGroupsError(e);
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  return {
    isSaving: groupMembersState.isObjLoading,
    gridData: groupMembersState.objData?.nationalTeamGroupMember || [],
    onSubmitAthletesFormGrid,
    onEditGroupAthleteClicked
  };
};

export default useNTGroupAthletesBulkInvite;