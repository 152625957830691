import { Fragment } from "react";

import useNTGroupAthletesInitiate from "./UseNTGroupAthletesInitiate";

import NTGroupsLoadingModal from "../components/dialogs/loading/NTGroupsLoadingModal";

import Headings from "../../../../common/components/headings/Headings";
import ActionIntraPageButton from "../../../../common/components/buttons/ActionIntraPageButton";

const NTGroupAthletesInitiate = ({ updateAthletePillStep }) => {
  const {
    isLoading,
    onInitiateClicked,
  } = useNTGroupAthletesInitiate(updateAthletePillStep);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Athletes</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={onInitiateClicked}>Initiate</ActionIntraPageButton>
        </div>
      </div>
      <NTGroupsLoadingModal isLoading={isLoading} />
    </Fragment>
  );
};

export default NTGroupAthletesInitiate;