import { Fragment } from "react";

import useNTGroupAthleteApprovalModal from "./UseNTGroupAthleteApprovalModal";

import PopUpModal from "../../../../../../common/components/dialogs/PopUpModal";
import { SAVING_MSG } from "../../../../../../common/utils/Constants";
import NTGroupContactApprovalFormGrid from "../../grids/contactApprovalForm/NTGroupContactApprovalFormGrid";

const NTGroupAthleteApprovalModal = ({ memberObj, onCloseModal }) => {
  const {
    isSaving,
    isLoading,
    gridData,
    onSubmitContactInfoFormGrid
  } = useNTGroupAthleteApprovalModal(memberObj, onCloseModal);

  return (
    <Fragment>
      <PopUpModal
        widthPct={99}
        maxWidth={1080}
        title={'Approve Contact Info'}
        displayPopUp={isSaving !== true}
        onModalCanceled={onCloseModal}>
        <NTGroupContactApprovalFormGrid
          isLoading={isLoading}
          gridData={gridData}
          onSubmitFormCallback={onSubmitContactInfoFormGrid}
          onSecondaryButtonClicked={onCloseModal} />
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default NTGroupAthleteApprovalModal;