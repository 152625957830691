import { Fragment } from "react";

import useNTGroupInviteAthleteModal from "./UseNTGroupInviteAthleteModal";

import NTGroupAthleteEventsGrid from "../../grids/athleteEvents/NTGroupAthleteEventsGrid";
import NTGroupAthleteEventAddForm from "../../forms/athleteEventAdd/NTGroupAthleteEventAddForm";

import PopUpModal from "../../../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";

const NTGroupInviteAthleteModal = ({ memberObj, onCloseModal }) => {
  const {
    isSaving,
    state,
    onInviteAthleteClicked,
    onDeleteAthleteEventClicked,
    onSubmitAthleteEventAddForm
  } = useNTGroupInviteAthleteModal(memberObj, onCloseModal);

  return (
    <Fragment>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Invite Athlete'}
        displayPopUp={isSaving !== true}
        onModalCanceled={onCloseModal}>
        <NTGroupAthleteEventAddForm
          groupAthleteName={state.groupAthleteName}
          groupAthleteEvents={state.groupAthleteEvents}
          onSubmitFormCallback={onSubmitAthleteEventAddForm} />
        <NTGroupAthleteEventsGrid
          gridData={state.groupAthleteEvents}
          isLoading={false}
          onDeleteClicked={onDeleteAthleteEventClicked} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" isDisabled={state.canInvite !== true} onClick={onInviteAthleteClicked}>Invite Athlete</PrimaryButton>&nbsp;
            <SecondaryButton type='button' onClick={onCloseModal}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={'Inviting Athlete...'}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default NTGroupInviteAthleteModal;