import { Fragment } from 'react';

import useNTGroupContactApprovalFormGrid from './UseNTGroupContactApprovalFormGrid';

import Spinner from '../../../../../../common/components/spinners/Spinner';
import Checkbox from '../../../../../../common/components/checkboxes/Checkbox';
import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';

import global from '../../../../../../common/components/GlobalStyle.module.css';
import style from './NTGroupContactApprovalFormGrid.module.css';

const DetailTableRow = ({ contactId, contactField, onUpdateIsApproved }) => (
  <tr>
    <td>{contactField.fieldLabel || ''}</td>
    <td className={[style.ValueCell, contactField.isApproved === true ? style.DiscardedValue : style.SelectedValue].join(' ')}>{contactField.nationalTeamDisplayValue || '...'}</td>
    {contactField.isUpdated === true
      ? <td className={[style.ValueCell, contactField.isApproved === true ? style.SelectedValue : ''].join(' ')}>{contactField.registrationDisplayValue || '...'}</td>
      : <td className={style.ValueCell}>NO CHANGE</td>
    }
    <td>
      {contactField.isUpdated === true &&
        <Checkbox
          name={`isSelected${contactField.id}`}
          checked={contactField.isApproved}
          onChange={() => { onUpdateIsApproved(contactField, contactId) }}
          disabled={contactField.isReadonly === true} />
      }
    </td>
  </tr>
);

const DetailTable = ({ contactId, contactFields, onUpdateIsApproved }) => (
  <table className={[global.DetailTable, style.ApprovalTable].join(' ')}>
    <thead>
      <tr>
        <th>Field</th>
        <th>Value Before Registration</th>
        <th>Value After Registration</th>
        <th>Approve?</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(contactFields) && contactFields.length > 0
        ? contactFields.map((contactField, i) => <DetailTableRow key={i} contactId={contactId} contactField={contactField} onUpdateIsApproved={onUpdateIsApproved} />)
        : <tr><td colSpan='4'>No Contact Fields</td></tr>
      }
    </tbody>
  </table>
);

const GridRow = ({ contact, onUpdateIsApproved }) => (
  <Fragment>
    <tr>
      <td colSpan='4'>{contact.contactTypeLabel || ''}</td>
    </tr>
    <tr className={global.Expanded}>
      <td colSpan="4">
        <DetailTable contactId={contact.id} contactFields={contact.contactFields} onUpdateIsApproved={onUpdateIsApproved} />
      </td>
    </tr>
  </Fragment>
);

const NTGroupContactApprovalFormGrid = ({ gridData, isLoading, secondaryButtonLabel = 'Cancel',
  onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formGridState,
    onUpdateIsApproved,
    onConfirmApprovalClicked
  } = useNTGroupContactApprovalFormGrid(gridData, onSubmitFormCallback);


  if (isLoading === true) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
        <thead>
          <tr>
            <th colSpan="4">Contact</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(formGridState) && formGridState.length > 0
            ? formGridState.map((contact, i) =>
              <GridRow key={i} contact={contact} onUpdateIsApproved={onUpdateIsApproved} />)
            : <tr><td colSpan="4">No Contacts</td></tr>
          }
        </tbody>
      </table>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="button" onClick={onConfirmApprovalClicked}>Confirm Approvals</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonLabel}</SecondaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default NTGroupContactApprovalFormGrid;