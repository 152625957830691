import { useEffect } from 'react';

import validate from './MyGroupsSearchFormValidation';

import useGroupCategoryData from '../../../../common/state/groupCategory/UseGroupCategoryData';

import useForm from "../../../../common/utils/UseForm";
import HierarchicalDataUtils from '../../../../common/utils/HierarchicalDataUtils';

const INITIAL_FORM_STATE = {
  groupCategories: [],
  groupName: '',
  groupCode: ''
};

const useMyGroupsSearchForm = (orgUnitId, previousSearchFilters, onSubmitFormCallback) => {
  const { groupCategoryState } = useGroupCategoryData();
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setFormState, setErrors, setIsDirty
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const onClearFormClicked = (event) => {
    event?.preventDefault();

    setFormState({ ...INITIAL_FORM_STATE });
    setErrors({});
  };

  useEffect(() => {
    if (groupCategoryState.isArrayLoaded === true && groupCategoryState.isArrayLoading === false
      && Object.keys(previousSearchFilters).length > 0) {
      const oldSearchValues = JSON.parse(JSON.stringify(previousSearchFilters));
      const newFormState = {
        groupCategories: HierarchicalDataUtils.GetNameIdPairs(groupCategoryState.treeData, oldSearchValues.groupCategoryId) || [],
        groupName: oldSearchValues.groupName || '',
        groupCode: oldSearchValues.groupCode || ''
      };

      const validatePromise = validate(newFormState);

      validatePromise.then((errors) => {
        if (Object.keys(errors).length === 0) {
          setFormState({ ...newFormState });
          setIsDirty(true);
          onSubmitFormCallback({ ...newFormState, orgUnitId });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCategoryState]);

  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onClearFormClicked
  };
};

export default useMyGroupsSearchForm;