/**
 * @param {object} baseGroupObjData 
 * @param {object} formState orgUnitId, groupTypeId, groupCategories, groupName, groupCode, groupDescription
 * @returns {object} group object
 */
const createGroupObjFromGroupInfoForm = (baseGroupObjData, formState) => {
  return {
    ...baseGroupObjData,
    orgUnitId: formState.orgUnitId,
    groupTypeId: formState.groupTypeId,
    groupCategoryId: formState.groupCategories[0].id,
    groupName: formState.groupName.trim(),
    groupCode: formState.groupCode.trim(),
    groupDescription: formState.groupDescription.trim(),
    groupType: undefined,
    groupCategory: undefined
  };
};

/**
 * @param {object} baseGroupObjData 
 * @param {object} formState hasRegistration, requiresApproval, registrationCloseDate, registrationOpenDate, eligibleRoles
 * @returns {object} group object
 */
const createGroupObjFromGroupRegistrationForm = (baseGroupObjData, formState, orgGroupId = undefined) => {
  const orgGroupEligibility = [];

  if (Array.isArray(formState.eligibleRoles) && formState.eligibleRoles.length > 0) {
    for (const role of formState.eligibleRoles) {
      orgGroupEligibility.push({ orgRoleId: role.id, orgGroupId });
    }
  }

  return {
    ...baseGroupObjData,
    hasRegistration: formState.hasRegistration,
    requiresApproval: formState.hasRequiresApproval === true ? formState.requiresApproval : null,
    hasOpenRegistration: formState.hasRegistration === true ? formState.hasOpenRegistration : null,
    hasOrgRoleRestriction: formState.hasRegistration === true && formState.hasOpenRegistration === true
      ? formState.hasOrgRoleRestriction
      : null,
    registrationCloseDate: formState.hasRegistration === true ? formState.registrationCloseDate : null,
    registrationOpenDate: formState.hasRegistration === true ? formState.registrationOpenDate : null,
    orgGroupEligibility: formState.hasRegistration === true && formState.hasOpenRegistration === true && formState.hasOrgRoleRestriction === true
      ? orgGroupEligibility
      : []
  };
};

/**
 * @param {Array} orgGroupFields objects in format {fieldName, fieldValue}
 * @param {Array} orgGroupProperties 
 * @param {Number} orgGroupId optional
 * @returns {Array} org group field values
 */
const createOrgGroupFieldValues = (orgGroupFields, orgGroupProperties, orgGroupId = undefined) => {
  const orgGroupFieldValue = [];

  for (const groupField of orgGroupFields) {
    const groupTypeField = orgGroupProperties.find(x => x.fieldName === groupField.fieldName);

    if (groupTypeField) {
      orgGroupFieldValue.push({
        groupTypeField,
        groupTypeFieldId: groupTypeField.groupTypeFieldId,
        fieldValue: groupField.fieldValue,
        orgGroupId
      });
    }
  }

  return orgGroupFieldValue;
};

const getGroupMemberIds = (potentialGroupMembers, existingGroupMembers) => {
  const memberIds = [];

  for (const member of potentialGroupMembers) {
    const groupCoach = existingGroupMembers.find(x => x.personId === member.id);

    if (groupCoach) {
      memberIds.push(groupCoach.personId);
    }
  }

  return memberIds;
};

const getUpdatedGroupMembers = (selectedGroupMembers, potentialGroupMembers, existingGroupMembers) => {
  const newGroupMembers = [];

  for (const member of potentialGroupMembers) {
    if (selectedGroupMembers.find(x => x.id === member.id)) { // member is selected
      if (!existingGroupMembers.find(x => x.personId === member.id)) { // member is not already in group
        newGroupMembers.push({ // add member to group
          personId: member.id,
          removeFromGroup: false
        });
      }
    } else if (existingGroupMembers.find(x => x.personId === member.id)) { // member is not selected but was in group
      newGroupMembers.push({ // remove member from group
        personId: member.id,
        removeFromGroup: true
      });
    }
  }

  return newGroupMembers;
};

export {
  createGroupObjFromGroupInfoForm, createGroupObjFromGroupRegistrationForm, createOrgGroupFieldValues,
  getGroupMemberIds, getUpdatedGroupMembers
};