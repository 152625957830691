import { useEffect } from 'react';

import validate from './SearchGroupFormValidation';

import useOrgUnitData from '../../../../state/orgUnit/UseOrgUnitData';
import useOrgGroupCategoriesData from '../../../../state/orgGroupCategories/UseOrgGroupCategoriesData';

import useForm from "../../../../utils/UseForm";
import HierarchicalDataUtils from '../../../../utils/HierarchicalDataUtils';

const INITIAL_FORM_STATE = {
  orgUnit: [],
  groupCategories: [],
  groupName: '',
  groupCode: ''
};

const useSearchGroupForm = (previousSearchFilters, onSubmitFormCallback) => {
  const { orgUnitState } = useOrgUnitData();
  const { orgGroupCategoriesState } = useOrgGroupCategoriesData();
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setFormState, setErrors, setIsDirty
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const onClearFormClicked = (event) => {
    event?.preventDefault();

    setFormState({ ...INITIAL_FORM_STATE });
    setErrors({});
  };

  useEffect(() => {
    if (orgUnitState.isArrayLoaded === true && orgUnitState.isArrayLoading === false &&
      orgGroupCategoriesState.isArrayLoaded === true && orgGroupCategoriesState.isArrayLoading === false
      && Object.keys(previousSearchFilters).length > 0) {
      const oldSearchValues = JSON.parse(JSON.stringify(previousSearchFilters));
      const newFormState = {
        orgUnit: HierarchicalDataUtils.GetNameIdPairs(orgUnitState.treeData, [oldSearchValues.orgUnitId]) || [],
        groupCategories: HierarchicalDataUtils.GetNameIdPairs(orgGroupCategoriesState.treeData, oldSearchValues.groupCategoryId) || [],
        groupName: oldSearchValues.groupName || '',
        groupCode: oldSearchValues.groupCode || ''
      };

      const validatePromise = validate(newFormState);

      validatePromise.then((errors) => {
        if (Object.keys(errors).length === 0) {
          setFormState({ ...newFormState });
          setIsDirty(true);
          onSubmitFormCallback(newFormState);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState.treeData, orgGroupCategoriesState.treeData]);

  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onClearFormClicked
  };
};

export default useSearchGroupForm;