import { Fragment } from 'react';

import NTGroupAthleteApprovalsGridLarge from './NTGroupAthleteApprovalsGridLarge';
import NTGroupAthleteApprovalsGridSmall from './NTGroupAthleteApprovalsGridSmall';

const NTGroupAthleteApprovalsGrid = ({ gridData, isLoading, onEditClicked }) => (
  <Fragment>
    <NTGroupAthleteApprovalsGridLarge gridData={gridData} isLoading={isLoading} onEditClicked={onEditClicked} />
    <NTGroupAthleteApprovalsGridSmall gridData={gridData} isLoading={isLoading} onEditClicked={onEditClicked} />
  </Fragment>
);

export default NTGroupAthleteApprovalsGrid;