import { isValidAlphanumericAndDollarSign } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  // Group Code
  if (formState.groupCode) {
    if (!isValidAlphanumericAndDollarSign(formState.groupCode)) {
      errors.groupCode = 'Group Code can only contain numbers, letters, and dollar signs';
    }
  }

  return errors;
};

const MyGroupsSearchFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default MyGroupsSearchFormValidation;