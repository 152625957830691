import { useContext } from 'react';

import { GroupStateContext } from './GroupContextProvider';
import { GroupMembersStateContext } from './GroupMembersContextProvider';

import GroupData from './GroupData';

const useGroupData = () => {
  const [groupState, setGroupState] = useContext(GroupStateContext);
  const [groupMembersState, setGroupMembersState] = useContext(GroupMembersStateContext);

  const postGroup = (orgGroupObj) => {
    return GroupData.postGroupData(orgGroupObj, groupState, setGroupState);
  };

  const getGroup = (orgGroupId) => {
    return GroupData.getGroupData(orgGroupId, groupState, setGroupState);
  };

  const getGroupMembers = (orgGroupId) => {
    return GroupData.getGroupMembersData(orgGroupId, groupMembersState, setGroupMembersState);
  };

  const putGroup = (orgGroupId, orgGroupObj) => {
    return GroupData.putGroupData(orgGroupId, orgGroupObj, groupState, setGroupState);
  };

  const putGroupMembers = (orgGroupId, groupMembersArray, groupAttendeeTypeName) => {
    return GroupData.putGroupMembersData(orgGroupId, groupMembersArray, groupAttendeeTypeName, groupMembersState, setGroupMembersState);
  };

  const deleteGroup = (orgGroupId) => {
    return GroupData.deleteGroupData(orgGroupId, groupState, setGroupState);
  };

  return {
    groupState,
    groupMembersState,
    postGroup,
    getGroup,
    getGroupMembers,
    putGroup,
    putGroupMembers,
    deleteGroup
  };
};

export default useGroupData;