import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const getGroupAlternateMembersData = (orgGroupId, state, setState) => {
  if (orgGroupId > 0) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    const orgGroupIdForUrl = orgGroupId ? encodeURIComponent(orgGroupId) : 'NaN';
    const url = `/OrgGroup/${orgGroupIdForUrl}/Alternate`;
    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const getGroupAlternateMembersIMXData = (orgGroupId, state, setState) => {
  if (orgGroupId) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    const orgGroupIdForUrl = orgGroupId ? encodeURIComponent(orgGroupId) : 'NaN';
    const url = `/OrgGroup/${orgGroupIdForUrl}/ImxAlternate`;
    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putGroupAlternateMembersData = (orgGroupId, alternateMembersArray, state, setState) => {
  if (orgGroupId > 0 && alternateMembersArray) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false
    };

    const api = initApi(API_NAMES.COMMON, newState, setState);

    const orgGroupIdForUrl = orgGroupId ? encodeURIComponent(orgGroupId) : 'NaN';
    const url = `/OrgGroup/${orgGroupIdForUrl}/Alternate`;
    return api?.executeObject ? api.executeObject(url, 'PUT', alternateMembersArray) : null;
  }
};

const putGroupAlternateMembersIMXData = (orgGroupId, alternateMembersArray, state, setState) => {
  if (orgGroupId > 0 && alternateMembersArray) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false
    };

    const api = initApi(API_NAMES.COMMON, newState, setState);


    const orgGroupIdForUrl = orgGroupId ? encodeURIComponent(orgGroupId) : 'NaN';
    const url = `/OrgGroup/${orgGroupIdForUrl}/ImxAlternate`;
    return api?.executeObject ? api.executeObject(url, 'PUT', alternateMembersArray) : null;
  }
};

const INITIAL_GROUP_ALTERNATE_MEMBERS_STATE = {
  ...BASIC_INITIAL_STATE,
};

const INITIAL_GROUP_ALTERNATE_MEMBERS_IMX_STATE = {
  ...BASIC_INITIAL_STATE,
};

const GroupAlternateMembersData = {
  INITIAL_GROUP_ALTERNATE_MEMBERS_STATE,
  INITIAL_GROUP_ALTERNATE_MEMBERS_IMX_STATE,
  getGroupAlternateMembersData,
  getGroupAlternateMembersIMXData,
  putGroupAlternateMembersData,
  putGroupAlternateMembersIMXData
};

export default GroupAlternateMembersData;