import EditIcon from "../../../../common/components/icons/EditIcon";
import DeleteIcon from "../../../../common/components/icons/DeleteIcon";
import SGColumnHeader from "../../../../common/components/grids/SGColumnHeader";

import useSortableGrid from "../../../../common/utils/UseSortableGrid";
import SGConstants from "../../../../common/utils/SGConstants";

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ member, onEditClicked, onDeleteClicked }) => (
  <tr>
    <td>{member.firstName || ''} {member.lastName || ''}</td>
    {/* <td>{member.eventName || ''}</td> */}
    <td>{member.zoneOrgUnitName || ''}</td>
    <td>{member.competitionGenderTypeName || ''}</td>
    <td>{member.personOrgGroupQualificationStatus || ''}</td>
    <td>
      {onEditClicked &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onEditClicked(e, member)}>
          <EditIcon />
        </button>
      }
      {onDeleteClicked &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onDeleteClicked(e, member)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const SAAGroupMembersGridLarge = ({ data, isLoading, onEditClicked, onDeleteClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          {/* <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Event'} columnField={'eventName'}
            sortType={SGConstants.SORT_TYPE_LENGTH} onColumnClicked={onColumnClicked} /> */}
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Zone'} columnField={'zoneOrgUnitCode'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Competition Category'} columnField={'competitionGenderTypeCode'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Status'} columnField={'personOrgGroupQualificationStatus'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="5">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((member, i) => <LargeGridRow key={i} member={member} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />)
            : <tr><td colSpan="5">No Group Members</td></tr>
        }
      </tbody>
    </table>
  );
};

export default SAAGroupMembersGridLarge;