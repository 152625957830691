import { useState } from "react";

import useNTGroupData from "../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupMembersData from "../../../state/nationalTeamGroup/nationalTeamGroupMembers/UseNTGroupMembersData";

import useMemberPersistentStorage from "../../../../common/state/memberPersistentStorage/UseMemberPersistentStorage";
import useGlobalRoutesData from "../../../../common/state/security/UseGlobalRoutesData";

import formatCrossUiRoute from "../../../../common/utils/FormatCrossUiRoute";
import { UI_PROJECT_NAMES } from "../../../../common/utils/Constants";

const INITIAL_STATE = {
  deleteGroupMemberObj: {},
  addGroupMemberObj: {},
  dupGroupMemberObj: {},
  displayDeletePopup: false,
  displayAddPopup: false,
  displayDupPopup: false
};

const useNTGroupMembers = () => {
  const { onNTGroupsError } = useNTGroupData();
  const { setPersistentStorage } = useMemberPersistentStorage();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { groupMembersState, getNTGroupMembers, putNTGroupMembers } = useNTGroupMembersData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCloseDeletePopup = (event, refreshGrid = false) => {
    try {
      event?.preventDefault();

      if (refreshGrid === true) {
        getNTGroupMembersHelper(groupMembersState.nationalTeamGroupId);
      }

      setState({
        ...state,
        displayDeletePopup: false,
        deleteGroupMemberObj: {}
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onCloseAddPopup = (event, refreshGrid = false) => {
    try {
      event?.preventDefault();

      if (refreshGrid === true) {
        getNTGroupMembersHelper(groupMembersState.nationalTeamGroupId);
      }

      setState({
        ...state,
        displayAddPopup: false,
        addGroupMemberObj: {}
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onCloseDupPopup = (event) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDupPopup: false,
        dupGroupMemberObj: {}
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onDeleteGroupMemberClicked = (event, groupMemberObj) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDeletePopup: true,
        deleteGroupMemberObj: groupMemberObj
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onEditGroupMemberClicked = (event, groupMemberObj) => {
    try {
      event?.preventDefault();
      setPersistentStorage(
        groupMemberObj.personId,
        UI_PROJECT_NAMES.MEMBER,
        '/',
        'Back to Member Search'
      );

      const routeObj = getGlobalRoute('MEMBER_NT_ADVANCED_INFO');
      const crossUiRoute = formatCrossUiRoute(routeObj?.uIProjectName, routeObj?.route, true);
      window.open(crossUiRoute.route, '_blank');
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onSubmitMemberIdForm = (formState) => {
    try {
      const dupGroupMemberObj = Array.isArray(groupMembersState.objData?.nationalTeamGroupMember) ? groupMembersState.objData.nationalTeamGroupMember.find((groupMember) => groupMember.personId === formState.matchedMember.personId) : null;

      if (dupGroupMemberObj ?? false) {
        setState({
          ...state,
          dupGroupMemberObj: {
            ...dupGroupMemberObj,
            groupMemberName: `${dupGroupMemberObj.preferredName || dupGroupMemberObj.firstName} ${dupGroupMemberObj.lastName}`
          },
          displayDupPopup: true
        });
      } else {
        setState({
          ...state,
          displayAddPopup: true,
          addGroupMemberObj: formState.matchedMember
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onSubmitMembersFormGrid = (formGridState) => {
    try {
      const groupMembersArray = [...formGridState];

      const putNTGroupMembersPromise = putNTGroupMembers(groupMembersState.nationalTeamGroupId, groupMembersArray);

      if (putNTGroupMembersPromise ?? false) {
        putNTGroupMembersPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  function getNTGroupMembersHelper(nationalTeamGroupId) {
    const getNTGroupMembersPromise = getNTGroupMembers(nationalTeamGroupId);

    if (getNTGroupMembersPromise ?? false) {
      getNTGroupMembersPromise.catch((e) => {
        onNTGroupsError(e);
      });
    }
  }

  return {
    isGridLoading: groupMembersState.isObjLoading,
    gridData: groupMembersState.objData?.nationalTeamGroupMember || [],
    state,
    onCloseDupPopup,
    onCloseAddPopup,
    onCloseDeletePopup,
    onSubmitMemberIdForm,
    onSubmitMembersFormGrid,
    onEditGroupMemberClicked,
    onDeleteGroupMemberClicked
  };
};

export default useNTGroupMembers;