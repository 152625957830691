import { useEffect, useState } from 'react';

import { DEFAULT_ID } from '../../../../common/utils/Constants';

const INITIAL_STATE = {
  personOrgGroupQualificationStatus: DEFAULT_ID,
  competitionGenderTypeCode: DEFAULT_ID,
  zoneOrgUnitCode: DEFAULT_ID
};

const useSAAGroupMembersFilter = (previousSearchFilters, onSubmitFormCallback) => {
  const [displayClearFilterButtonState, setDisplayClearFilterButtonState] = useState(false);
  const [state, setState] = useState(INITIAL_STATE);

  const onUpdateFilter = (key, value) => {
    const newState = { ...state, [key]: value };

    setState(newState);
    onSubmitFormCallback(newState);
  };

  const onClearFilterClicked = (e) => {
    e?.preventDefault();

    setState({ ...INITIAL_STATE });
    onSubmitFormCallback({ ...INITIAL_STATE });
  };

  useEffect(() => {
    if (state.competitionGenderTypeCode !== DEFAULT_ID || state.zoneOrgUnitCode !== DEFAULT_ID || state.personOrgGroupQualificationStatus !== DEFAULT_ID) {
      setDisplayClearFilterButtonState(true);
    } else {
      setDisplayClearFilterButtonState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (previousSearchFilters) {
      const newState = {
        ...INITIAL_STATE,
        competitionGenderTypeCode: previousSearchFilters.competitionGenderTypeCode === '' ? DEFAULT_ID : previousSearchFilters.competitionGenderTypeCode,
        zoneOrgUnitCode: previousSearchFilters.zoneOrgUnitCode === '' ? DEFAULT_ID : previousSearchFilters.zoneOrgUnitCode,
        personOrgGroupQualificationStatus: previousSearchFilters.personOrgGroupQualificationStatus === '' ? DEFAULT_ID : previousSearchFilters.personOrgGroupQualificationStatus
      };

      setState(newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    displayClearFilterButton: displayClearFilterButtonState,
    state,
    onUpdateFilter,
    onClearFilterClicked
  };
};

export default useSAAGroupMembersFilter;