import { Fragment } from 'react';

import useGroupInviteAlternateMembers from './UseGroupInviteAlternateMembers';

import InviteGroupMemberGrid from '../../components/grids/InviteGroupMemberGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import { LOADING_MSG, SAVING_MSG } from '../../../../../common/utils/Constants';

const GroupInviteAlternateMembers = () => {
  const {
    isLoading,
    isSaving,
    isGridLoading,
    gridData,
    selectedPersonsState,
    onInviteAthletesClicked,
    onBackClicked,
    onCheckboxClicked
  } = useGroupInviteAlternateMembers();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Alternate Athletes</Headings.H3>
        </div>
      </div>
      <div className='row'>
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <InviteGroupMemberGrid
            data={gridData}
            selectedPersons={selectedPersonsState}
            isLoading={isGridLoading}
            onCheckboxClicked={onCheckboxClicked} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="button" onClick={onInviteAthletesClicked}>Invite Selected Athletes</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default GroupInviteAlternateMembers;