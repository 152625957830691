import { useEffect } from 'react';

import { createOrgGroupFieldValues, getGroupMemberIds, getUpdatedGroupMembers } from '../../utils/GroupUtils';

import validate from '../../../../../common/components/forms/groups/TravelTripAttributesFormValidation';
import useStatesCombobox from '../../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';

import useOrgGroupPropertiesData from '../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';
import useGroupData from '../../../../../common/state/group/UseGroupData';
import useGroupCoachesData from '../../../../../common/state/groupCoaches/UseGroupCoachesData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../../common/utils/HierarchicalDataUtils';

const useGroupTravelTripAttributes = () => {
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { groupState, groupMembersState, getGroupMembers, putGroup, putGroupMembers } = useGroupData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { groupCoachesState } = useGroupCoachesData();
  const { getStateNameById } = useStatesCombobox();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormData, resetForm, onValueTextPairChanged
  } = useForm(getInitialFormState, submitFormCallback, validate);

  useEffect(() => {
    if (groupState.isObjLoaded === true
      && groupMembersState.isArrayLoaded === true && groupMembersState.orgGroupId === groupState.objData.orgGroupId
      && orgGroupPropertiesState.isArrayLoaded === true && orgGroupPropertiesState.groupTypeId === groupState.objData.groupTypeId
      && groupCoachesState.isArrayLoaded === true && groupCoachesState.orgUnitId === groupState.objData.orgUnitId) {
      const groupObj = groupState.objData;
      const groupMembersArray = groupMembersState.arrayData;
      const groupCoachIds = getGroupMemberIds(groupCoachesState.options, groupMembersArray);

      setFormData({
        ...formState,
        groupCoaches: HierarchicalDataUtils.GetNameIdPairs(groupCoachesState.options, groupCoachIds),
        startDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE)?.fieldValue,
        endDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE)?.fieldValue,
        city: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_CITY)?.fieldValue,
        stateCode: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_STATE)?.fieldValue,
        stateName: getStateNameById(groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_STATE)?.fieldValue),
        zip: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_ZIP)?.fieldValue,
        tripPurpose: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TRIP_PURPOSE)?.fieldValue
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupPropertiesState, groupState, groupCoachesState, groupMembersState]);

  useEffect(() => {
    if (groupState.isObjLoaded === true && groupState.objData.groupTypeId !== orgGroupPropertiesState.groupTypeId) {
      getOrgGroupProperties(groupState.objData.groupTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupState, orgGroupPropertiesState]);

  async function submitFormCallback(formState) {
    if (groupState.isObjLoading === false) {
      const newOrgGroupObj = JSON.parse(JSON.stringify(groupState.objData));
      const newGroupMembersArray = getUpdatedGroupMembers(formState.groupCoaches, groupCoachesState.options, groupMembersState.arrayData);

      newOrgGroupObj.orgGroupFieldValue = createOrgGroupFieldValues([
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE, fieldValue: formState.startDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE, fieldValue: formState.endDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_CITY, fieldValue: formState.city },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_STATE, fieldValue: formState.stateCode },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_ZIP, fieldValue: formState.zip },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TRIP_PURPOSE, fieldValue: formState.tripPurpose }
      ], orgGroupPropertiesState.arrayData, newOrgGroupObj.orgGroupId);

      const putGroupResult = await putGroup(newOrgGroupObj.orgGroupId, newOrgGroupObj);

      if (putGroupResult) {
        if (newGroupMembersArray.length > 0) {
          const putGroupMembersResult = await putGroupMembers(groupState.objData.orgGroupId, newGroupMembersArray, 'Coach');

          if (putGroupMembersResult) {
            getGroupMembers(groupState.objData.orgGroupId);
          }
        }
      }
    }
  }

  function getInitialFormState() {
    return {
      groupCoaches: [],
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING,
      city: '',
      stateCode: '',
      stateName: '',
      zip: '',
      tripPurpose: ''
    };
  }

  return {
    isSaving: groupState.isObjLoading || groupMembersState.isObjLoading,
    isLoading: orgGroupPropertiesState.isArrayLoading || groupMembersState.isArrayLoading,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onCancelClicked: resetForm,
    orgUnitId: groupState.objData.orgUnitId || ''
  };
};

export default useGroupTravelTripAttributes;