import NTGroupRoleTypeDropdown from '../../dropdowns/roleType/NTGroupRoleTypeDropdown';
import NTGroupConfirmedDropdown from '../../dropdowns/confirmed/NTGroupConfirmedDropdown';
import NTGroupAttendeeTypeDropdown from '../../dropdowns/attendeeType/NTGroupAttendeeTypeDropdown';

import DeleteIcon from '../../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ groupMember, onUpdateFormGridState, onEditClicked, onDeleteClicked }) => (
  <tr>
    <td><a onClick={(e) => onEditClicked(e, groupMember)}>{groupMember.groupMemberName || ''}</a></td>
    <td style={{ width: "25%" }}>
      <NTGroupAttendeeTypeDropdown
        name={`groupAttendeeTypeId${groupMember.personNationalTeamGroupId}`}
        value={groupMember.groupAttendeeTypeId}
        onChange={(newValue, newValueLabel, e) => { e?.target?.value && onUpdateFormGridState(groupMember.personNationalTeamGroupId, newValue, 'groupAttendeeTypeId', newValueLabel, 'attendeeType') }} />
    </td>
    <td style={{ width: "25%" }}>
      <NTGroupRoleTypeDropdown
        name={`groupRoleTypeId${groupMember.personNationalTeamGroupId}`}
        value={groupMember.groupRoleTypeId}
        onChange={(newValue, newValueLabel, e) => { e?.target?.value && onUpdateFormGridState(groupMember.personNationalTeamGroupId, newValue, 'groupRoleTypeId', newValueLabel, 'roleType') }} />
    </td>
    <td style={{ width: "25%" }}>
      <NTGroupConfirmedDropdown
        name={`isConfirmedTypeId${groupMember.personNationalTeamGroupId}`}
        value={groupMember.isConfirmedTypeId}
        onChange={(newValue, newValueLabel, e) => { e?.target?.value && onUpdateFormGridState(groupMember.personNationalTeamGroupId, newValue, 'isConfirmedTypeId', newValueLabel, 'isConfirmedType') }} />
    </td>
    <td>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={(e) => onDeleteClicked(e, groupMember)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const NTGroupMembersFormGridLarge = ({ formGridState, onUpdateFormGridState, onEditClicked, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Attendee Type</th>
        <th>Role</th>
        <th>Potential/Confirmed</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(formGridState) && formGridState.length > 0
        ? formGridState.map((groupMember, i) =>
          <GridRow key={i} groupMember={groupMember} onUpdateFormGridState={onUpdateFormGridState} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />)
        : <tr><td colSpan="5">No Group Members</td></tr>
      }
    </tbody>
  </table>
);

export default NTGroupMembersFormGridLarge;