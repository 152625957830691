import { useContext } from "react";

import SAAGroupMembersData from "./SAAGroupMembersData";
import { SAAGroupMembersContext } from "./SAAGroupMembersContextProvider";

const useSAAGroupMembersData = () => {
  const [groupMembersState, setGroupMembersState] = useContext(SAAGroupMembersContext);

  const getSAAGroupMembers = (orgGroupId) => {
    return SAAGroupMembersData.getSAAGroupMembersData(orgGroupId, groupMembersState, setGroupMembersState);
  };

  return {
    groupMembersState,
    getSAAGroupMembers
  };
};

export default useSAAGroupMembersData;