import { Fragment } from 'react';

import useSearchGroup from './UseSearchGroup';

import SearchGroupGrid from './grids/SearchGroupGrid';
import SearchGroupForm from './forms/SearchGroupForm';

import Headings from '../../headings/Headings';

import global from '../../GlobalStyle.module.css';

const SearchGroup = ({ pageTitle, onAddGroupClicked, onEditGroupClicked, onCancelClicked, groupCategoryLevel = 'HQ' }) => {
  const {
    isLoading,
    gridData,
    showFilters,
    previousSearchFilters,
    onToggleShowFilters,
    onSubmitSearchForm
  } = useSearchGroup();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{pageTitle}</Headings.H3>
        </div>
      </div>
      <div className={showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <SearchGroupForm
          groupCategoryLevel={groupCategoryLevel}
          previousSearchFilters={previousSearchFilters}
          onSubmitFormCallback={onSubmitSearchForm}
          onAddGroup={onAddGroupClicked}
          onCancelClicked={onCancelClicked} />
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <SearchGroupGrid
            gridData={gridData}
            isLoading={isLoading}
            showFilters={showFilters}
            onEditGroupClicked={onEditGroupClicked}
            onToggleShowFilters={onToggleShowFilters} />
        </div>
      </div>
    </Fragment>
  );
};

export default SearchGroup;