import useSearchGroupForm from "./UseSearchGroupForm"

import Input from "../../../inputs/Input"
import PrimaryButton from "../../../buttons/PrimaryButton"
import SecondaryButton from "../../../buttons/SecondaryButton"
import ActionIntraPageButton from "../../../buttons/ActionIntraPageButton"

import OrgUnitTreeView from "../../../tree/orgUnitTree/OrgUnitTreeView"
import OrgGroupCategoriesTreeView from "../../../tree/orgGroupCategoriesTree/OrgGroupCategoriesTreeView"

import global from '../../../GlobalStyle.module.css';

const SearchGroupForm = ({ groupCategoryLevel = undefined, previousSearchFilters, onSubmitFormCallback,
  onAddGroup = undefined, onCancelClicked = undefined }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onClearFormClicked
  } = useSearchGroupForm(previousSearchFilters, onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <OrgUnitTreeView
            label="Group Owner"
            name="orgUnit"
            value={formState.orgUnit}
            error={errorState.orgUnit}
            message={errorState.orgUnit}
            selectableLeavesOnly={false}
            singleSelect={true}
            onChange={(newValue) => { updateFormState('orgUnit', newValue) }}
            organizationId={undefined} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <OrgGroupCategoriesTreeView
            label="Group Category"
            name="groupCategories"
            value={formState.groupCategories}
            error={errorState.groupCategories}
            message={errorState.groupCategories}
            selectableLeavesOnly={false}
            singleSelect={false}
            onChange={(newValue) => { updateFormState('groupCategories', newValue) }}
            groupCategoryLevel={groupCategoryLevel || ''} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Group Name"
            name="groupName"
            value={formState.groupName}
            error={errorState.groupName}
            message={errorState.groupName}
            onChange={(value) => { updateFormState('groupName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Group Code"
            name="groupCode"
            value={formState.groupCode}
            error={errorState.groupCode}
            message={errorState.groupCode}
            onChange={(value) => { updateFormState('groupCode', value) }} />
        </div>
      </div>
      {(errorState.filter || errorState.errorOnSubmitAction) &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">Search for Group</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onClearFormClicked}>Clear Form</SecondaryButton>&nbsp;
          {onAddGroup !== undefined && <ActionIntraPageButton type="button" onClick={onAddGroup}>Add Group </ActionIntraPageButton>}
          {onCancelClicked !== undefined && <SecondaryButton type="button" onClick={() => onCancelClicked()}>Cancel</SecondaryButton>}
        </div>
      </div>
    </form>
  );
};

export default SearchGroupForm;