import { useEffect, useState } from "react";

import useNTGroupData from "../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupMembersData from "../../../state/nationalTeamGroup/nationalTeamGroupMembers/UseNTGroupMembersData";

import { MEMBER_INVITE_STATUS_SUBMITTED } from "../utils/NTGroupConstants";

const INITIAL_STATE = {
  displayApprovalPopup: false,
  approvalGroupMemberObj: {}
};

const useNTGroupApprovals = () => {
  const { onNTGroupsError } = useNTGroupData();
  const { groupMembersState, getNTGroupMembers } = useNTGroupMembersData();
  const [state, setState] = useState(INITIAL_STATE);
  const [gridState, setGridState] = useState(null);

  const onCloseApprovalPopup = async (event, refreshGrid = false) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayApprovalPopup: false,
        approvalGroupMemberObj: {}
      });

      if (refreshGrid === true) {
        const getNTGroupMembersResult = await getNTGroupMembers(groupMembersState.nationalTeamGroupId);

        if (getNTGroupMembersResult) {
          setGridState(createNewApprovalsGridState(getNTGroupMembersResult.objData?.nationalTeamGroupMember || []));
        }
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onOpenApprovalPopupClicked = (event, groupMemberObj) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayApprovalPopup: true,
        approvalGroupMemberObj: groupMemberObj
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  useEffect(() => {
    try {
      if (groupMembersState.isObjLoaded === true) {
        setGridState(createNewApprovalsGridState(groupMembersState.objData?.nationalTeamGroupMember || []));
      }
    } catch (e) {
      onNTGroupsError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createNewApprovalsGridState(nationalTeamGroupMember) {
    const newGridState = [];

    for (const groupMemberObj of nationalTeamGroupMember) {
      if (groupMemberObj.inviteStatus === MEMBER_INVITE_STATUS_SUBMITTED) {
        newGridState.push({
          ...groupMemberObj,
          groupMemberName: `${groupMemberObj.preferredName || groupMemberObj.firstName} ${groupMemberObj.lastName}`
        });
      }
    }

    return newGridState;
  }

  return {
    isGridLoading: groupMembersState.isObjLoading || gridState === null,
    gridData: gridState || [],
    state,
    onCloseApprovalPopup,
    onOpenApprovalPopupClicked
  };
};

export default useNTGroupApprovals;