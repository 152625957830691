import { useContext } from "react";

import { SAAGroupFiltersContext } from "./SAAGroupFiltersContextProvider";

const useSAAGroupFiltersData = () => {
  const [groupMemberFiltersState, setGroupMemberFiltersState] = useContext(SAAGroupFiltersContext);

  const updateGroupMemberFilters = (newFilters) => {
    setGroupMemberFiltersState({ ...groupMemberFiltersState, ...newFilters });
  };

  return {
    groupMemberFiltersState,
    updateGroupMemberFilters
  };
};

export default useSAAGroupFiltersData;