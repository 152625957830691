import { Fragment } from 'react';

import useGroupInfo from './UseGroupInfo';

import GroupInfoForm from '../../../../common/components/forms/groups/GroupInfoForm';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const GroupInfo = () => {
  const {
    isLoading,
    isSaving,
    formState,
    errorState,
    modalState,
    handleSubmit,
    onFormValueChanged,
    onCancelClicked,
    onDeleteGroupClicked,
    onDeleteModalConfirmClicked,
    onDeleteModalCancelClicked,
    orgUnitId
  } = useGroupInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Group Info</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <GroupInfoForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          orgUnitId={orgUnitId} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>&nbsp;
            <PrimaryButton type='button' onClick={onDeleteGroupClicked}>Delete Group</PrimaryButton>
            {errorState && errorState.errorOnSubmitAction
              && <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction}</p>
            }
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete this Group?'}
        displayPopUp={modalState.displayModal}
        onModalCanceled={onDeleteModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Group Name:</b> {modalState.groupName}</p>
            <p><b>Group Type:</b> {modalState.groupType}</p>
            <p><b>Group Category:</b> {modalState.groupCategory}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteModalConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default GroupInfo;