import { Fragment } from 'react';

import useGroupMyGroups from './UseGroupMyGroups';

import MyGroupsSearchForm from '../../../components/forms/myGroupsSearch/MyGroupsSearchForm';
import MyGroupsSearchGrid from '../../../components/grids/myGroupsSearch/MyGroupsSearchGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';

import { LOADING_MSG } from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css'

const MyGroupsHeader = ({ orgUnitName, onSelectOrgUnitClicked }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <button className={global.HeaderButton}>
          <Headings.H3
            className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
            excludeBar={true}>
            My Groups
          </Headings.H3>
        </button>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <hr className="usas-bar-turmeric" />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <button className={global.BackToSearchButton}
          type="button"
          onClick={onSelectOrgUnitClicked}>
          <LeftArrowIcon /> Select Org Unit
        </button>
      </div>
    </div>
    <div className="row usas-extra-bottom-margin">
      <div className={["col-xs-12", global.HeaderText].join(' ')}>
        <p className={global.HeaderText}><strong>Editing:</strong> {orgUnitName}</p>
      </div>
    </div>
  </Fragment>
);

const GroupMyGroups = () => {
  const {
    gridData,
    isLoading,
    isGridLoading,
    showFilters,
    previousSearchFilters,
    selectOrgUnitState,
    onToggleShowFilters,
    onSubmitSearchForm,
    onAddGroupClicked,
    onEditGroupClicked,
    onSelectOrgUnitClicked
  } = useGroupMyGroups();

  return (
    <Fragment>
      <MyGroupsHeader orgUnitName={selectOrgUnitState.orgUnitName} onSelectOrgUnitClicked={onSelectOrgUnitClicked} />
      <div className={showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <MyGroupsSearchForm
          orgUnitId={selectOrgUnitState.orgUnitId}
          previousSearchFilters={previousSearchFilters}
          onSubmitFormCallback={onSubmitSearchForm}
          onAddGroup={onAddGroupClicked} />
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <MyGroupsSearchGrid
            gridData={gridData}
            isLoading={isGridLoading}
            showFilters={showFilters}
            onEditGroupClicked={onEditGroupClicked}
            onToggleShowFilters={onToggleShowFilters} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Error'}
        displayPopUp={!selectOrgUnitState.orgUnitId}
        onModalCanceled={onSelectOrgUnitClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>No Org Unit Loaded</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSelectOrgUnitClicked}>Reload My Groups</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default GroupMyGroups;