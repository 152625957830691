import { createContext, useState } from 'react';

import SAAGroupInfoData from './SAAGroupInfoData';

export const SAAGroupInfoContext = createContext();

const SAAGroupInfoContextProvider = ({ children }) => {
  const stateHook = useState(SAAGroupInfoData.INITIAL_GROUP_INFO_STATE);

  return (
    <SAAGroupInfoContext.Provider value={stateHook}>
      {children}
    </SAAGroupInfoContext.Provider>
  );
};

export default SAAGroupInfoContextProvider;