import useNTGroupAthleteEventAddForm from "./UseNTGroupAthleteEventAddForm";

import Input from "../../../../../../common/components/inputs/Input";
import ReadOnly from "../../../../../../common/components/readOnly/ReadOnly";
import TimeInput from "../../../../../../common/components/inputs/TimeInput";
import DatePicker from "../../../../../../common/components/datepickers/DatePicker";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import EventCombobox from "../../../../../../common/components/comboboxes/eventCombobox/EventCombobox";

import global from '../../../../../../common/components/GlobalStyle.module.css';

const NTGroupAthleteEventAddForm = ({ groupAthleteName, submitButtonText = 'Add Event', onSubmitFormCallback }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  } = useNTGroupAthleteEventAddForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <ReadOnly
            label='Athlete'
            name='groupAthleteName'
            value={groupAthleteName} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <EventCombobox
            label='Event*'
            name='eventId'
            valueToMatch={formState.eventName}
            error={errorState.eventId}
            message={errorState.eventId}
            onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'eventId', newValueLabel, 'eventName'); }} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <TimeInput
            label="Swim Time*"
            name="swimTime"
            value={formState.swimTime}
            error={errorState.swimTime}
            message={errorState.swimTime}
            onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'swimTime', value, 'swimTimeValue'); }} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <DatePicker
            label="Swim Date*"
            name="swimDate"
            value={formState.swimDate}
            error={errorState.swimDate}
            message={errorState.swimDate}
            onChange={(value) => { updateFormState('swimDate', value); }}
            countOfYears={30}
            startYearOffset={-30} />
        </div>
        <div className="col-xs-12 col-sm-6">
          <Input
            label='Meet Name*'
            name="meetName"
            value={formState.meetName}
            error={errorState.meetName}
            message={errorState.meetName}
            onChange={(value) => { updateFormState('meetName', value) }} />
        </div>
      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
        </div>
      </div>
    </form>
  );
};

export default NTGroupAthleteEventAddForm;