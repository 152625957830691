import { useContext, useState } from "react";

import GroupSearchData from "./GroupSearchData";
import { GroupFiltersContext } from "./GroupFiltersContextProvider";

const useGroupSearchData = () => {
  const [groupFiltersState, setGroupFiltersState] = useContext(GroupFiltersContext);
  const [groupSearchState, setGroupSearchState] = useState(GroupSearchData.INITIAL_GROUP_SEARCH_STATE);

  const searchGroups = (filterObject) => {
    setGroupFiltersState({ ...groupFiltersState, filterObject });
    return GroupSearchData.searchGroupsData(JSON.stringify(filterObject), groupSearchState, setGroupSearchState);
  };

  const onToggleShowFilters = () => {
    setGroupFiltersState({
      ...groupFiltersState,
      showFilters: !groupFiltersState.showFilters
    });
  };

  const resetGroupFiltersState = () => {
    setGroupFiltersState({
      ...GroupSearchData.INITIAL_GROUP_FILTERS_STATE
    });
  };

  return {
    groupSearchState,
    groupFiltersState,
    searchGroups,
    onToggleShowFilters,
    resetGroupFiltersState
  };
};

export default useGroupSearchData;