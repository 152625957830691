import { useContext } from "react";

import SAAGroupData from "./SAAGroupData";
import { SAAGroupContext } from "./SAAGroupContextProvider";

const useSAAGroupData = () => {
  const [scholasticAllAmericaGroupState, setScholasticAllAmericaGroupState] = useContext(SAAGroupContext);

  const onSAAGroupsError = (e, errorMessage = undefined, errorTitle = 'Scholastic All America Groups Error') => {
    setScholasticAllAmericaGroupState({
      ...scholasticAllAmericaGroupState,
      isError: true,
      error: e,
      errorTitle,
      errorMessage: errorMessage || e?.message || 'An error occurred.'
    });
  };

  const resetScholasticAllAmericaGroupState = () => {
    setScholasticAllAmericaGroupState({
      ...SAAGroupData.INITIAL_SCHOLASTIC_ALL_AMERICA_GROUP_STATE
    });
  };

  return {
    scholasticAllAmericaGroupState,
    onSAAGroupsError,
    resetScholasticAllAmericaGroupState
  };
};

export default useSAAGroupData;