import { BLANK_DATE_STRING, DEFAULT_ID } from '../../../../../../common/utils/Constants';
import { formatTimeForFilterObject } from '../../../../../../common/utils/TimesUtils';
import { isValidBirthDate, isValidDate, isValidSwimTime } from '../../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  // Event
  if (formState.eventName === '') {
    errors.eventId = 'Event is required';
  } else if (formState.eventId === DEFAULT_ID || formState.eventId === '') {
    errors.eventId = 'Event must be valid';
  }

  // Swim Time 
  if (formState.swimTimeValue === '') {
    errors.swimTime = 'Swim Time is required';
  } else if (!isValidSwimTime(formatTimeForFilterObject(formState.swimTimeValue))) {
    errors.swimTime = 'Swim Time must be valid';
  }

  // Swim Date
  if (formState.swimDate === BLANK_DATE_STRING) {
    errors.swimDate = 'Swim Date is required';
  } else if (!isValidDate(formState.swimDate)) {
    errors.swimDate = 'Swim Date must be a valid date';
  } else if (!isValidBirthDate(formState.swimDate)) {
    errors.swimDate = 'Swim Date cannot be in the future'
  }

  // Meet Name
  if (!(formState.meetName?.trim())) {
    errors.meetName = 'Meet Name is required';
  }

  return errors;
};

const NTGroupAthleteEventAddFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default NTGroupAthleteEventAddFormValidation;