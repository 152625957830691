import useNTGroupTypesTreeView from './UseNTGroupTypesTreeView';

import TreeView from '../../../../../../common/components/tree/TreeView';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const NTGroupTypesTreeView = ({ label, name, value, error, message, selectableLeavesOnly, singleSelect,
  onChange }) => {
  const { nationalTeamGroupTypesHierarchyState } = useNTGroupTypesTreeView();

  return nationalTeamGroupTypesHierarchyState.message
    ? <span className={global.LoadingMsg}>{nationalTeamGroupTypesHierarchyState.message}</span>
    : <TreeView
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      isLoading={nationalTeamGroupTypesHierarchyState.isArrayLoading === true}
      options={nationalTeamGroupTypesHierarchyState.treeData || []}
      selectableLeavesOnly={selectableLeavesOnly}
      singleSelect={singleSelect}
      onChange={onChange} />
};

export default NTGroupTypesTreeView;