import { Fragment } from 'react';

import useGroupMemberEdit from './UseGroupMemberEdit';

import ListRegistrationForm from '../../components/forms/ListRegistrationForm';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import CompetitorListRegistrationForm from '../../../../../common/components/forms/groups/CompetitorListRegistrationForm';
import CampRegistrationForm from '../../../../../common/components/forms/groups/CampRegistrationForm';
import SAARegistrationForm from '../../../../../common/components/forms/groups/SAARegistrationForm';
import NationalTeamRegistrationForm from '../../../../../common/components/forms/groups/NationalTeamRegistrationForm';
import ErrorMessage from '../../../../../common/components/errors/ErrorMessage';
import GroupQualificationEventGrid from '../../../../../common/components/grids/groupQualificationEvent/GroupQualificationEventGrid';

import { LOADING_MSG, SAVING_MSG } from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GroupRegistrationForm = ({ GROUP_CONSTANTS, groupTypeName, personOrgGroupQualificationStatus, personId, personOrgGroupId, groupObj, memberGroupObj,
  onBackClicked, onFormCompletion }) => {
  switch (groupTypeName) {
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
      return <ListRegistrationForm personId={personId} personOrgGroupId={personOrgGroupId}
        personOrgGroupQualificationStatus={personOrgGroupQualificationStatus} groupObj={groupObj} memberGroupObj={memberGroupObj}
        onSecondaryButtonClicked={onBackClicked} onFormCompletion={onFormCompletion} />;
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_COMPETITOR_LIST:
      return <CompetitorListRegistrationForm personId={personId} personOrgGroupId={personOrgGroupId}
        personOrgGroupQualificationStatus={personOrgGroupQualificationStatus} groupObj={groupObj} memberGroupObj={memberGroupObj}
        disableValidation={true} onSecondaryButtonClicked={onBackClicked} onFormCompletion={onFormCompletion} />;
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_CAMP:
      return <CampRegistrationForm personId={personId} personOrgGroupId={personOrgGroupId}
        personOrgGroupQualificationStatus={personOrgGroupQualificationStatus} groupObj={groupObj} memberGroupObj={memberGroupObj}
        disableValidation={true} onSecondaryButtonClicked={onBackClicked} onFormCompletion={onFormCompletion} />;
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_SAA:
      return <SAARegistrationForm personId={personId} personOrgGroupId={personOrgGroupId}
        personOrgGroupQualificationStatus={personOrgGroupQualificationStatus} groupObj={groupObj} memberGroupObj={memberGroupObj}
        disableValidation={true} isAdminEdit={true} onSecondaryButtonClicked={onBackClicked}
        onFormCompletion={onFormCompletion} />;
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_NATIONAL_TEAM:
      return <NationalTeamRegistrationForm personId={personId} personOrgGroupId={personOrgGroupId}
        personOrgGroupQualificationStatus={personOrgGroupQualificationStatus} groupObj={groupObj} memberGroupObj={memberGroupObj}
        disableValidation={true} onSecondaryButtonClicked={onBackClicked} onFormCompletion={onFormCompletion} />;
    default:
      return <ErrorMessage message={'An error occurred'} />
  }
};

const GroupMemberEdit = () => {
  const {
    GROUP_CONSTANTS,
    isSaving,
    isLoading,
    isMemberGroupObjLoaded,
    isGridLoading,
    groupObj,
    memberGroupObj,
    groupQualificationEvents,
    personId,
    state,
    onBackClicked,
    onFormCompletion
  } = useGroupMemberEdit();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Edit Group Member</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <p className={global.HeaderText}><strong>Editing:</strong>&nbsp;{state.firstName} {state.lastName}</p>
        </div>
      </div>
      <div className="row">
        {groupQualificationEvents.length > 0 &&
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <GroupQualificationEventGrid data={groupQualificationEvents} isLoading={isGridLoading} />
          </div>
        }
      </div>
      {isMemberGroupObjLoaded === true && (
        <GroupRegistrationForm
          GROUP_CONSTANTS={GROUP_CONSTANTS}
          groupTypeName={groupObj.groupType?.typeName}
          personId={personId}
          personOrgGroupQualificationStatus={state.personOrgGroupQualificationStatus}
          personOrgGroupId={memberGroupObj.personOrgGroupId}
          groupObj={groupObj}
          memberGroupObj={memberGroupObj}
          onFormCompletion={onFormCompletion}
          onBackClicked={onBackClicked} />
      )}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default GroupMemberEdit;