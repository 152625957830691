import { useEffect } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useGlobalRoutesData from "../../../../common/state/security/UseGlobalRoutesData";

const useSAAGroupLanding = () => {
  const navigate = useNavigate();
  const { globalRoutesState, getGlobalRoute } = useGlobalRoutesData();

  useEffect(() => {
    if (globalRoutesState.isArrayLoaded === true) {
      const route = getGlobalRoute('SAA_GROUP_MEMBERS');

      navigate(route?.route || '/scholasticallamericagroup/members'); //TODO remove once ProjectRouteInfo.json is updated
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalRoutesState.isArrayLoaded]);
};

export default useSAAGroupLanding;