import useNTGroupsLoadingModal from "./UseNTGroupsLoadingModal";

import PopUpModal from "../../../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";

import { LOADING_MSG } from "../../../../../../common/utils/Constants";

const NTGroupsLoadingModal = ({ isLoading, loadingMessage = LOADING_MSG}) => {
  const {
    nationalTeamGroupState,
    onReloadClicked
  } = useNTGroupsLoadingModal();

  if (nationalTeamGroupState.isError === true) {
    return (
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={nationalTeamGroupState.errorTitle}
        displayPopUp={true}
        onModalCanceled={onReloadClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{nationalTeamGroupState.errorMessage}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onReloadClicked}>Reload National Team Groups</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
    );
  } else {
    return (
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={loadingMessage}
        displayPopUp={isLoading === true} />
    );
  }
};

export default NTGroupsLoadingModal;