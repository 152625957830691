import { Fragment } from 'react';

import useNTGroupInviteAthletesFormGrid from './UseNTGroupInviteAthletesFormGrid';

import NTGroupInviteAthletesFormGridLarge from './NTGroupInviteAthletesFormGridLarge';
import NTGroupInviteAthletesFormGridSmall from './NTGroupInviteAthletesFormGridSmall';

import Spinner from '../../../../../../common/components/spinners/Spinner';
import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';
import useExpandedRow from '../../../../../../common/components/grids/UseExpandedRow';
import ActionIntraPageButton from '../../../../../../common/components/buttons/ActionIntraPageButton';

const NTGroupInviteAthletesFormGrid = ({ gridData, isLoading, onEditClicked, onSubmitFormCallback }) => {
  const {
    canInviteState,
    formGridState,
    onUpdateIsSelected,
    onUpdateAllGridItems,
    onInviteSelectedClicked
  } = useNTGroupInviteAthletesFormGrid(gridData, onSubmitFormCallback);
  const { expandedId, onClick } = useExpandedRow();


  if (isLoading === true) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" isDisabled={canInviteState !== true} onClick={onInviteSelectedClicked}>
            Invite Selected
          </PrimaryButton>
          <span className='pull-right'>
            <ActionIntraPageButton type="button" onClick={() => onUpdateAllGridItems(canInviteState === true ? false : true)}>
              {canInviteState === true ? 'Unselect All' : 'Select All'}
            </ActionIntraPageButton>
          </span>
        </div>
      </div>
      <NTGroupInviteAthletesFormGridLarge formGridState={formGridState} expandedId={expandedId} onEditClicked={onEditClicked} onUpdateIsSelected={onUpdateIsSelected} onExpandClicked={onClick} />
      <NTGroupInviteAthletesFormGridSmall formGridState={formGridState} expandedId={expandedId} onEditClicked={onEditClicked} onUpdateIsSelected={onUpdateIsSelected} onExpandClicked={onClick} />
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="button" isDisabled={canInviteState !== true} onClick={onInviteSelectedClicked}>
            Invite Selected
          </PrimaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default NTGroupInviteAthletesFormGrid;