import { Fragment } from 'react';

import useSAAGroupMembersDetail from './UseSAAGroupMembersDetail';

import SAAGroupsLoadingModal from '../components/dialogs/loading/SAAGroupsLoadingModal';

import Headings from '../../../../common/components/headings/Headings';
import SAARegistrationForm from '../../../../common/components/forms/groups/SAARegistrationForm';
import GroupQualificationEventGrid from '../../../../common/components/grids/groupQualificationEvent/GroupQualificationEventGrid';

import { LOADING_MSG, SAVING_MSG } from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const SAAGroupMembersDetail = () => {
  const {
    isSaving,
    isLoading,
    isMemberGroupObjLoaded,
    isGridLoading,
    groupObj,
    memberGroupObj,
    groupQualificationEvents,
    personId,
    state,
    onBackClicked,
    onFormCompletion
  } = useSAAGroupMembersDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Edit Group Member</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <p className={global.HeaderText}><strong>Editing:</strong>&nbsp;{state.firstName} {state.lastName}</p>
        </div>
      </div>
      <div className="row">
        {groupQualificationEvents.length > 0 &&
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <GroupQualificationEventGrid data={groupQualificationEvents} isLoading={isGridLoading} />
          </div>
        }
      </div>
      {isMemberGroupObjLoaded === true && (
        <SAARegistrationForm
          personId={personId}
          personOrgGroupId={memberGroupObj.personOrgGroupId}
          personOrgGroupQualificationStatus={state.personOrgGroupQualificationStatus}
          groupObj={groupObj}
          memberGroupObj={memberGroupObj}
          onSecondaryButtonClicked={onBackClicked}
          onFormCompletion={onFormCompletion}
          disableValidation={true}
          isAdminEdit={true} />
      )}
      <SAAGroupsLoadingModal
        isLoading={isSaving || isLoading}
        loadingMessage={isSaving ? SAVING_MSG : LOADING_MSG} />
    </Fragment>
  );
};

export default SAAGroupMembersDetail;