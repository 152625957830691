import { Fragment } from "react";

import useSearchMeetPopup from './UseSearchMeetPopup';

import SearchMeetPopupGrid from "./SearchMeetPopupGrid";

import Input from "../../inputs/Input";
import PopUpModal from "../../dialogs/PopUpModal";
import LookupIcon from "../../icons/LookupIcon";
import PrimaryButton from "../../buttons/PrimaryButton";
import SecondaryButton from "../../buttons/SecondaryButton";

import global from "../../GlobalStyle.module.css";
import style from './SearchMeetPopup.module.css';

const SearchMeetPopup = ({ label = "Meet", meetIdKey = "meetId", meetNameKey = 'meetName',
  hostOrganizationId, meetClassificationId = undefined, formState, errorState, setFormData }) => {
  const {
    state,
    meetLookupState,
    popupFormState,
    popupErrorState,
    updatePopupFormState,
    handleSubmit,
    onDisplayPopupClicked,
    onHidePopupClicked,
    onSelectMeet
  } = useSearchMeetPopup(formState, setFormData, meetIdKey, meetNameKey, hostOrganizationId, meetClassificationId);

  return (
    <Fragment>
      <div className="usas-extra-bottom-margin">
        <div className={global.ComponentFlex}>
          {label && <label className={global.UsasLabel} htmlFor={meetIdKey}>{label}</label>}
          <a onClick={() => onDisplayPopupClicked()}>
            <div>
              <input
                className={[global.BaseInputBox, style.ButtonInputBox].join(' ') }
                type={'button'}
                value={formState[meetNameKey]}
                id={meetIdKey}
                name={meetIdKey} />
            </div>
            <LookupIcon />
          </a>
          {errorState[meetIdKey] && <p className={global.ErrorMessage}>{errorState[meetIdKey]}</p>}
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={600}
        title={'Meet Lookup'}
        displayPopUp={state.displayPopup === true}
        onModalCanceled={onHidePopupClicked}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <SearchMeetPopupGrid
              data={meetLookupState.arrayData}
              isLoading={meetLookupState.isArrayLoading}
              onSelectMeet={onSelectMeet} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <Input
              label="Meet Name"
              name="meetName"
              value={popupFormState.meetName}
              error={popupErrorState.meetName}
              message={popupErrorState.meetName}
              onChange={(value) => { updatePopupFormState('meetName', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={handleSubmit}>Search</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onHidePopupClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default SearchMeetPopup;