import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";

import useGroupData from "../../../../../common/state/group/UseGroupData";
import useGroupFieldNamesData from "../../../../../common/state/groupFieldNames/UseGroupFieldNamesData";
import useMemberGroupsData from "../../../../../common/state/memberGroups/UseMemberGroupsData";
import useOrgGroupPropertiesData from "../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData";

import Constants from "../../../../../common/utils/Constants";

const INITIAL_STATE = {
  personOrgGroupId: Constants.DEFAULT_ID,
  firstName: '',
  lastName: '',
  personOrgGroupQualificationStatus: ''
};

const useGroupMemberEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgGroupPropertiesState } = useOrgGroupPropertiesData();
  const { groupState, groupMembersState, getGroupMembers } = useGroupData();
  const { memberGroupsState, memberGroupsQualificationEventState, getGroupMember, getGroupQualificationEvents,
    putGroupMember
  } = useMemberGroupsData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const [state, setState] = useState(INITIAL_STATE);

  const onBackClicked = () => {
    navigate(NavLinks.GROUP_MEMBERS);
  };

  const onFormCompletion = async (groupMemberObj) => {
    const putGroupMemberResult = await putGroupMember(state.personOrgGroupId, groupMemberObj);

    if (putGroupMemberResult) {
      const getGroupMembersResult = await getGroupMembers(groupState.objData.orgGroupId);

      if (getGroupMembersResult) {
        navigate(NavLinks.GROUP_MEMBERS);
      }
    }
  };

  useEffect(() => {
    async function loadGroupMember() {
      if (location.state?.member?.personOrgGroupId > 0) {
        const member = location.state.member;

        const getGroupMemberResult = await getGroupMember(member.personOrgGroupId);

        if (getGroupMemberResult) {
          const memberGroupObj = getGroupMemberResult.objData;

          setState({
            ...state,
            personOrgGroupId: memberGroupObj.personOrgGroupId,
            firstName: member.firstName,
            lastName: member.lastName,
            personOrgGroupQualificationStatus: member.personOrgGroupQualificationStatus
          });

          const personOrgGroupQualification = Array.isArray(memberGroupObj.personOrgGroupQualification) && memberGroupObj.personOrgGroupQualification.length > 0
            ? memberGroupObj.personOrgGroupQualification[0]
            : undefined;

          if (personOrgGroupQualification) {
            getGroupQualificationEvents(personOrgGroupQualification.personOrgGroupQualificationId, true);
          }
        }
      }
    }
    loadGroupMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    GROUP_CONSTANTS,
    isSaving: memberGroupsState.isSaving,
    isLoading: orgGroupPropertiesState.isArrayLoading || memberGroupsState.isObjLoading || groupMembersState.isArrayLoading,
    isGridLoading: memberGroupsQualificationEventState.isArrayLoading,
    isMemberGroupObjLoaded: memberGroupsState.isObjLoaded,
    groupObj: groupState.objData || {},
    memberGroupObj: memberGroupsState.objData || {},
    groupQualificationEvents: memberGroupsQualificationEventState.arrayData || [],
    personId: memberGroupsState.objData?.personId || '',
    state,
    onBackClicked,
    onFormCompletion
  };
};

export default useGroupMemberEdit;