import { useEffect } from "react";

import useNTGroupData from "../../../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupApprovalData from "../../../../../state/nationalTeamGroup/nationalTeamGroupApproval/UseNTGroupApprovalData";
import useNTGroupInfoData from "../../../../../state/nationalTeamGroup/nationalTeamGroupInfo/UseNTGroupInfoData";

const useNTGroupAthleteApprovalModal = (memberObj, onCloseModal) => {
  const { onNTGroupsError } = useNTGroupData();
  const { groupInfoState } = useNTGroupInfoData();
  const { groupApprovalState, groupApprovalSubmitState, getNTGroupApproval, postNTGroupApproval } = useNTGroupApprovalData();

  const onSubmitContactInfoFormGrid = async (formGridState) => {
    try {
      const postNTGroupApprovalResult = await postNTGroupApproval(groupInfoState.nationalTeamGroupId, memberObj.personNationalTeamGroupId, formGridState);
    
      if (postNTGroupApprovalResult) {
        onCloseModal(undefined, true);
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  useEffect(() => {
    async function loadNTGroupApproval() {
      try {
        await getNTGroupApproval(groupInfoState.nationalTeamGroupId, memberObj.personNationalTeamGroupId);
      } catch (e) {
        onNTGroupsError(e);
        onCloseModal();
      }
    }
    loadNTGroupApproval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberObj]);

  return {
    isLoading: groupApprovalState.isArrayLoading,
    isSaving: groupApprovalSubmitState.isArrayLoading,
    gridData: groupApprovalState.arrayData || [],
    onSubmitContactInfoFormGrid
  };
};

export default useNTGroupAthleteApprovalModal;