
const INITIAL_SCHOLASTIC_ALL_AMERICA_GROUP_STATE = {
  isError: false,
  error: {},
  errorTitle: '',
  errorMessage: ''
};  

const SAAGroupData = {
  INITIAL_SCHOLASTIC_ALL_AMERICA_GROUP_STATE,
};

export default SAAGroupData;