import useMyGroupsSearchForm from "./UseMyGroupsSearchForm";

import Input from "../../../../common/components/inputs/Input";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import ActionIntraPageButton from "../../../../common/components/buttons/ActionIntraPageButton";
import GroupCategoriesTreeView from "../../../../common/components/tree/groupCategoriesTree/GroupCategoriesTreeView";

import global from '../../../../common/components/GlobalStyle.module.css';

const MyGroupsSearchForm = ({ orgUnitId, previousSearchFilters, onSubmitFormCallback, onAddGroup = undefined }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onClearFormClicked
  } = useMyGroupsSearchForm(orgUnitId, previousSearchFilters, onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        {orgUnitId &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <GroupCategoriesTreeView
              label="Group Category*"
              name="groupCategories"
              value={formState.groupCategories}
              error={errorState.groupCategories}
              message={errorState.groupCategories}
              selectableLeavesOnly={false}
              singleSelect={false}
              onChange={(value) => { updateFormState('groupCategories', value) }}
              orgUnitId={orgUnitId} />
          </div>
        }
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Group Name"
            name="groupName"
            value={formState.groupName}
            error={errorState.groupName}
            message={errorState.groupName}
            onChange={(value) => { updateFormState('groupName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Group Code"
            name="groupCode"
            value={formState.groupCode}
            error={errorState.groupCode}
            message={errorState.groupCode}
            onChange={(value) => { updateFormState('groupCode', value) }} />
        </div>
      </div>
      {(errorState.filter || errorState.errorOnSubmitAction) &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">Search for Group</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onClearFormClicked}>Clear Form</SecondaryButton>&nbsp;
          {onAddGroup !== undefined && <ActionIntraPageButton type="button" onClick={onAddGroup}>Add Group </ActionIntraPageButton>}
        </div>
      </div>
    </form>
  );
};

export default MyGroupsSearchForm;