import { useEffect, useState } from "react";

import useNTGroupData from "../../../../../state/nationalTeamGroup/UseNTGroupData";
import { formatPhoneNumber } from "../../../../../../common/utils/validation";
import { compareAlphabetical } from "../../../../../../common/utils/SortingUtils";

const NO_DATA_NATIONAL_TEAM = 'NO DATA';
const NO_DATA_REGISTRATION = 'DELETED BY USER';

const CONTACT_TYPE_NEW = 'AddContact';
const CONTACT_TYPE_EXISTING = 'ExistingContact';
const CONTACT_TYPE_DELETED = 'DeletedContact';
const CONTACT_FIELD_TYPE_BASE_INFO = 'ContactBaseInfo';
const CONTACT_FIELD_TYPE_PHONE = 'ContactPhone';
const CONTACT_FIELD_TYPE_ADDRESS = 'ContactAddress';

const useNTGroupContactApprovalFormGrid = (gridData, onSubmitFormCallback) => {
  const { onNTGroupsError } = useNTGroupData();
  const [formGridState, setFormGridState] = useState([]);

  const onConfirmApprovalClicked = (event) => {
    try {
      event?.preventDefault();

      const contactApprovals = [];

      for (const contactFormObj of formGridState) {
        const contactApprovalObj = { contactBaseInfo: null, contactPhone: [], contactAddress: [] };

        for (const contactField of contactFormObj.contactFields) {
          if (contactField.fieldType === CONTACT_FIELD_TYPE_BASE_INFO) {
            contactApprovalObj.contactBaseInfo = contactField.isApproved === true
              ? contactField.registrationValue
              : contactField.nationalTeamValue ? { ...contactField.nationalTeamValue, personNationalTeamRegistrationContactId: null } : null;
          } else if (contactField.fieldType === CONTACT_FIELD_TYPE_PHONE) {
            contactApprovalObj.contactPhone.push(contactField.isApproved === true
              ? contactField.registrationValue
              : contactField.nationalTeamValue ? { ...contactField.nationalTeamValue, personNationalTeamRegistrationContactPhoneId: null } : null);
          } else if (contactField.fieldType === CONTACT_FIELD_TYPE_ADDRESS) {
            contactApprovalObj.contactAddress.push(contactField.isApproved === true
              ? contactField.registrationValue
              : contactField.nationalTeamValue ? { ...contactField.nationalTeamValue, personNationalTeamRegistrationContactAddressId: null } : null);
          }
        }

        contactApprovals.push(contactApprovalObj);
      }

      onSubmitFormCallback(contactApprovals);
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onUpdateIsApproved = (contactField, contactId) => {
    try {
      const newFormGridState = JSON.parse(JSON.stringify(formGridState));

      for (let i = 0; i < newFormGridState.length; i++) {
        if (newFormGridState[i].id === contactId) {
          if (contactField.fieldType === CONTACT_FIELD_TYPE_BASE_INFO
            && (newFormGridState[i].contactType === CONTACT_TYPE_NEW || newFormGridState[i].contactType === CONTACT_TYPE_DELETED)) {
            for (let j = 0; j < newFormGridState[i].contactFields.length; j++) {
              newFormGridState[i].contactFields[j].isApproved = !newFormGridState[i].contactFields[j].isApproved;
            }
          } else {
            for (let j = 0; j < newFormGridState[i].contactFields.length; j++) {
              if (newFormGridState[i].contactFields[j].id === contactField.id) {
                newFormGridState[i].contactFields[j].isApproved = !newFormGridState[i].contactFields[j].isApproved;
                break;
              }
            }
          }

        }
      }

      setFormGridState(newFormGridState);
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  useEffect(() => {
    try {
      const newFormGridState = [];

      for (let i = 0; i < gridData.length; i++) {
        const newContactFormObj = createContactFormObj(gridData[i], i);

        newFormGridState.push(newContactFormObj);
      }

      newFormGridState.sort((a, b) => compareAlphabetical(a.contactType, b.contactType));

      setFormGridState(newFormGridState);
    } catch (e) {
      onNTGroupsError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  function createContactFormObj(contactObj, index) {
    const contactFields = [];
    let contactType = '';
    let contactTypeLabel = '';

    if (contactObj.contactBaseInfo?.nationalTeamContact === null) {
      contactType = CONTACT_TYPE_NEW;
      contactTypeLabel = 'New Contact';
    } else if (contactObj.contactBaseInfo?.registrationContact === null) {
      contactType = CONTACT_TYPE_DELETED;
      contactTypeLabel = 'Deleted Contact';
    } else {
      contactType = CONTACT_TYPE_EXISTING;
      contactTypeLabel = 'Existing Contact';
    }

    if (contactObj.contactBaseInfo) {
      contactFields.push({
        id: `${index * 10}${0}BaseInfo`,
        fieldType: CONTACT_FIELD_TYPE_BASE_INFO,
        fieldLabel: 'Base Info',
        nationalTeamValue: contactObj.contactBaseInfo.nationalTeamContact,
        registrationValue: contactObj.contactBaseInfo.registrationContact,
        nationalTeamDisplayValue: contactObj.contactBaseInfo.nationalTeamContact ? getContactBaseInfoDisplayValue(contactObj.contactBaseInfo.nationalTeamContact) : NO_DATA_NATIONAL_TEAM,
        registrationDisplayValue: contactObj.contactBaseInfo.registrationContact ? getContactBaseInfoDisplayValue(contactObj.contactBaseInfo.registrationContact) : NO_DATA_REGISTRATION,
        isUpdated: contactObj.contactBaseInfo.isUpdated,
        isApproved: false,
        isReadonly: false
      });
    }

    if (Array.isArray(contactObj.contactPhone)) {
      for (let i = 0; i < contactObj.contactPhone.length; i++) {
        contactFields.push({
          id: `${index * 10}${i}Phone`,
          fieldType: CONTACT_FIELD_TYPE_PHONE,
          fieldLabel: 'Phone',
          nationalTeamValue: contactObj.contactPhone[i].nationalTeamContactPhone,
          registrationValue: contactObj.contactPhone[i].registrationContactPhone,
          nationalTeamDisplayValue: contactObj.contactPhone[i].nationalTeamContactPhone?.phoneNumber ? formatPhoneNumber(contactObj.contactPhone[i].nationalTeamContactPhone.phoneNumber) : NO_DATA_NATIONAL_TEAM,
          registrationDisplayValue: contactObj.contactPhone[i].registrationContactPhone?.phoneNumber ? formatPhoneNumber(contactObj.contactPhone[i].registrationContactPhone.phoneNumber) : NO_DATA_REGISTRATION,
          isUpdated: contactObj.contactPhone[i].isUpdated,
          isApproved: false,
          isReadonly: contactType === CONTACT_TYPE_DELETED || contactType === CONTACT_TYPE_NEW
        });
      }
    }

    if (Array.isArray(contactObj.contactAddress)) {
      for (let i = 0; i < contactObj.contactAddress.length; i++) {
        contactFields.push({
          id: `${index * 10}${i}Address`,
          fieldType: CONTACT_FIELD_TYPE_ADDRESS,
          fieldLabel: 'Address',
          nationalTeamValue: contactObj.contactAddress[i].nationalTeamContactAddress,
          registrationValue: contactObj.contactAddress[i].registrationContactAddress,
          nationalTeamDisplayValue: contactObj.contactAddress[i].nationalTeamContactAddress ? getContactAddressDisplayValue(contactObj.contactAddress[i].nationalTeamContactAddress) : NO_DATA_NATIONAL_TEAM,
          registrationDisplayValue: contactObj.contactAddress[i].registrationContactAddress ? getContactAddressDisplayValue(contactObj.contactAddress[i].registrationContactAddress) : NO_DATA_REGISTRATION,
          isUpdated: contactObj.contactAddress[i].isUpdated,
          isApproved: false,
          isReadonly: contactType === CONTACT_TYPE_DELETED || contactType === CONTACT_TYPE_NEW
        });
      }
    }

    return {
      id: `${index * 10}${0}Contact`,
      contactType,
      contactTypeLabel,
      contactFields
    }
  }

  function getContactBaseInfoDisplayValue(contactBaseInfoObj) {
    return `${contactBaseInfoObj.firstName} ${contactBaseInfoObj.lastName} \n${contactBaseInfoObj.contactRelationshipTypeName} \n${contactBaseInfoObj.emailAddress || 'NO EMAIL'}`;
  }

  function getContactAddressDisplayValue(contactAddressObj) {
    return `${contactAddressObj.address1} ${contactAddressObj.address2 || ''} \n${contactAddressObj.city}, ${contactAddressObj.stateCode || contactAddressObj.countryCode} ${contactAddressObj.postalCode} \n${contactAddressObj.addressType ? contactAddressObj.addressType.trim() : 'NO ADDRESS TYPE'}`;
  }

  return {
    formGridState,
    onUpdateIsApproved,
    onConfirmApprovalClicked
  };
};

export default useNTGroupContactApprovalFormGrid;