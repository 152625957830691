import { useEffect } from 'react';

import NavLinks from '../NavLinks';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useGroupSearchData from '../../../../common/state/groupSearch/UseGroupSearchData';

const useGroupSelection = () => {
  const { environmentVariableState, GROUP_SELECTOR_ROLES_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { resetGroupFiltersState } = useGroupSearchData();

  useEffect(() => {
    resetGroupFiltersState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoaded: environmentVariableState.isLoaded === true,
    NavLinks,
    GROUP_SELECTOR_ROLES_ROLE_GROUP_ID
  };
};

export default useGroupSelection;