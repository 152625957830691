import { useEffect, useState } from "react";
import { useNavigate } from "../../../common/wrappers/ReactRouterDom";

import useSAAGroupData from "../../state/scholasticAllAmericaGroup/UseSAAGroupData";
import useSAAGroupInfoData from "../../state/scholasticAllAmericaGroup/scholasticAllAmericaGroupInfo/UseSAAGroupInfoData";
import useSAAGroupMembersData from "../../state/scholasticAllAmericaGroup/scholasticAllAmericaGroupMembers/UseSAAGroupMembersData";

import useNavRoutes from "../../../common/state/security/UseNavRoutes";
import useSecurityData from "../../../common/state/security/UseSecurityData";

const TAXONOMIES = ['SAAGroupsDetail'];
const SCOPE = 'SAAGroup';

const LOADING_PHASES = {
  ERROR: -1,
  NOT_LOADED: 1,
  LOADING_GROUP: 2,
  LOADING_MEMBERS: 3,
  LOADING_CONTEXT: 4,
  LOADED: 5
};

const useSAAGroupsContextView = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { onSAAGroupsError } = useSAAGroupData();
  const { groupInfoState, getSAAGroupInfo } = useSAAGroupInfoData();
  const { groupMembersState, getSAAGroupMembers } = useSAAGroupMembersData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();
  const [loadingState, setLoadingState] = useState(LOADING_PHASES.NOT_LOADED);

  const onHomeClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(navRoutes.SAA_GROUP_MEMBERS?.route);
    } catch (e) {
      onSAAGroupsError(e);
    }
  };

  const onBackToClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(navRoutes.SAA_GROUP_LANDING?.route);
    } catch (e) {
      onSAAGroupsError(e);
    }
  };

  useEffect(() => {
    try {
      switch (loadingState) {
        case LOADING_PHASES.NOT_LOADED:
          getSAAGroupInfo();
          setLoadingState(LOADING_PHASES.LOADING_GROUP);
          break;
        case LOADING_PHASES.LOADING_GROUP:
          if (groupInfoState.isObjLoaded === true) {
            if (groupInfoState.objData.orgGroupId > 0) {
              getSAAGroupMembers(groupInfoState.objData.orgGroupId);
              setLoadingState(LOADING_PHASES.LOADING_MEMBERS);
            } else {
              setLoadingState(LOADING_PHASES.ERROR);
            }
          }
          break;
        case LOADING_PHASES.LOADING_MEMBERS:
          if (groupMembersState.isArrayLoaded === true) {
            const ctxStatus = getContextSecurity(groupInfoState.objData.orgGroupId, TAXONOMIES, SCOPE);
            setLoadingState(ctxStatus === 'sent' ? LOADING_PHASES.LOADING_CONTEXT : LOADING_PHASES.LOADED);
          }
          break;
        case LOADING_PHASES.LOADING_CONTEXT:
          if (contextSecurityState.isObjLoaded === true) {
            setLoadingState(LOADING_PHASES.LOADED);
          }
          break;
        case LOADING_PHASES.LOADED:
        default:
          break;
      }
    } catch (e) {
      onSAAGroupsError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupInfoState, groupMembersState, contextSecurityState]);

  return {
    isError: loadingState === LOADING_PHASES.ERROR,
    isLoading: loadingState !== LOADING_PHASES.LOADED && loadingState !== LOADING_PHASES.ERROR,
    homeRoute: navRoutes.SAA_GROUP_MEMBERS?.route,
    taxonomyName: TAXONOMIES[0],
    groupInfoObj: groupInfoState.objData || {},
    onHomeClicked,
    onBackToClicked
  };
};

export default useSAAGroupsContextView;