import EditIcon from "../../../../common/components/icons/EditIcon";

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ group, onEditGroupClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className="hidden-xs">{group?.groupName}</span>&nbsp;
      {onEditGroupClicked &&
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={(e) => onEditGroupClicked(e, group)}>
          <EditIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Group Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{group?.groupName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Group Code</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{group?.groupCode || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Group Owner</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{group?.orgUnitName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Group Category / Hierarchy</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{group?.groupCategory}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Group Type</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{group?.groupType}</div>
      </div>
    </div>
  </div>
);

const MyGroupsSearchGridSmall = ({ gridData, isLoading, onEditGroupClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((group, i) => <GridRow key={i} group={group} onEditGroupClicked={onEditGroupClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Groups</div>
            </div>
          </div>
        )
    }
  </div>
);

export default MyGroupsSearchGridSmall;