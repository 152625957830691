import { Fragment } from "react";

import useNTGroupMemberAddModal from "./UseNTGroupMemberAddModal";

import NTGroupRoleTypeDropdown from "../../dropdowns/roleType/NTGroupRoleTypeDropdown";
import NTGroupConfirmedDropdown from "../../dropdowns/confirmed/NTGroupConfirmedDropdown";
import NTGroupAttendeeTypeDropdown from "../../dropdowns/attendeeType/NTGroupAttendeeTypeDropdown";

import ReadOnly from "../../../../../../common/components/readOnly/ReadOnly";
import PopUpModal from "../../../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";

import { SAVING_MSG } from "../../../../../../common/utils/Constants";

const NTGroupMemberAddModal = ({ memberObj, onCloseModal }) => {
  const {
    isSaving,
    formState,
    errorState,
    onValueTextPairChanged,
    handleSubmit
  } = useNTGroupMemberAddModal(memberObj, onCloseModal);

  return (
    <Fragment>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Add Group Member'}
        displayPopUp={isSaving !== true}
        onModalCanceled={onCloseModal}>
        <form onSubmit={handleSubmit} noValidate>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <ReadOnly
                label='Member'
                name="groupMemberName"
                value={formState.groupMemberName} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <NTGroupAttendeeTypeDropdown
                label='Attendee Type*'
                name="groupAttendeeTypeId"
                value={formState.groupAttendeeTypeId}
                error={errorState.groupAttendeeTypeId}
                message={errorState.groupAttendeeTypeId}
                onChange={(newValue, newValueLabel, e) => { e?.target?.value && onValueTextPairChanged(newValue, 'groupAttendeeTypeId', newValueLabel, 'attendeeType') }} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <NTGroupRoleTypeDropdown
                label='Role*'
                name="groupRoleTypeId"
                value={formState.groupRoleTypeId}
                error={errorState.groupRoleTypeId}
                message={errorState.groupRoleTypeId}
                onChange={(newValue, newValueLabel, e) => { e?.target?.value && onValueTextPairChanged(newValue, 'groupRoleTypeId', newValueLabel, 'roleType') }} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <NTGroupConfirmedDropdown
                label='Potential/Confirmed*'
                name="isConfirmedTypeId"
                value={formState.isConfirmedTypeId}
                error={errorState.isConfirmedTypeId}
                message={errorState.isConfirmedTypeId}
                onChange={(newValue, newValueLabel, e) => { e?.target?.value && onValueTextPairChanged(newValue, 'isConfirmedTypeId', newValueLabel, 'isConfirmedType') }} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="submit">Add Group Member</PrimaryButton>&nbsp;
              <SecondaryButton type='button' onClick={onCloseModal}>Cancel</SecondaryButton>
            </div>
          </div>
        </form>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default NTGroupMemberAddModal;