import { useState } from "react";
import Constants from "../../../common/utils/Constants";

import GroupAlternateMembersData from "./GroupAlternateMembersData";

const useGroupAlternateMembersData = () => {
  const [groupAlternateMembersState, setGroupAlternateMembersState] = useState(GroupAlternateMembersData.INITIAL_GROUP_ALTERNATE_MEMBERS_STATE);
  const [groupAlternateMembersIMXState, setGroupAlternateMembersIMXState] = useState(GroupAlternateMembersData.INITIAL_GROUP_ALTERNATE_MEMBERS_IMX_STATE);

  const getGroupAlternateMembers = (orgGroupId) => {
    return GroupAlternateMembersData.getGroupAlternateMembersData(orgGroupId, groupAlternateMembersState, setGroupAlternateMembersState);
  };

  const getGroupAlternateMembersIMX = (orgGroupId) => {
    return GroupAlternateMembersData.getGroupAlternateMembersIMXData(orgGroupId, groupAlternateMembersIMXState, setGroupAlternateMembersIMXState);
  };

  const putGroupAlternateMembers = (orgGroupId, alternateMembersArray) => {
    return GroupAlternateMembersData.putGroupAlternateMembersData(orgGroupId, alternateMembersArray, groupAlternateMembersState, setGroupAlternateMembersState);
  };

  const putGroupAlternateMembersIMX = (orgGroupId, alternateMembersArray) => {
    return GroupAlternateMembersData.putGroupAlternateMembersIMXData(orgGroupId, alternateMembersArray, groupAlternateMembersIMXState, setGroupAlternateMembersIMXState);
  };

  const updateMemberEventIsSelected = (groupQualificationEventId) => {
    const targetIndex = groupAlternateMembersState.arrayData.findIndex(x => x.groupQualificationEventId === groupQualificationEventId);

    if (targetIndex > Constants.DEFAULT_ID) {
      const newArrayData = JSON.parse(JSON.stringify(groupAlternateMembersState.arrayData));
      newArrayData[targetIndex].isSelected = !groupAlternateMembersState.arrayData[targetIndex].isSelected;

      setGroupAlternateMembersState({
        ...groupAlternateMembersState,
        arrayData: newArrayData
      });
    }
  };

  const updateMemberIMXIsSelected = (groupQualificationImxId) => {
    const targetIndex = groupAlternateMembersIMXState.arrayData.findIndex(x => x.groupQualificationImxId === groupQualificationImxId);

    if (targetIndex > Constants.DEFAULT_ID) {
      const newArrayData = JSON.parse(JSON.stringify(groupAlternateMembersIMXState.arrayData));
      newArrayData[targetIndex].isSelected = !groupAlternateMembersIMXState.arrayData[targetIndex].isSelected;

      setGroupAlternateMembersIMXState({
        ...groupAlternateMembersIMXState,
        arrayData: newArrayData
      });
    }
  };

  return {
    groupAlternateMembersState,
    groupAlternateMembersIMXState,
    getGroupAlternateMembers,
    getGroupAlternateMembersIMX,
    putGroupAlternateMembers,
    putGroupAlternateMembersIMX,
    updateMemberEventIsSelected,
    updateMemberIMXIsSelected
  };
};

export default useGroupAlternateMembersData;