import { useState } from "react";
import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";

import useGroupData from "../../../../../common/state/group/UseGroupData";
import useMemberGroupsData from "../../../../../common/state/memberGroups/UseMemberGroupsData";

import { DEFAULT_ID } from "../../../../../common/utils/Constants";

const INITIAL_MODAL_STATE = {
  displayModal: false,
  personOrgGroupId: DEFAULT_ID,
  firstName: '',
  lastName: '',
};

const useGroupSAAMembers = () => {
  const navigate = useNavigate();
  const { groupState, groupMembersState, getGroupMembers } = useGroupData();
  const { memberGroupsState, deleteGroupMember, resetMemberGroupsState } = useMemberGroupsData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onInviteAlternateClicked = () => {
    navigate(NavLinks.GROUP_MEMBERS_INVITE_ALTERNATE);
  };

  const onAddMemberToGroupClicked = () => {
    navigate(NavLinks.GROUP_MEMBERS_ADD);
  };

  const onEditGroupMemberClicked = (event, member) => {
    event?.preventDefault();

    navigate(NavLinks.GROUP_MEMBERS_EDIT, { state: { member } });
  };

  const onDeleteModalConfirmClicked = async (event) => {
    event?.preventDefault();

    const personOrgGroupId = modalState.personOrgGroupId;

    setModalState({ ...INITIAL_MODAL_STATE });

    const deleteGroupMemberResult = await deleteGroupMember(personOrgGroupId);

    if (deleteGroupMemberResult) {
      resetMemberGroupsState();

      await getGroupMembers(groupState.objData.orgGroupId);
    }
  };

  const onDeleteModalCancelClicked = () => {
    setModalState({ ...INITIAL_MODAL_STATE });
  };

  const onDeleteGroupMemberClicked = (event, member) => {
    event?.preventDefault();

    setModalState({
      ...modalState,
      displayModal: true,
      firstName: member.firstName,
      lastName: member.lastName,
      personOrgGroupId: member.personOrgGroupId
    });
  };

  return {
    isLoading: groupMembersState.isArrayLoading,
    isSaving: memberGroupsState.isSaving,
    gridData: groupMembersState.arrayData || [],
    modalState,
    onInviteAlternateClicked,
    onAddMemberToGroupClicked,
    onEditGroupMemberClicked,
    onDeleteGroupMemberClicked,
    onDeleteModalConfirmClicked,
    onDeleteModalCancelClicked
  };
};

export default useGroupSAAMembers;