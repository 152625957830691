import { API_NAMES, initApi } from "../../utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../utils/HttpHelper";

const searchGroupsData = (filterObject, state, setState) => {
  if (filterObject) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    const filterObjectForUrl = filterObject ? encodeURIComponent(filterObject) : 'NaN';
    const url = `/OrgGroup/search?filters=${filterObjectForUrl}`;

    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const INITIAL_GROUP_SEARCH_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_GROUP_FILTERS_STATE = {
  filterObject: {},
  showFilters: true
};

const GroupSearchData = {
  INITIAL_GROUP_SEARCH_STATE,
  INITIAL_GROUP_FILTERS_STATE,
  searchGroupsData
};

export default GroupSearchData;