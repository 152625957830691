import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useSAAGroupInfoData from "../../../state/scholasticAllAmericaGroup/scholasticAllAmericaGroupInfo/UseSAAGroupInfoData";
import useSAAGroupMembersData from "../../../state/scholasticAllAmericaGroup/scholasticAllAmericaGroupMembers/UseSAAGroupMembersData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";
import useMemberGroupsData from "../../../../common/state/memberGroups/UseMemberGroupsData";
import useOrgGroupPropertiesData from "../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData";

import { DEFAULT_ID } from "../../../../common/utils/Constants";

const INITIAL_STATE = {
  personOrgGroupId: DEFAULT_ID,
  firstName: '',
  lastName: '',
  personOrgGroupQualificationStatus: ''
};

const useSAAGroupMembersDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { orgGroupPropertiesState } = useOrgGroupPropertiesData();
  const { groupInfoState } = useSAAGroupInfoData();
  const { groupMembersState, getSAAGroupMembers } = useSAAGroupMembersData();
  const { memberGroupsState, memberGroupsQualificationEventState, getGroupMember, getGroupQualificationEvents,
    putGroupMember
  } = useMemberGroupsData();
  const [state, setState] = useState(INITIAL_STATE);

  const onBackClicked = () => {
    navigate(navRoutes.SAA_GROUP_MEMBERS?.route);
  };

  const onFormCompletion = async (groupMemberObj) => {
    const putGroupMemberResult = await putGroupMember(state.personOrgGroupId, groupMemberObj);

    if (putGroupMemberResult) {
      const getSAAGroupMembersResult = await getSAAGroupMembers(groupInfoState.objData.orgGroupId);

      if (getSAAGroupMembersResult) {
        navigate(navRoutes.SAA_GROUP_MEMBERS?.route);
      }
    }
  };

  useEffect(() => {
    async function loadGroupMember() {
      if (location.state?.member?.personOrgGroupId > 0) {
        const member = location.state.member;

        const getGroupMemberResult = await getGroupMember(member.personOrgGroupId);

        if (getGroupMemberResult) {
          const memberGroupObj = getGroupMemberResult.objData;

          setState({
            ...state,
            personOrgGroupId: memberGroupObj.personOrgGroupId,
            firstName: member.firstName,
            lastName: member.lastName,
            personOrgGroupQualificationStatus: member.personOrgGroupQualificationStatus
          });

          const personOrgGroupQualification = Array.isArray(memberGroupObj.personOrgGroupQualification) && memberGroupObj.personOrgGroupQualification.length > 0
            ? memberGroupObj.personOrgGroupQualification[0]
            : undefined;

          if (personOrgGroupQualification) {
            getGroupQualificationEvents(personOrgGroupQualification.personOrgGroupQualificationId, true);
          }
        }
      }
    }
    loadGroupMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSaving: memberGroupsState.isSaving,
    isLoading: orgGroupPropertiesState.isArrayLoading || memberGroupsState.isObjLoading || groupMembersState.isArrayLoading,
    isGridLoading: memberGroupsQualificationEventState.isArrayLoading,
    isMemberGroupObjLoaded: memberGroupsState.isObjLoaded,
    groupObj: groupInfoState.objData || {},
    memberGroupObj: memberGroupsState.objData || {},
    groupQualificationEvents: memberGroupsQualificationEventState.arrayData || [],
    personId: memberGroupsState.objData?.personId || '',
    state,
    onBackClicked,
    onFormCompletion
  };
};

export default useSAAGroupMembersDetail;