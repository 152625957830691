import { Fragment } from 'react';

import useGroupInviteAlternateMembersIMX from './UseGroupInviteAlternateMembersIMX';

import InviteGroupMemberIMXGrid from '../../components/grids/InviteGroupMemberIMXGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import { LOADING_MSG, SAVING_MSG } from '../../../../../common/utils/Constants';

const GroupInviteAlternateMembersIMX = () => {
  const {
    isLoading,
    isSaving,
    isGridLoading,
    gridData,
    onInviteAthletesClicked,
    onBackClicked,
    onCheckboxClicked
  } = useGroupInviteAlternateMembersIMX();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Alternate Athletes IMX</Headings.H3>
        </div>
      </div>
      <div className='row'>
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <InviteGroupMemberIMXGrid
            data={gridData}
            isLoading={isGridLoading}
            onCheckboxClicked={onCheckboxClicked} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="button" onClick={onInviteAthletesClicked}>Invite Selected Athletes</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default GroupInviteAlternateMembersIMX;