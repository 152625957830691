import useNTGroupRoleTypeDropdown from './UseNTGroupRoleTypeDropdown';

import Dropdown from '../../../../../../common/components/dropdowns/Dropdown';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const NTGroupRoleTypeDropdown = ({ label, name, value, error, message, onChange }) => {
  const { nationalTeamGroupRoleTypesState } = useNTGroupRoleTypeDropdown();

  return nationalTeamGroupRoleTypesState.message
    ? <span className={global.LoadingMsg}>{nationalTeamGroupRoleTypesState.message}</span>
    : (
      <Dropdown
        name={name}
        value={value}
        error={error}
        label={label}
        message={message}
        onChange={onChange}
        options={nationalTeamGroupRoleTypesState.options || []}
        isLoading={nationalTeamGroupRoleTypesState.areOptionsLoaded !== true}
      />
    );
};

export default NTGroupRoleTypeDropdown;