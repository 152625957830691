import { Route, Routes } from "react-router-dom";
import { Navigate } from "../../../common/wrappers/ReactRouterDom";

import SAAGroupsContextView from "./SAAGroupsContextView";

import SAAGroupLanding from "./scholasticAllAmericaLanding/SAAGroupLanding";
import SAAGroupReports from "./scholasticAllAmericanReports/SAAGroupReports";
import SAAGroupMembers from "./scholasticAllAmericaMembers/SAAGroupMembers";
import SAAGroupMembersDetail from "./scholasticAllAmericaMembers/SAAGroupMembersDetail";

import SAAGroupContextProvider from "../../state/scholasticAllAmericaGroup/SAAGroupContextProvider";
import SAAGroupInfoContextProvider from "../../state/scholasticAllAmericaGroup/scholasticAllAmericaGroupInfo/SAAGroupInfoContextProvider";
import SAAGroupMembersContextProvider from "../../state/scholasticAllAmericaGroup/scholasticAllAmericaGroupMembers/SAAGroupMembersContextProvider";
import SAAGroupFiltersContextProvider from "../../state/scholasticAllAmericaGroupFilters/SAAGroupFiltersContextProvider";
import ReportPeriodSeasonContextProvider from "../../../common/state/reportPeriodSeason/ReportPeriodSeasonContextProvider";

import LoadingModal from "../../../common/components/dialogs/LoadingModal";
import ErrorBoundary from "../../../common/components/errors/ErrorBoundary";
import SecuredRouteElement from "../../../common/components/security/SecuredRouteElement";
import ErrorBoundaryFallback from "../../../common/components/errors/ErrorBoundaryFallback";

import useNavRoutes from "../../../common/state/security/UseNavRoutes";
import OrgGroupPropertiesContextProvider from "../../../common/state/orgGroupProperties/OrgGroupPropertiesContextProvider";

const SAAGroupsContextRoutesContextProviders = ({ children }) => (
  <SAAGroupInfoContextProvider>
    <SAAGroupMembersContextProvider>
      <SAAGroupFiltersContextProvider>
        {children}
      </SAAGroupFiltersContextProvider>
    </SAAGroupMembersContextProvider>
  </SAAGroupInfoContextProvider>
);

const SAAGroupsBasicRoutesContextProviders = ({ children }) => (
  <ReportPeriodSeasonContextProvider>
    <OrgGroupPropertiesContextProvider>
      <SAAGroupContextProvider>
        {children}
      </SAAGroupContextProvider>
    </OrgGroupPropertiesContextProvider>
  </ReportPeriodSeasonContextProvider>
);

const SAAGroupsContextRoutes = ({ navRoutes }) => (
  <SAAGroupsContextRoutesContextProviders>
    <SAAGroupsContextView>
      <Routes>
        <Route path={navRoutes.SAA_GROUP_MEMBERS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.SAA_GROUP_MEMBERS}
              editElement={<SAAGroupMembers />} />
          } />
        <Route path={navRoutes.SAA_GROUP_MEMBERS_DETAIL?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.SAA_GROUP_MEMBERS_DETAIL}
              editElement={<SAAGroupMembersDetail />} />
          } />
        <Route path={navRoutes.SAA_GROUP_REPORTS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.SAA_GROUP_REPORTS}
              editElement={<SAAGroupReports />} />
          } />
      </Routes>
    </SAAGroupsContextView>
  </SAAGroupsContextRoutesContextProviders>
);

const SAAGroupsBasicRoutes = ({ navRoutes }) => (
  <SAAGroupsBasicRoutesContextProviders>
    <Routes>
      <Route path={navRoutes.SAA_GROUP_LANDING?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.SAA_GROUP_LANDING}
            editElement={<SAAGroupLanding />} />
        } />

      <Route path={'/*'} element={<SAAGroupsContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes.SAA_GROUP_LANDING?.path} replace />} />
    </Routes>
  </SAAGroupsBasicRoutesContextProviders>
);

const SAAGroups = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <SAAGroupsBasicRoutes navRoutes={navRoutes} />
    </ErrorBoundary>
  );
};

export default SAAGroups;