import { createContext, useState } from 'react';

import SAAGroupData from './SAAGroupData';

export const SAAGroupContext = createContext();

const SAAGroupContextProvider = ({ children }) => {
  const stateHook = useState(SAAGroupData.INITIAL_SCHOLASTIC_ALL_AMERICA_GROUP_STATE);

  return (
    <SAAGroupContext.Provider value={stateHook}>
      {children}
    </SAAGroupContext.Provider>
  );
};

export default SAAGroupContextProvider;