import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useGroupAddData from '../../../state/groupAdd/UseGroupAddData';

import useGroupSearchData from '../../../../common/state/groupSearch/UseGroupSearchData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useOrgGroupTypesData from '../../../../common/state/orgGroupTypes/UseOrgGroupTypesData';
import useGroupFieldNamesData from '../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

const useGroupMyGroups = () => {
  const navigate = useNavigate();
  const { clearObjData } = useGroupAddData();
  const { groupSearchState, groupFiltersState, searchGroups, onToggleShowFilters } = useGroupSearchData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { orgGroupTypesState } = useOrgGroupTypesData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();

  const onAddGroupClicked = (event) => {
    event?.preventDefault();

    const targetGroup = orgGroupTypesState.arrayData.find(x => x.typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST);

    if (targetGroup) {
      navigate(NavLinks.GROUP_ADD_LIST, { state: { groupTypeId: targetGroup.groupTypeId, groupTypeName: targetGroup.typeName } });
    }
  };

  const onEditGroupClicked = (event, group) => {
    event?.preventDefault();

    const orgGroupId = group?.orgGroupId;

    if (orgGroupId > 0) {
      navigate(NavLinks.GROUP_INFO, { state: { orgGroupId } });
    }
  };

  const onSelectOrgUnitClicked = (event) => {
    event?.preventDefault();

    navigate(NavLinks.GROUP_SELECTION);
  };

  const onSubmitSearchForm = (formState) => {
    if (groupSearchState.isArrayLoading === false) {
      const filterObject = {
        orgUnitId: formState.orgUnitId || selectOrgUnitState.orgUnitId || undefined,
        groupCategoryId: formState.groupCategories.length > 0 ? formState.groupCategories.map(x => x.id) : undefined,
        groupName: formState.groupName ? formState.groupName.trim() : undefined,
        groupCode: formState.groupCode ? formState.groupCode.trim() : undefined
      };

      searchGroups(filterObject);
    }
  };

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId && Object.keys(groupFiltersState.previousSearchFilters).length === 0) {
      onSubmitSearchForm({ orgUnitId: selectOrgUnitState.orgUnitId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId]);

  useEffect(() => {
    clearObjData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    gridData: groupSearchState.arrayData || [],
    isLoading: orgGroupTypesState.isArrayLoading,
    isGridLoading: groupSearchState.isArrayLoading,
    showFilters: groupFiltersState.showFilters,
    previousSearchFilters: groupFiltersState.filterObject || {},
    selectOrgUnitState,
    onToggleShowFilters,
    onSubmitSearchForm,
    onAddGroupClicked,
    onEditGroupClicked,
    onSelectOrgUnitClicked
  };
};

export default useGroupMyGroups;