import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import GroupData from '../../../common/state/group/GroupData';

import Constants from '../../../common/utils/Constants';

const GROUP_OBJ = {
  groupCategoryId: Constants.DEFAULT_ID,
  groupTypeId: Constants.DEFAULT_ID,
  orgUnitId: '',
  groupCode: '',
  groupDescription: '',
  groupName: '',
  isDelete: false,
  orgGroupEligibility: [],
  orgGroupFieldValue: [],
  hasOrgRoleRestriction: null,
  hasRegistration: null,
  requiresCoachApproval: null,
  registrationCloseDate: null,
  registrationOpenDate: null,
  hasOpenRegistration: null
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  objData: JSON.parse(JSON.stringify(GROUP_OBJ)),
  groupTypeName: ''
};

const GroupAddData = { 
  INITIAL_STATE, 
  GROUP_OBJ, 
  postGroupData: GroupData.postGroupData, 
};

export default GroupAddData;