import { Fragment } from "react";

import useSAAGroupMembers from "./UseSAAGroupMembers";

import SAAGroupsLoadingModal from "../components/dialogs/loading/SAAGroupsLoadingModal";

import SAAGroupMembersGrid from "../../../components/grids/saaGroupMembersGrid/SAAGroupMembersGrid";

import Headings from "../../../../common/components/headings/Headings";

const SAAGroupMembers = () => {
  const {
    isGridLoading,
    gridData,
    onEditGroupMemberClicked,
  } = useSAAGroupMembers();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Members</Headings.H3>
        </div>
      </div>
      <SAAGroupMembersGrid
        gridData={gridData}
        isLoading={isGridLoading}
        onEditClicked={onEditGroupMemberClicked} />
      <SAAGroupsLoadingModal isLoading={false} />
    </Fragment>
  );
};

export default SAAGroupMembers;