import useGroupAttributes from './UseGroupAttributes';

import GroupListAttributes from './listAttributes/GroupListAttributes';
import GroupCompetitorListAttributes from './competitorListAttributes/GroupCompetitorListAttributes';
import GroupCampAttributes from './campAttributes/GroupCampAttributes';
import GroupSAAAttributes from './saaAttributes/GroupSAAAttributes';
import GroupNationalTeamAttributes from './nationalTeamAttributes/GroupNationalTeamAttributes';
import GroupPracticeAttributes from './practiceAttributes/GroupPracticeAttributes';
import GroupTravelTripAttributes from './travelTripAttributes/GroupTravelTripAttributes';
import GroupClubSiteAttributes from './clubSiteAttributes/GroupClubSiteAttributes';

import ErrorMessage from '../../../../common/components/errors/ErrorMessage';
import LoadingModal from '../../../../common/components/dialogs/LoadingModal';

const GroupAttributes = () => {
  const {
    isLoaded,
    groupTypeName,
    GROUP_CONSTANTS
  } = useGroupAttributes();

  if (isLoaded === true) {
    switch (groupTypeName) {
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
        return <GroupListAttributes />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_COMPETITOR_LIST:
        return <GroupCompetitorListAttributes />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_CAMP:
        return <GroupCampAttributes />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_TRAVEL_TRIP:
        return <GroupTravelTripAttributes />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP:
        return <GroupPracticeAttributes />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_SAA:
        return <GroupSAAAttributes />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_NATIONAL_TEAM:
        return <GroupNationalTeamAttributes />;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_CLUB_SITE:
        return <GroupClubSiteAttributes />;
      default:
        return <ErrorMessage message={'An error occurred'} />;
    }
  } else {
    return <LoadingModal />;
  }
};

export default GroupAttributes;