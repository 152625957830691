import validate from './NTGroupAddFormValidation';

import { findGroupTypeObjById } from '../../../utils/NTGroupUtils';

import useNTGroupData from '../../../../../state/nationalTeamGroup/UseNTGroupData';
import useNTGroupTypesHierarchyData from '../../../../../state/nationalTeamGroupTypesHierarchy/UseNTGroupTypesHierarchyData';

import useEnvironmentVariableData from '../../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from "../../../../../../common/utils/UseForm";
import { BLANK_DATE_STRING, DEFAULT_ID } from '../../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  nationalTeamGroupType: [],
  nationalTeamGroupName: '',
  nationalTeamGroupCode: '',
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING,
  registrationStartDate: BLANK_DATE_STRING,
  registrationEndDate: BLANK_DATE_STRING,
  meetId: DEFAULT_ID,
  meetName: '',
  hasRegistration: false,
  hasMeet: false
};

const useNTGroupAddForm = (onSubmitFormCallback) => {
  const { onNTGroupsError } = useNTGroupData();
  const { FINA_ORG_UNIT_ID, ORG_APPROVED_MEET_CLASSIFICATION_ID } = useEnvironmentVariableData();
  const { nationalTeamGroupTypesHierarchyState } = useNTGroupTypesHierarchyData();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, setFormData
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const onGroupTypeChanged = (nationalTeamGroupType) => {
    try {
      if (Array.isArray(nationalTeamGroupType) && nationalTeamGroupType[0]?.id > 0) {
        const targetGroupType = findGroupTypeObjById(nationalTeamGroupTypesHierarchyState.arrayData, nationalTeamGroupType[0]?.id);

        if (targetGroupType) {
          setFormState({
            ...formState,
            nationalTeamGroupType,
            hasRegistration: targetGroupType?.hasRegistration === true,
            hasMeet: targetGroupType?.hasMeet === true
          });
        }
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  return {
    FINA_ORG_UNIT_ID,
    ORG_APPROVED_MEET_CLASSIFICATION_ID,
    hasRegistration: formState.hasRegistration === true,
    hasMeet: formState.hasMeet === true,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    setFormData,
    onGroupTypeChanged
  };
};

export default useNTGroupAddForm;