import { Fragment } from "react";

import useNTGroupInfo from "./UseNTGroupInfo";

import NTGroupInfoForm from "../components/forms/groupInfo/NTGroupInfoForm";
import NTGroupsLoadingModal from "../components/dialogs/loading/NTGroupsLoadingModal";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

import { SAVING_MSG } from "../../../../common/utils/Constants";

const NTGroupInfo = () => {
  const {
    isSaving,
    groupInfoObj,
    groupMembersObj,
    state,
    onSubmitInfoForm,
    onDeleteGroupClicked,
    onDeletePopupCancelClicked,
    onDeletePopupConfirmClicked
  } = useNTGroupInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Group Info</Headings.H3>
        </div>
      </div>
      <NTGroupInfoForm
        groupInfoObj={groupInfoObj}
        hasMembers={groupMembersObj.hasMembers === true}
        onSubmitFormCallback={onSubmitInfoForm}
        onDeleteGroupClicked={onDeleteGroupClicked} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete Group?'}
        displayPopUp={state.displayDeletePopup === true}
        onModalCanceled={onDeletePopupCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Group Name:</b> {groupInfoObj.nationalTeamGroupName}</p>
            <p><b>Group Code:</b> {groupInfoObj.nationalTeamGroupCode}</p>
            <p><b>Group Type:</b> {groupInfoObj.groupTypeName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeletePopupConfirmClicked}>Yes, Delete Group</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeletePopupCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <NTGroupsLoadingModal
        isLoading={isSaving}
        loadingMessage={SAVING_MSG} />
    </Fragment>
  );
};

export default NTGroupInfo;