import { createContext, useState } from 'react';

import GroupData from './GroupData';

export const GroupMembersStateContext = createContext();

const GroupMembersContextProvider = ({ children }) => {
  const stateHook = useState(GroupData.INITIAL_GROUP_MEMBERS_STATE);

  return (
    <GroupMembersStateContext.Provider value={stateHook}>
      {children}
    </GroupMembersStateContext.Provider>
  );
};

export default GroupMembersContextProvider;