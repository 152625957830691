import EditIcon from '../../../../../../common/components/icons/EditIcon';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ groupAthlete, onEditClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{groupAthlete.groupMemberName}</span>&nbsp;
      <div className={global.FloatRight}>
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={(e) => onEditClicked(e, groupAthlete)}>
          <EditIcon toolTipText='Open Approval Menu' />
        </button>
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {groupAthlete.groupMemberName}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Member Id
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {groupAthlete.memberId || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const NTGroupAthleteApprovalsGridSmall = ({ gridData, isLoading, onEditClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((groupAthlete, i) => <GridRow key={i} groupAthlete={groupAthlete} onEditClicked={onEditClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Pending Approvals</div>
            </div>
          </div>
        )
    }
  </div>
);

export default NTGroupAthleteApprovalsGridSmall;